import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get } from 'lodash';
import { Link } from "@StarberryUtils"
import parse from 'html-react-parser';
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby";

import "./ImageAndVideoBlock.scss";
import PlayVideo from "../../Play/custom-video";
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import GoogleReview from "../../../images/google-reviews-white.svg"
import ReviewsCount from '../Reviews/review-count'
// Header component

const ImageAndVideoBlock = (props) => {
    const [isPlay, setPlay] = useState(false);
    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.Video_Background_Image_Transforms) {
        processedImages = props.imagetransforms.Video_Background_Image_Transforms;
    }
    const data = useStaticQuery(graphql`
    query {
      glstrapi {
  
        reviews(publicationState: LIVE, sort: "Date:DESC", limit: 10,where : {Review_Content_ne:null}) {
          id
          Name
          Date
          Publish
          Rating
          Review_Content
          Select_Page
          Name_Sub_Text
          Image {
            url
          }
          Reviews_Link
        }
        globalModule {
          Google_Reviews_Content
        }
  
      }
    }
  `);
   const pageClass = props?.pageLayout === "officePage"?"no-padding-top":""

  let reviews = data.glstrapi.reviews;
  let page_name = props.Pagename.replaceAll(' ', '_')
  reviews = reviews.filter(item => item?.Select_Page?.toLowerCase().includes(page_name?.toLowerCase()));

    return (
        <React.Fragment>
            <section className={`choice imagevideoblock image-video-block ${pageClass}`}>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    <Container>
                        <Row>
                           <Col sm={6}>
                                <div className="video-column">
                                    <div className={"video-image-banner"}>
                                        {props?.Video_Embed_Code?<div className="ifram-container">
                                        <iframe src={props?.Video_Embed_Code} frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title={`${props.Pagename} | Your place to thrive`}></iframe>
                                        </div>
                                        :<>
                                        {props.officeImage ?
                                            <ImageTransform
                                                imagesources={props.officeImage.url}
                                                renderer="srcSet"
                                                imagename="offices.Video_Background_Image.detail"
                                                attr={{ alt: "office", className: 'img-zoom' }}
                                                imagetransformresult={processedImages}
                                                id={props?.pageId}
                                            />
                                            :
                                            <ImageTransform
                                                imagesources={props.Image.url}
                                                renderer="srcSet"
                                                imagename="articles.Video_Background_Image.detail"
                                                attr={{ alt: props?.Image?.alternativeText, className: 'img-zoom' }}
                                                imagetransformresult={processedImages}
                                                id={props?.pageId}
                                                testparam={true}
                                            />
                                        }
                                        <div className="video-btn-text">
                                            {props.Video_Url &&
                                                <div className="video-buttons">
                                                    <PlayVideo url={props.Video_Url} />
                                                </div>
                                            }
                                        </div>
                                        </>
                                    }
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="status-content-section">
                                    <div className="boxes__text">
                                        <div className="content-section">
                                            {reviews && reviews.length > 0 &&
                                                <div class="slider-wrapper">
                                                    {reviews[0].Review_Content &&
                                                        <h2>
                                                            {parse(reviews[0].Review_Content)}
                                                        </h2>
                                                    }
                                                    <div className="aurher-info">
                                                        <p>{reviews[0].Name}</p>
                                                        {reviews[0]?.Name_Sub_Text && 
                                                            <p>,&nbsp;{parse(reviews[0]?.Name_Sub_Text)}</p>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className="google-reviews">
                                                {reviews[0]?.Reviews_Link?<a target="_blank" href={reviews[0]?.Reviews_Link}>
                                                <picture>
                                                <img
                                                src={GoogleReview}
                                                alt="reviews-img01"
                                                className="google_review_logo"
                                                />
                                                </picture>
                                                </a>
                                                :
                                                <picture>
                                                <img
                                                src={GoogleReview}
                                                alt="reviews-img01"
                                                className="google_review_logo"
                                                />
                                                </picture>
                                                }
                                            <ReviewsCount />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment >
    );
};

export default ImageAndVideoBlock;
