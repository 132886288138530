import React, { useState } from "react";
import PlayVideo from "../../Play/custom-video";
import "./OurStatus.scss";
import { Parallax } from "react-parallax";
import parse from 'html-react-parser';
// Header component
import Banner04 from "../../../images/selling/banner04.png";
import info03 from "../../../images/development-plan/statusdesk02.png";

const OurStatus = (props) => {

  const [isPlay, setPlay] = useState(false);

  return (
    <React.Fragment>
      <Parallax bgImage={props.Video_Background_Image.url} className={props.Custom_Class} strength={600} renderLayer={(percentage) => (
        <div style={{ height: '100vh', background: "linear-gradient(0deg,rgba(26,25,25,.2),rgb(26 25 25 / 37%))" }} className="our-status">
          <div className="parallax-text"
            style={{
              position: "absolute",
              opacity: 2 - percentage,
              left: "0",
              width: "100%",
              top: percentage * 300,
              "margin-top": "0px"
            }}>

            <div className="video-wrapper">
              {props.Video_Embed_URL &&
              <React.Fragment>

                <div className="video-buttons">
                    <PlayVideo url={props.Video_Embed_URL} />
                </div>
                <span className="btn-info d-none d-xl-block">
                    {props.Video_Short_Info}
                </span>
              </React.Fragment>
              }
            </div>

            {props.Video_Content &&
              <div className="status-content">
                {parse(props.Video_Content)}
              </div>
            }
          </div>
        </div>
      )}>

      </Parallax>
    </React.Fragment>
  );
};

// Header component

export default OurStatus;
