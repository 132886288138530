import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
const AreaGuideRender = ({ item, tag }) => {

    const image_url = item?.Tile_Image?.url
    let processedImages = JSON.stringify({});
    //if (item?.Tile_Image?.url) {
        //processedImages = item?.Tile_Image?.url;
    //}

    return (
        <React.Fragment>           
            <div className="properties img-zoom">
                <div className="properties-img">
                  <Link to={`/about-us/areas-we-cover/${item.URL}`}>
                    {/*<img src={item?.Tile_Image?.url} alt="image" />*/}
                    <ImageTransform
                    imagesources={item?.Tile_Image?.url}
                    renderer="srcSet"
                    imagename="articles.Image_Block.detail"
                    imagetransformresult={processedImages}
                    id={item.id} />
                  </Link>
                </div>
                <div className="gallery-text">
                <Link to={`/about-us/areas-we-cover/${item.URL}`}>
                    <h3 className='guide_title'>{item?.Title}</h3>
                </Link>
                </div>
            </div>                                       
        </React.Fragment>
    );
};

export default AreaGuideRender;
