import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get } from 'lodash';
import { Link } from "@StarberryUtils"
import parse from 'html-react-parser';
import "./ImageBlock.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import GoogleReview from "../../../images/google-reviews-black.svg"
import ReviewsCount from '../Reviews/review-count'
import RegisterForm from "../../forms/register-form"
import ModalTiny from "react-bootstrap/Modal"
// Header component

const ImageBlock = (props) => {

    //console.log(props);
    let reviewsUR = ''
    if(props.Pagename == "Home") {
        reviewsUR = 'https://maps.app.goo.gl/uMWTxSpEXKg57t9n9'
    }
    if(props.Pagename === "Property Valuation") {
        reviewsUR = 'https://maps.app.goo.gl/Nmbcdve57abhw1pHA'
    }
    if(props.Pagename === "Redbrik SecureMove") {
        reviewsUR = 'https://maps.app.goo.gl/Nmbcdve57abhw1pHA'
    }
    if(props.Pagename === "Buying with us") {
        reviewsUR = 'https://maps.app.goo.gl/S3rgndoP2XQnHQHN7'
    }
    if(props.Pagename == "New Homes") {
        reviewsUR = 'https://maps.app.goo.gl/uMWTxSpEXKg57t9n9'
    }
    if(props.Pagename == "Landlords") {
        reviewsUR = 'https://maps.app.goo.gl/uMWTxSpEXKg57t9n9'
    }
    if(props.Pagename == "Rent a Property with us") {
        reviewsUR = 'https://maps.app.goo.gl/uMWTxSpEXKg57t9n9'
    }
    if(props.Pagename == "Selling with us") {
        reviewsUR = 'https://maps.app.goo.gl/uMWTxSpEXKg57t9n9'
    }

    const ctas = get(props, 'Buttons', []);
    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.Video_Background_Image_Transforms) {
        processedImages = props.imagetransforms.Video_Background_Image_Transforms;
    }

    const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
    const openSharefrndformModal = () => {
        setSharefrndformOpen(true);
    }
    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }
    
    return (
        <React.Fragment>
            <section className={`imageblock ${(props?.BG_Color)} ${(props?.Image_Align)} ${props?.Custom_Class}`}> 
               <ScrollAnimation animateIn={props.Initially_Animate?`fadeInUp`:``} animateOnce={true} initiallyVisible={props.Initially_Animate?``:`true`}>
                    <Container>

                        <Row>
                            {props.Image_Align === "Left" &&
                                <Col sm={6}>
                                    <div className={"img-column"}>
                                        <img src={props.Image.url} className="img-zoom"/>
                                        {/*<ImageTransform
                                            imagesources={props.Image.url}
                                            renderer="srcSet"
                                            imagename="articles.Image_Block.detail"
                                            attr={{ alt: props?.Image?.alternativeText, className: 'img-zoom' }}
                                            imagetransformresult={processedImages}
                                            id={props?.pageId}
                                            testparam={true}
                                         />*/}
                                    </div>
                                </Col>
                            }
                            <Col sm={6}>
                                {props.Content &&
                                    <div className="status-content">
                                        {parse(props.Content)}
                                           
                                           {(ctas.length>0) && (
                                            <div className="btn-list">
                                            {ctas.map((cta, i) => {
                                                const  parenturl = cta?.Link?.Main_Parent?.URL
                                                var pageurl = parenturl?parenturl+"/"+cta?.Link?.URL:cta?.Link?.URL
                                                pageurl = cta?.custom_url ? cta?.custom_url : "/"+pageurl;

                                                if (cta?.Link?.URL.match(/http|https/) || cta?.custom_url?.match(/http|https/)) {
                                                    return (
                                                        <a href={cta?.custom_url?cta?.custom_url:cta?.Link?.URL} target={cta?.Target === 'New_Tab' ? '_blank' : '_blank'} className={`btn ${cta.Color === "primary" ? 'btn-trans' : cta.Color}`}>{cta.Label}</a>
                                                    )
                                                } else {
                                                    if(cta?.Link?.URL!=="register") {
                                                    return (
                                                        <Link to={`${pageurl}`} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className={`btn ${cta.Color === "primary" ? 'btn-trans' : cta.Color}`}>
                                                            {cta.Icon &&
                                                                <i className={`icon-video-white`}></i>
                                                            }
                                                            {cta.Label}
                                                        </Link>
                                                    )
                                                    }else{
                                                        return (
                                                            <a href="javascript:void(0)" onClick={()=> { openSharefrndformModal();}} className="btn btn_cray"> {cta.Label}</a>
                                                        )
                                                    }
                                                }
                                            })}
                                            </div>
                                            )}
                                            {props?.reviews &&
                                            <>
                                            {reviewsUR?<>
                                            <a href={reviewsUR} target="_blank">
                                                <div className="google-reviews">
                                                <picture><img src={GoogleReview} alt="reviews-img01" className="google_review_logo"/></picture>
                                                <ReviewsCount />                  
                                                </div> 
                                                </a>
                                            </>
                                            :
                                            <div className="google-reviews">
                                                <picture><img src={GoogleReview} alt="reviews-img01" className="google_review_logo"/></picture>
                                                 <ReviewsCount />                  
                                            </div>                                            
                                            }
                                            </>
                                            }
                                    </div>
                                }
                                                        
                            </Col>
                            {props.Image_Align === "Right" &&
                                <Col sm={6}>
                                    <div className={`img-column ${(props.Image_Align)}`}>
                                        {props.Map_Link &&
                                            <iframe src={props.Map_Link} width="100%" height="480"></iframe>
                                        }
                                        {props.Image &&                                             
                                            <>
                                            <img src={props.Image.url} className="img-zoom"/>
                                            {/*<ImageTransform
                                                imagesources={props.Image.url}
                                                renderer="srcSet"
                                                imagename="articles.Image_Block.detail"
                                                attr={{ alt: props?.Image?.alternativeText, className: 'img-zoom' }}
                                                imagetransformresult={processedImages}
                                                id={props?.pageId}
                                                testparam={true}
                                            />*/}
                                            </>
                                        } 
                                    </div>
                                </Col>
                            }
                        </Row>

                    </Container>
                </ScrollAnimation>
            </section>
            <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="book-a-viewing-modal getin-touch">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Register with us</h2></ModalTiny.Title>            
          </ModalTiny.Header>
          
          <ModalTiny.Body className="event-section">
          <p>Let us know what you're looking for below and one of our team will be in touch to help you on the journey to finding your place.</p>
              <RegisterForm />
          </ModalTiny.Body>

          </ModalTiny>
        </React.Fragment>
    );
};

export default ImageBlock;
