import React from "react";
import { Link } from "@StarberryUtils";
import parse from 'html-react-parser';
import {Row, Col, Container } from 'react-bootstrap';

const PlainHeader = (props) => {
    return (
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="plain-main-header">
                        {props?.page?.Pagename === 'Our Offices' ? (
                            <>
                                <h1>Contact <span>Redbrik</span></h1>
                                <p>You've always got a Redbrik property expert nearby, explore our branches below.</p>
                            </>
                        ) : (
                            <>{parse(props.Header_Content)}</>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default PlainHeader;
