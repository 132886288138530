import React from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import parse from 'html-react-parser';
import GenerateLink from "../../common/site/generate-link"
import Join01 from "../../../images/propertyDetails/join01.png"
import Join02 from "../../../images/propertyDetails/join02.png"
import Join03 from "../../../images/propertyDetails/join03.png"
import Join04 from "../../../images/propertyDetails/join04.png"


import "./JoinUs.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const JoinUs = () => {
    
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        globalModule {
            Register_Module_Content
            Register_Module_CTA_Label
            Register_Module_CTA_Link {
            id
            }
        }
    }
  }
`);

const content = data.glstrapi.globalModule;

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                <section className="join-us">
                    <Container>
                        <Row>
                            <Col lg={6} className="order-lg-2">
                                <div className="join-now">
                                    {parse(content?.Register_Module_Content)}

                                    <GenerateLink link={content?.Register_Module_CTA_Link} className="btn">{content?.Register_Module_CTA_Label}</GenerateLink>

                                </div>
                            </Col>
                            <Col lg={6} className="order-lg-1">
                                <div className="join-img">
                                    <picture>
                                        <source media="(min-width:1500px)" srcSet={Join04} />
                                        <source media="(min-width:992px)" srcSet={Join03} />
                                        <source media="(min-width:768px)" srcSet={Join02} />
                                        <img src={Join01} alt="join" />
                                    </picture>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
        </React.Fragment>
    );
};

export default JoinUs;
