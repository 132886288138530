import React from "react";
import { Link } from "@StarberryUtils"
import parse from 'html-react-parser';
import {Row, Col, Container } from 'react-bootstrap';
import PlayVideo from "../Play/custom-video";

const CareerVideos = (props) => {

    return (
        <div className="career-videos">
            <Container>
                <Row>
                {props.Add_New_Video.map((career, key) => {
                    return (
                    <Col lg={4}>
                        <div className="video">
                            <div className="image">
                            {career.Career_Video_Background_Image &&
                                <img src={career.Career_Video_Background_Image.url} alt={career.Career_Video_Background_Image.alternativeText} />
                            }
                            {career.Career_Video_URL &&
                            <div className="video-buttons">
                                <PlayVideo url={career.Career_Video_URL} />
                            </div>
                            }
                            </div>
                            {parse(career.Career_Video_Details)}
                        </div>
                    </Col>
                    )
                })}
                </Row>
            </Container>
        </div>
    )
}

export default CareerVideos;
