import React, { useState, useEffect, useRef } from "react";
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import { Modal } from "react-bootstrap"
import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';
// import {Link}  from "gatsby"
import ReactPlayer from 'react-player'
import { InstagramEmbed } from 'react-social-media-embed';
// import { useLocation } from "@reach/router"
import { getMatterportUrl } from '@components/SearchResult/filter/constant'
import $ from "jquery"

import {
    isIOS,
    isMobile
} from "react-device-detect";

const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return(
        createPortal(
            children,
            el
        )
    )
}


const CustomVideo = (props,{children}) => {

  const [isOpen, setOpen] = useState(false);
  const [videotype, setVideoType] = useState('youtube');
  const [youtubeid, setYoutubeID] = useState('youtube');
  const [instagram, setInstagram] = useState('instagram');

  const [video_url, setVideoURL] = useState('');

  function checkvideo(url) {
  let url_one = String(url)
  let youtube = url_one.indexOf("youtube.com") > -1
  let instagrams = url_one.indexOf("instagram.com") > -1
  let matter = url_one.indexOf("matterport.com") > -1
  let matter_model = url_one.indexOf("models") > -1
  if(instagrams == true) {
    setVideoType("instagram")
    setInstagram(url_one)
    //setInstagram(`https://scontent.cdninstagram.com/o1/v/t16/f1/m82/8847C56B0CD3889DB240A130E4A7A0A9_video_dashinit.mp4?efg=eyJxZV9ncm91cHMiOiJbXCJpZ193ZWJfZGVsaXZlcnlfdnRzX290ZlwiXSIsInZlbmNvZGVfdGFnIjoidnRzX3ZvZF91cmxnZW4uY2xpcHMuYzIuNzIwLmJhc2VsaW5lIn0&_nc_ht=instagram.fcjb1-2.fna.fbcdn.net&_nc_cat=111&vs=873529681221259_3364899600&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC84ODQ3QzU2QjBDRDM4ODlEQjI0MEExMzBFNEE3QTBBOV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVAhg6cGFzc3Rocm91Z2hfZXZlcnN0b3JlL0dJLUxHUm1vV0RfeG04OERBRHRwbVNWRjdnVVVicV9FQUFBRhUCAsgBACgAGAAbABUAACbsiaPs0riyQRUCKAJDMywXQEIVP3ztkWgYEmRhc2hfYmFzZWxpbmVfMV92MREAdf4HAA%3D%3D&_nc_rid=b6cabe624c&ccb=9-4&oh=00_AfCOo53fwEWaaFNuqrdAzyRxPFl_uSUDh2gCcGsYWHoHbA&oe=6636508A&_nc_sid=cf751b`)
  }else if(youtube == true) {
      setVideoType("youtube")
      setYoutubeID(url_one.split( '/' ).pop())
  }
  else if(matter == true) {
      setVideoType("matterport")
      if(matter_model){
          if(url_one.split( '/' ).pop()) {
              setVideoURL("https://my.matterport.com/show/?m="+url_one.split( '/' ).pop())
          }
      }
      else {
          setVideoURL(props.url)
      }
  }

  }


     const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }

  const [isPlay, setPlay] = useState(false);
  const vidRef = useRef(null);
  const [show, setShow] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const [controls, setVideoControls] = useState(false);


  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const pausevideo = (ref) => {
    setPlayvideo(false)
    setVideoControls(false)

  }
  const playvideo = (ref) => {
    setPlayvideo(true)
    setVideoControls(false)
    setTimeout(function(){ $(".modal-bg-black").hide() }, 1700);
  }
  const showControls = () => {
    setVideoControls(true)
  }

    return(
        <>

            <strong className={props.className}  onClick={() => { setHTMLVideo(true); playvideo(); setShow(true);checkvideo(props.url);setOpen(true); }}>
                {props.children}
            </strong>

            {isOpen && showHTMLVideo && videotype == 'matterport' ?
                <ModalWindow>
                    <div className="matterPort-popup">
                        <ModalVideo channel='custom' isOpen={isOpen} url={`${getMatterportUrl(video_url)}`} onClose={() => setOpen(false)} />
                    </div>
                </ModalWindow> : isOpen && showHTMLVideo && videotype != 'matterport' ?
                <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                className="full-video-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className={videotype==="instagram"?"instagram":""}>


                    {isMobile &&
                    <div className="modal-bg-black">
                    </div>
                    }
                  
                    <>{videotype==="instagram"? < div className="instagram-Embed"><InstagramEmbed url={instagram} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay width='100%' height='100%'/></div>
                        :  <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ref={vidRef} onPlay={trackerVideo(props.title)} url={props.url} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' />
                        }
                        </>
                    </Modal.Body>
                </Modal> : ''

            }

        </>
    )
}

export default CustomVideo
