import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get } from 'lodash';
import { Link } from "@StarberryUtils"
import parse from 'html-react-parser';
import "./OurServices.scss";
import ScrollAnimation from 'react-animate-on-scroll'; 
// Header component

const OurServices = (props) => { 
    return (
        <React.Fragment>
            <section className={`our-services no-padding-top ${props.custom_class}`}>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                {parse(props.services_content)}
                                <div className={"services-list"}>
                                    {props.services_list && props.services_list.map((stat, i) => {                                             
                                        return (
                                            <div className="stats">
                                                <div className="services-img"><img src={stat.services_list_icons.url} /></div>
                                                <div className="list-info">
                                                    <div className="title">{stat.services_name}</div>
                                                    <div className="info">{parse(stat.services_list_content)}</div>
                                                </div>                                                
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment>
    );
};

export default OurServices;
