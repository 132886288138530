import { Link } from "@StarberryUtils";
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import gql from "graphql-tag"
import { navigate } from "@reach/router"
import { useQuery } from '@apollo/client';
import './CostLiving.scss';

const GUIDE_LIST = gql`
query GUIDE_LIST {
    guides(publicationState: LIVE,
        sort: "createdAt:DESC"){
        id
        Title
        URL
        Description
        Image {
          alternativeText
          url
        }
        File {
          alternativeText
          url
        } 
    }
}
`;

const CostLiving = (props) => {

    const { loading, error, data } = useQuery(GUIDE_LIST);
    const [modalSharefrndformOpen, setSharefrndformOpen] = useState(false);
    const [pdfFile, setPdfFile] = useState("");
    const [title, setTitle] = useState("");


   // Pdf download function
   const downloadFUnction = () => {
    if (pdfFile) {
        // using Java Script method to get PDF file
        fetch(pdfFile).then(response => {
            response.blob().then(file => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(file);
                const PDF = pdfFile.replace(pdfFile, title)

                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = PDF;
                alink.click();
            })
        })
    }
}

    const openSharefrndformModal = (e) => {
        navigate(`/guide/${e.URL}`)
        // setPdfFile(e?.File?.url)
        // setTitle(e?.Title)
        // setSharefrndformOpen(e?.File?.url ? true : false);
    }

    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }
      return (
        <section className='cost_living_landing news-section'>
            <Container>               
                {data?.guides &&
                    <Row className='guide_section gallery-wrapper'>
                        {data?.guides && data?.guides.map((guide, i) => {
                            return (
                                <div className='guide_card gallery-info col-lg-6 col-xl-3'>
                                    <div className="gallery-img img-zoom">
                                        <img src={guide.Image.url} alt="image" onClick={() => openSharefrndformModal(guide?.File?.url ? guide : "")}/>
                                    </div>
                                    <div className="gallery-content">
                                        <h3 className='guide_title'><Link to={`/guide/${guide.URL}`}>{guide.Title}</Link></h3>
                                    </div>
                                </div>
                            )
                        })}
                    </Row> 
                    }
                
            </Container>
        </section>
    )
}

export default CostLiving