import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import ModalTiny from "react-bootstrap/Modal"

import NewsletterForm  from "../../forms/newsletter-form"
import MailingForm from "../../forms/join-mailing-list"


import "./StayUpdate.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const Update = () => {

    const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
    const openSharefrndformModal = () => {
        setSharefrndformOpen(true);
    }
    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                <section className="download-broucher news-letters">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="stay-update"> 
                                    <div className="form-content">
                                    <h2><em>Stay</em> in the loop.</h2>
                                    <p>Sign up to our newsletter to stay up to date with what's happening in your neighbourhood</p>
                                    </div>
                                    <div className="form-wrapper guide-form mailing-list">
                                 
                                    <a href="javascript:void(0)" onClick={()=> { openSharefrndformModal();}} className="btn btn_cray btn-mailing-list">Join Mailing List</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
            <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="book-a-viewing-modal getin-touch">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">In your neighbourhood</h2></ModalTiny.Title>            
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section">
                    <p>Sign up to our newsletter to find out about the latest property market trends, events and happenings in your area.</p>
                    <MailingForm to_email_id={`marketing@redbrik.co.uk`}/>
                    {/*<RegisterForm />*/}
                </ModalTiny.Body>
            </ModalTiny>
        </React.Fragment>
    );
};
export default Update;
