import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils"; 
import PriceComp from "@components/Property/Price"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
 
const PropertyRender = ({ item, tag }) => {


  // property details url structure
  let uriStr = "";
  let imagename = "property.images.similar_properties"
  if (item.search_type === "sales") {
    uriStr = `property-for-sale-details/`
  } else if (item.search_type === "lettings") {
    uriStr = `property-to-rent-details/`
  }

  if (tag == "new-homes") {
    if (item.search_type === "sales") {
      uriStr = `new-homes-for-sale/`
    } else if (item.search_type === "lettings") {
      uriStr = `new-homes-to-rent/`
    }
  }
  if (tag == "new-developments") { 
      uriStr = `new-homes/current-developments-details/` 
      imagename = "new-developments.images.similar_properties"
  } 
  if (tag == "international") { 
    uriStr = `international-property-for-sale-details/` 
    imagename = "international-property.images.similar_properties"
  } 
 
  // property details url structure

  let processedImages = JSON.stringify({});
  if (item?.imagetransforms?.images_Transforms) {
    processedImages = item.imagetransforms.images_Transforms;
  } 

  return (
    <React.Fragment>  
      <div className="properties img-zoom">
        <Link to={'/' + uriStr + item.slug + '-' + item.id}>
          <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename={imagename} attr={{ alt: item.display_address + ' - Redbrik', class: '' }} imagetransformresult={processedImages} id={item.id} />
        </Link>
        <div className="card-details">
          <h3>
            <Link to={'/' + uriStr + item.slug + '-' + item.id}>
              {item.display_address}
            </Link>

          </h3>
          {item.price &&
            <h4>
              <PriceComp {...item} />
            </h4>
          }
          <div className="card-info">
            {item.bedroom &&
              <span className="details">
                <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                  <i className="icon-bedroom"></i>
                </Link>
                {item.bedroom}
              </span>
            }
            {item.bathroom &&
              <span className="details">
                <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                  <i className="icon-bathroom"></i>
                </Link>
                {item.bathroom}
              </span>
            }
            {item.reception &&
              <span className="details">
                <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                  <i className="icon-chair"></i>
                </Link>
                {item.reception}
              </span>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PropertyRender;
