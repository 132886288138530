import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import "./Choice.scss";
import { useStaticQuery, graphql } from "gatsby";
import parse from 'html-react-parser';
import { useLocation } from "@reach/router";
import GoogleReview from "../../../images/google-reviews-white.svg"
import ReviewsCount from '../Reviews/review-count'
// Header component

const Choice = (props) => {

  const hasInfo = props.Intro_Info_Title ? ' has-info' : 'has-noinfo';

  const path = ["/buy/", "/sell/"]
  var { pathname } = useLocation();
  const isPath = path.includes(pathname)
  const [readMore, setReadMore] = useState(props.Intro_Content_One?.length >= 700)

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
  
        reviews(publicationState: LIVE, sort: "Date:DESC" ,where : {Review_Content_ne:null}) {
          id
          Name
          Date
          Publish
          Rating
          Review_Content
          Name_Sub_Text
          Select_Page
          Image {
            url
          }
          Reviews_Link
        }
        
        globalModule {
          Google_Reviews_Content
        }
  
      }
    }
  `);
  let reviews = data.glstrapi.reviews;
  let page_name = props.Pagename.replaceAll(' ', '_')
  reviews = data?.glstrapi?.reviews?.filter(item => item?.Select_Page?.toLowerCase().includes(page_name?.toLowerCase()));
  if(reviews.length===0) {
    page_name = props?.page?.Choose_Menu[0]?.Label
    reviews = data?.glstrapi?.reviews?.filter(item => item?.Select_Page?.toLowerCase().includes(page_name?.toLowerCase()));
  }

  return (
    <React.Fragment>
      <section className="choice" id="section-choice">
        <Container>
          <Row className="about-content">
          {/*<Col lg={12}>
          {props.Title_SM &&
              <span className="small-title">{props.Title_SM}</span>
          }
        </Col> */}
            <Col lg={5}>
              <div className="choice-sm-info">
                {props.Intro_Title &&
                  <>{parse(props.Intro_Title)}</>
                }
                {props.Intro_Info_Title &&
                  <span>{props.Intro_Info_Title}</span>
                }
              </div>
            </Col>
            <Col lg={7}>
              <div className={props.Intro_Content_Two ? "about-content" : "about-content single-col"}>
                    <div className={`expand ${readMore ? "panel-closed" : ""}`}>
                      {reviews[0] &&
                        <>{parse(reviews[0]?.Review_Content)}</>
                      }
                    </div>
                    <div className="aurher-info">
                        {reviews[0] &&
                          <><p>{reviews[0]?.Name}</p>
                              {reviews[0]?.Name_Sub_Text && 
                              <p>,&nbsp;{parse(reviews[0]?.Name_Sub_Text)}</p>
                              }
                          </>
                        }
                    </div>
                    <div className="google-reviews">
                      {reviews[0]?.Reviews_Link?<a target="_blank" href={reviews[0]?.Reviews_Link}>
                        <picture>
                        <img
                        src={GoogleReview}
                        alt="reviews-img01"
                        className="google_review_logo"
                        />
                        </picture>
                        </a>
                        :
                        <picture>
                        <img
                        src={GoogleReview}
                        alt="reviews-img01"
                        className="google_review_logo"
                        />
                        </picture>
                      }
                        <ReviewsCount />
                    </div>
                </div>              
            </Col>            
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Choice;
