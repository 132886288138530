import React, { useEffect, useState, useRef } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
//import axios from 'axios';
import RedbrikRecommendsRender from "./RedbrikRecommendsRender";

import "./CharityPartners.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";

const AREAGUIDELIST = gql`
  query GetredbrikRecommends ($Area_Name: String!){
    redbrikRecommends(
        where: {Area_Name: $Area_Name} limit: 10 ) {
          id         
          URL
          Title
          Tile_Image {
              url          
          }   
      }
  }
`;

const AllAREAGUIDELIST = gql`
  query GetredbrikRecommends {
    redbrikRecommends {
          id         
          URL
          Title
          Tile_Image {
              url          
          }         
      }
  }
`;


const RedbrikRecommends = (props) => { 

  const { loading, error, data } = useQuery(AllAREAGUIDELIST);
  const { loading: ChesterfieldLoad, error: ChesterfieldErr, data: Chesterfield } = useQuery(AREAGUIDELIST, { variables: { Area_Name: "Chesterfield" } });
  const { loading: SheffieldLoad, error: SheffieldErr, data: Sheffield } = useQuery(AREAGUIDELIST, { variables: { Area_Name: "Sheffield" } });
  const { loading: CrystalLoad, error: CrystalErr, data: Crystal } = useQuery(AREAGUIDELIST, { variables: { Area_Name: "Crystal_Peaks" } });
  const { loading: StocksbridgeLoad, error: StocksbridgeErr, data: Stocksbridge } = useQuery(AREAGUIDELIST, { variables: { Area_Name: "Stocksbridge" } });
  //console.log(Crystal)
  const [activetype, setActivceType] = useState("all")

  useEffect(() => {
    /*var propertySelector = '.all-featured-properties .type-list li';

    $(propertySelector).on('click', function(){
        $(propertySelector).removeClass('active');
        $(this).addClass('active');
    });*/
  },[]);

  let sliderRef = useRef(null);
  const play = () => {
    sliderRef.slickPlay();
  };
  const pause = () => {
    sliderRef.slickPause();
  };

  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: false,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 991,       
        settings: { 
          mobileFirst: true,
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 767,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const settingsAll = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 991,       
        settings: { 
          mobileFirst: true,
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 767,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsAllChesterfield = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 991,       
        settings: { 
          mobileFirst: true,
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },

      {
        breakpoint: 767,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };


  if (loading) return null;

  return (
    <React.Fragment>
      <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

        <section className={`similar-properties all-featured-properties guide-block charityPartners lifystyle ${props?.BackgroundColor}`}>
          <Container>
            <Row>
              <Col sm={12}>
                <div className="heading">
                  {(props.Title || props.Bold_Title) ? parse(`<h2><i>${props.Bold_Title? props.Bold_Title : ''}</i> ${props.Title? props.Title : ''}</h2>`) : parse("<h2>All Featured<br /><span>properties</span></h2>")}
                  <div className="type-list">
                    <ul>
                      <li className={activetype == "all" ? "active" : ""} onClick={() => { setActivceType('all') }}>All</li>
                      <li className={activetype == "Chesterfield" ? "active" : ""}onClick={() => { setActivceType('Chesterfield') }}>Chesterfield</li>
                      <li className={activetype == "Sheffield" ? "active" : ""}onClick={() => { setActivceType('Sheffield') }}>Sheffield</li>
                      <li className={activetype == "Crystal_Peaks" ? "active" : ""} onClick={() => { setActivceType('Crystal_Peaks') }}>Crystal Peaks</li>
                      <li className={activetype == "Stocksbridge" ? "active" : ""} onClick={() => { setActivceType('Stocksbridge') }}>Stocksbridge</li>
                    </ul>
                  </div>
                </div>

              <div className={`slick-wrap ${activetype == "all" ? 'd-block' : 'd-none'}`}>
                  {data?.redbrikRecommends.length ?
                    <Slider ref={slider => (sliderRef = slider)} {...settingsAll}>

                      {data && data?.redbrikRecommends.map((item, index) => {
                        return (
                          <RedbrikRecommendsRender item={item} tag={``} />
                        )
                      })
                      }
                      {data && data?.redbrikRecommends.map((item, index) => {
                        return (
                          <RedbrikRecommendsRender item={item} tag={``} />
                        )
                      })
                      }
                    </Slider> : 'No Recommendations found'
                    }
                </div>

              <div className={`slick-wrap ${activetype == "Chesterfield" ? 'd-block' : 'd-none'}`}>
                  {Chesterfield?.redbrikRecommends.length ?
                    <Slider ref={slider => (sliderRef = slider)} {...settingsAllChesterfield}>

                      {Chesterfield && Chesterfield?.redbrikRecommends.map((item, index) => {
                        return (
                          <RedbrikRecommendsRender item={item} tag={``} />
                        )
                      })
                      }
                    </Slider> : 'No Recommendations found'
                  }
                </div>

                <div className={`slick-wrap ${activetype == "Sheffield" ? 'd-block' : 'd-none'}`}>
                  {Sheffield?.redbrikRecommends.length ?
                    <Slider {...settings}>

                      {Sheffield && Sheffield?.redbrikRecommends.map((item, index) => {
                        return (
                          <RedbrikRecommendsRender item={item}  />
                        )
                      })
                      }
                    </Slider> : 'No Recommendations found'
                  }
                </div>

                <div className={`slick-wrap ${activetype == "Crystal_Peaks" ? 'd-block' : 'd-none'}`}>
                  {Crystal?.redbrikRecommends.length ?
                    <Slider {...settings}> 
                      {Crystal && Crystal?.redbrikRecommends.map((item, index) => {
                        return (
                          <RedbrikRecommendsRender item={item} />
                        )
                      })
                      }
                    </Slider> : 'No Recommendations found'
                  }
                </div>

                <div className={`slick-wrap ${activetype == "Stocksbridge" ? 'd-block' : 'd-none'}`}>
                  {Stocksbridge?.redbrikRecommends.length ?
                    <Slider {...settings}> 
                      {Stocksbridge && Stocksbridge?.redbrikRecommends.map((item, index) => {
                        return (
                          <RedbrikRecommendsRender item={item} />
                        )
                      })
                      }
                    </Slider> : 'No Recommendations found'
                  }
                </div>
                
              </Col>
            </Row>
          </Container>
                </section>

      </ScrollAnimation> 
    </React.Fragment>
  ); 
};

export default RedbrikRecommends;
