import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import parse from "html-react-parser"
import "./ShortStatement.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const ShortStatement = (props) => {

    return (
        <React.Fragment>
                <section className="statement-section">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="heading">
                                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                                    {props.Grid_Services_Intro &&
                                    <>
                                        {parse(props.Grid_Services_Intro)}
                                    </>
                                    }
                                </ScrollAnimation>
                                </div>
                                <div className="statement-info">

                                    {props.Add_Services.map((service, key) => {
                                        let icon_class = ''
                                        
                                        if(service.Choose_Icon === "Team") {
                                            icon_class = 'team'
                                        }
                                        else if(service.Choose_Icon === "Online_Portal") {
                                            icon_class = 'client'
                                        }
                                        else if(service.Choose_Icon === "Smiley") {
                                            icon_class = 'happy'
                                        }
                                        else if(service.Choose_Icon === "Calendar") {
                                            icon_class = 'calendar'
                                        }
                                        else if(service.Choose_Icon === "Clock") {
                                            icon_class = 'timer'
                                        }
                                        else if(service.Choose_Icon === "Throughs") {
                                            icon_class = 'throughs'
                                        }
                                        else if(service.Choose_Icon === "Shirt") {
                                            icon_class = 'tshirt'
                                        }
                                        else if(service.Choose_Icon === "Trophy") {
                                            icon_class = 'trophy'
                                        }
                                        else if(service.Choose_Icon === "House") {
                                            icon_class = 'house'
                                        }
                                        else if(service.Choose_Icon === "Pound_Sign") {
                                            icon_class = 'poundsign'
                                        }
                                        else if(service.Choose_Icon === "Lightning_Bolt") {
                                            icon_class = 'lightning'
                                        }
                                        else if(service.Choose_Icon === "Checklist") {
                                            icon_class = 'checklist'
                                        }
                                        else if(service.Choose_Icon === "Building") {
                                            icon_class = 'building'
                                        } 
                                        else if(service.Choose_Icon === "Speech_Bubble") {
                                            icon_class = 'speechbubble'
                                        }
                                        else if(service.Choose_Icon === "Suitcase") {
                                            icon_class = 'suitcase'
                                        }

                                        let ani_time = 50
                                        return (
                                            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={ani_time*key} className={`statement-icon-item ${icon_class}`}>
                                                <Link href="javascript:void(0)">
                                                    <i className={`icon-${icon_class}`}></i>
                                                </Link>
                                                {parse(service.Content)}
                                            </ScrollAnimation>
                                        )
                                    })}

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

        </React.Fragment>
    );
};

export default ShortStatement;
