import React from "react";
import parse from 'html-react-parser';
import {Row, Col, Container } from 'react-bootstrap';

import GenerateLink from "../../common/site/generate-link"
import PlayVideo from "../../Play/custom-video";

const StaticContent = (props) => {

    return (
        <div className={`static-content-block ${props.banner}`}>
            <Container>
                <Row>
                    <Col sm={12} lg={12}>
                    {props.data && props.data.map((module, i) => {
                        return (
                            <>
                            {module.Add_Static_Content &&
                            <div className="plain-content">
                            {parse(module.Add_Static_Content)}
                            </div>
                            }
                            </>
                        )
                    })}
                    </Col>
                    {/*
                    <Col sm={12} lg={4}>
                    {props.data && props?.data.map((module, i) => {
                        return (
                            <div className="sidebar-main" key={i}>
                            {module.Right_Sidebar_Video_Background_Image &&
                                <div className="video-play status">
                                <img src={module.Right_Sidebar_Video_Background_Image.url} />
                                    {module.Right_Sidebar_Video_URL && <div className="video-wrapper video-with-banner-content-play">
                                        <div className="video-buttons">
                                            <PlayVideo url={module.Right_Sidebar_Video_URL} />
                                        </div>
                                    </div>}
                                </div>
                            }
                            {module.Right_Sidebar_Content &&
                                <div className="sidebar">
                                    {parse(module.Right_Sidebar_Content)}

                                    {module.Right_Sidebar_CTA_Label &&
                                        <GenerateLink class="btn" link={module.Right_Sidebar_CTA_Link}>
                                            {module.Right_Sidebar_CTA_Label}
                                        </GenerateLink>
                                    }
                                </div>
                            }
                            </div>
                        )
                    })}
                    </Col>
                */}
                </Row>
            </Container>
        </div>
    )
}

export default StaticContent;
