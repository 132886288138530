import React from "react";
import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";

// Header component
const ReviewsCount = (props) => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      
      reviews(publicationState: LIVE) {
        id
        Name
        Rating
      }

    }
  }
`);

const reviews = data.glstrapi.reviews;

const reviewsCount = data.glstrapi.reviews.length;
const wordToNumber = {'One': 1, 'Two': 2, 'Three': 3, 'Four': 4, 'Five': 5};
let sumOfScores = 0;

reviews.map((review) => {
    const reviewStringValue = review.Rating;
    sumOfScores += wordToNumber[reviewStringValue];
    return(sumOfScores)
})

const averageRating = Math.round(sumOfScores / reviewsCount * 10) / 10;

  return (
    <>
    {/*<React.Fragment>
        <div className={`${props.class ? props.class : "rating"} `}>
            Rated <span className="total">{averageRating}/5</span>
        </div>
  </React.Fragment>*/}
    </>
  );
};

export default ReviewsCount;
