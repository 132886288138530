import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import parse from 'html-react-parser';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import update01 from "../../../images/static/update01.png"
import update001 from "../../../images/static/updatedesk001.png"
import update002 from "../../../images/static/updatedesk002.png"
import update003 from "../../../images/static/updatedesk003.png"
import update0001 from "../../../images/static/update0001.png"
import update0002 from "../../../images/static/update0002.png"
import update0003 from "../../../images/static/update0003.png"
import update0004 from "../../../images/static/update0004.png"

import "./LatestUpdate.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const LatestUpdate = () => {
    
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        magazines(publicationState: LIVE, sort: "Date:desc") {
            id
            Date
            Latest
            Title
            Magazine_URL
            imagetransforms
            Content
            Upload_Magazine_File {
                url
            }
            Image {
              alternativeText
              url
            }
        }
    }
  }
`);

const magazines = data.glstrapi.magazines;
    return (
        <React.Fragment>

            <section className="Latest-update">
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="stay-wrapper">
                                    <Row>
                                    {magazines.map((node, key) => {

                                        const image_url = node.Image.url 

                                        let processedImages = JSON.stringify({});
                                        if (node?.imagetransforms?.Image_Transforms) {
                                            processedImages = node.imagetransforms.Image_Transforms;
                                        }

                                        return (
                                        <div className="col-lg-4 one-col">
                                            <a href={node?.Upload_Magazine_File?.url?node?.Upload_Magazine_File?.url:node.Magazine_URL} target="_blank">
                                            <div className={`stay-img ${node.Latest ? 'images' : '' } img-zoom`}>
                                                <div className="issue-shadow">
                                                <ImageTransform  imagesources={image_url} renderer="srcSet" imagename="magazine.Image.tile" attr={{ alt: node.Title+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={node.id}/>
                                                </div>
                                                <div className="stay-info">
                                                    <h4>{node.Title}</h4>
                                                    {parse(node.Content)}
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                        )
                                    })}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment>
    );
};
export default LatestUpdate;
