import React, {useEffect, useState} from "react";
import { Link } from "@StarberryUtils"
import {Row, Col, Container } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
// import $ from "jquery";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import TeamSlider from "../Home/Services/TeamSlider"
import logoBlack from "../../images/redbrik-new-logo.svg";
import { isTablet, isMobile, isDesktop } from 'react-device-detect';
import { LATEST_NEWS_PAGE_URL, MEET_THE_TEAM_PAGE_URL } from "../common/site/constants";

import MailForm from "./team-landing-form"

import _ from "lodash"

import "../StaticPage/AboutPage/AboutPage.scss";
import "../StaticPage/News/News.scss";


const GET_ALL_TEAMS_CAT = gql`
query GETTEAMS($URL: String!){
  peoples(sort: "Sort:asc",where:{Publish:true,Choose_Category:{URL: $URL}}) {
    Name
    URL
    id
    Designation
    Email
    Image {
      url
    }
    Choose_Category {
      URL
    }
    imagetransforms
  }

  peopleCategories(sort: "Sort:asc",where:{Publish:true}) {
    Name
    URL
  }
}
`;


function TeamList(props) {

    const [allTeam, setAllTeam] = useState([])

    const { loading, error, data } =  useQuery(GET_ALL_TEAMS_CAT, {
        variables: { URL: props.Select_Category_here.URL }
      });


    if (!loading && _.isEmpty(allTeam)) {
        setAllTeam(data.peoples)
      }



    const chunks = _.chunk(allTeam, 9);

    var temp = []

    chunks.map((person,key) => {
        temp.push(_.take(person, 5))
        temp.push(_.slice(person, 5, person.length))
    })

    const ImageRender = ({ image_url, node, processedImages }) => {
        return (
          <ImageTransform imagesources={image_url} renderer="srcSet" imagename="peoples.Image.tile_1" attr={{ alt: node.Name + ' - Redbrik', class: '' }} imagetransformresult={processedImages} id={node.id} />
        )
      }


    if (loading) return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img className="logo-white loader-logo" src={logoBlack} alt="logo"/>
            </div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
          </div>
        </section>
        );


    return (
        <div class="team-module">
            <TeamSlider team={allTeam}/>
        </div>
    )
    {/* Not sure abut following module for now overwrite ith above */}
    return (
        <Container className="team-landing">
            <h1>{props.Pagename}</h1>
            {(!isMobile) &&
            <div className="about-page">
            {temp.map((teams, key) => {
                return (
                <Row className="team-row">
                    {teams.length <= 4 &&
                    <>
                    {teams.map((node, key) => {
                        const image_url = node.Image?.url

                        let processedImages = JSON.stringify({});
                        if (node?.imagetransforms?.Image_Transforms) {
                            processedImages = node.imagetransforms.Image_Transforms;
                        }

                        return (
                            <Col sm={12} lg={3}>
                                <div className="team-member-tile">
                                    <div className="about-img ">
                                    <Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${node.URL}`}>

                                        <ImageRender processedImages={processedImages} image_url={image_url} node={node} />
                                    </Link>
                                        {/* <div className="video-buttons">
                                            <strong className="video-btn">
                                                <i className="icon-video"></i>
                                            </strong>
                                        </div> */}
                                        <div className="person-info">
                                            <Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${node.URL}`}><h2 className="h4">{node.Name}</h2></Link>
                                            {node.Designation && <div className="designation">{node.Designation}</div> }
                                            <MailForm name={node.Name} email={node.Email} />
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        )
                    })}
                    </>
                    }

                    {teams.length == 5 &&
                    <>
                    <Col sm={12} lg={6}>
                    {teams.slice(0,1).map((node, key) => {
                        const image_url = node.Image.url

                        let processedImages = JSON.stringify({});
                        if (node?.imagetransforms?.Image_Transforms) {
                            processedImages = node.imagetransforms.Image_Transforms;
                        }

                        return (
                                <div className="team-member-tile">
                                    <div className="about-img tile-main">
                                    <Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${node.URL}`}>
                                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="peoples.Image.tile_big" attr={{ alt: node.Name+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={node.id}/>
                                    </Link>
                                        {/* <div className="video-buttons">
                                            <strong className="video-btn">
                                                <i className="icon-video"></i>
                                            </strong>
                                        </div> */}

                                        <div className="person-info">
                                            <Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${node.URL}`}><h2 className="h4">{node.Name}</h2></Link>
                                            {node.Designation && <div className="designation">{node.Designation}</div> }
                                            <MailForm name={node.Name} email={node.Email} />
                                        </div>
                                    </div>

                                </div>
                        )
                    })}
                    </Col>
                    <Col sm={12} lg={6}>
                    <Row>
                    {teams.slice(1,5).map((node, key) => {
                        const image_url = node.Image.url

                        let processedImages = JSON.stringify({});
                        if (node?.imagetransforms?.Image_Transforms) {
                            processedImages = node.imagetransforms.Image_Transforms;
                        }

                        return (
                            <Col sm={12} lg={6}>
                                <div className="team-member-tile">
                                    <div className="about-img">
                                    <Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${node.URL}`}>
                                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="peoples.Image.tile" attr={{ alt: node.Name+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={node.id}/>
                                    </Link>
                                        {/* <div className="video-buttons">
                                            <strong className="video-btn">
                                                <i className="icon-video"></i>
                                            </strong>
                                        </div> */}
                                        <div className="person-info">
                                            <Link to={`${MEET_THE_TEAM_PAGE_URL.alias}/${node.URL}`}><h2 className="h4">{node.Name}</h2></Link>
                                            {node.Designation && <div className="designation">{node.Designation}</div> }
                                            <MailForm name={node.Name} email={node.Email} />
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        )
                    })}
                    </Row>
                    </Col>
                    </>
                    }

                </Row>
                )
            })}
            </div>
            }

            {(isMobile) &&
                <div className="about-page row">
                {allTeam.map((node, key) => {
                    const image_url = node.Image?.url

                    let processedImages = JSON.stringify({});
                    if (node?.imagetransforms?.Image_Transforms) {
                        processedImages = node.imagetransforms.Image_Transforms;
                    }

                        return (
                            <Col xs={6}>
                                <div className="team-member-tile">
                                    <div className="about-img ">
                                    <Link to={node.URL}>

                                        <ImageRender processedImages={processedImages} image_url={image_url} node={node} />
                                    </Link>
                                        {/* <div className="video-buttons">
                                            <strong className="video-btn">
                                                <i className="icon-video"></i>
                                            </strong>
                                        </div> */}
                                        <div className="person-info">
                                            <Link to={node.URL}><h2 className="h4">{node.Name}</h2></Link>
                                            {node.Designation && <div className="designation">{node.Designation}</div> }
                                            <MailForm name={node.Name} email={node.Email} tag="name" formLabel="Person Contact"/>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        )
                })}
                </div>
            }
        </Container>
    )
}

export default TeamList
