import React, { useState, useEffect } from "react";
import {Row, Col, Container } from 'react-bootstrap';
import parse from 'html-react-parser';
import $ from "jquery";
import GenerateLink from "../common/site/generate-link"
import PlayVideo from "../Play/custom-video";
import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { sitename } from "@constants";
import { get } from 'lodash';
import { Link } from "@StarberryUtils"

import "../ProperyDetails/Status/Status.scss"
import "./StaticBanner.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const StaticPage = (props) => {

  const ctas = get(props, 'Buttons', []);
  const img = get(props, 'Banner_Image.url')
  console.log('Page_Modules ctas', ctas)
  const [showModal, setShowModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const openModal = () => {
    setShowModal(true);
  }
  const hideModal = () => {
    setShowModal(false);
  }

  const handleonClick = (e, cta) => {
    e.preventDefault();
    setShowModal(true);
    setModalData(cta)
  }

  let gatsbyImg = getImage(props?.Banner_Image?.url_sharp)

  let pagename = props?.Pagename;
  let isHome = false;
  if (pagename === 'Home'){
    pagename = 'property service';
    isHome = true;
  }


  return (
    <section className="home-banner home-banner-links">
      <div className="banner-item">
          <div className="banner-img">
            {/* When using ggfx clitn component, banner image getting time to load, facing some jump issue. Using gatsby image to over come this */}
            <GatsbyImage image={gatsbyImg} alt={img.alternativeText || `${pagename} Banner - ${sitename} Estate Agents`} className="d-block" />
          </div>

          <div className="banner-content-alt d-lg-flex justify-content-center">
            <div className="static-service">
              <div>
               {isHome?
               <>
                {props.Heading && (
                  <h2 className="h1">{props.Pre_Title &&
                    <span className={`${isHome ? "normal" : "bold"}`}>
                      {props.Pre_Title}
                    </span>
                  } <span className={`${isHome ? "bold" : "normal"}`}>{props.Heading}</span></h2>
                )}
                 {props.Sub_Heading && (
                  <h1 className="h2">{props.Sub_Heading}</h1>
                )}
                </>
                 :
                props.Heading && (
                  <h1>{props.Pre_Title &&
                    <span className={`${isHome ? "italic" : "bold"}`}>
                      {props.Pre_Title}
                    </span>
                  } <span className={`${isHome ? "bold" : "normal"}`}>{parse(props.Heading)}</span></h1>
                )
              }

                {props.Banner_Main_Content &&
                  <>{parse(props.Banner_Main_Content)}</>
                }
                  <div className="btn-list">
                        {props.Banner_CTA_Link && props.Pagename != "Careers" ?

                        <GenerateLink class="btn transparent" link={props.Banner_CTA_Link} label={props.Banner_CTA_Label} >
                        {props.Banner_CTA_Label}
                        </GenerateLink> :
                        <a class="btn btn-trans all-jobs-list">{props.Banner_CTA_Label}</a>                       
                        }
                  </div>
              </div>
            </div>
          </div> 
      </div>
    </section>
  )
};

export default StaticPage;
