import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import parse from 'html-react-parser';
import Img from 'gatsby-image';
import "./GetInTouch.scss";

import ContactForm from "../forms/contact-form"

import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const GetInTouch = (props) => {
    return (
        <React.Fragment>
            <section className="getin-touch">
                {props.Form_Background_Image &&
                <div className="banner-img">
                    <Img fluid={props.Form_Background_Image.url_sharp.childImageSharp.fluid} alt={props.Form_Background_Image.alternativeText} />
                </div>
                }
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className="query">
                                {props.Form_Info_Title &&
                                <span className="sm-heading">
                                    {props.Form_Info_Title}
                                </span>
                                }
                                {props.Form_Header_Content &&
                                <>
                                {parse(props.Form_Header_Content)}
                                </>
                                }
                                <div className="form-wrapper">
                                    <ContactForm formLabel="Contact - General Enquiry" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default GetInTouch;
