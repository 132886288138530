import React from "react"
import { Link } from "@StarberryUtils"
import { sitename } from "@constants";
import "./Services.scss"
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Row, Col } from "react-bootstrap"
import GenerateLink from "../../common/site/generate-link"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

const Services = (props) => {

    return (
        <React.Fragment>
            <section className={`services ${props.class} ${props.Services_Title ? 'service-bottom' : ''}`}>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                                {props.Services_Title &&
                                <div className="service">
                                    <h2>{props.Services_Title}</h2>

                                    <GenerateLink class="learn-more" link={props.Services_CTA_Link}>
                                    {props.Services_CTA_Label}
                                    </GenerateLink>

                                </div>
                                }
                                </ScrollAnimation>
                                <div className="service-wrapper">

                                {props.Service_Tile && props.Service_Tile.map((tile, i) => {
                                    let anim_time = 50 * i
                                    return (
                                    <div className="service-img img-zoom">
                                    <ScrollAnimation className="h-100" animateIn="fadeInUp" delay={anim_time} animateOnce={true}>
                                        <GenerateLink link={tile.Service_Tile_Link}>
                                            <ImageTransform imagesources={tile.Service_Tile_Image.url} renderer="srcSet" imagename="articles.Service_Tile_Image.default"
                                                                   attr={{ alt: (tile.Service_Tile_Image.alternativeText || `${tile.Service_Tile_Label} ${sitename} Estate Agents`)}}
                                                                   imagetransformresult={props?.processedImages || {}} id={props?.pageId} testparam={true} />
                                            {/*<picture>
                                                <source media="(min-width:992px)" srcSet={tile.Service_Tile_Image.url} />
                                                <source media="(min-width:768px)" srcSet={tile.Service_Tile_Image.url} />
                                                <img src={tile.Service_Tile_Image.url} alt={tile.Service_Tile_Image.alternativeText} />
                                            </picture>*/}
                                            <div className="service-info">
                                                <GenerateLink link={tile.Service_Tile_Link}>
                                                    {tile.Service_Tile_Label}
                                                </GenerateLink>
                                            </div>
                                        </GenerateLink>
                                    </ScrollAnimation>
                                    </div>
                                    )
                                })}
                                </div>
                            </Col>
                        </Row>
                    </Container>
            </section>
        </React.Fragment>
    )
}

export default Services
