import React, { useState } from "react";
import ModalTiny from "react-bootstrap/Modal"
import gql from "graphql-tag"

import Contact from "../forms/contact-form"



function TeamForm(props) {
    const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
    const openSharefrndformModal = () => {
        setSharefrndformOpen(true);
    }
    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }


    return (
        <React.Fragment>
            {props.tag == 'name' ?
            <a href="javascript:void(0)" onClick={()=> { openSharefrndformModal();}} className="email">Email {props.name.split(" ")[0]}</a> : props.tag == 'contact' ?
            <a href="javascript:void(0)" onClick={()=> { openSharefrndformModal();}} className="sm-info">Email {props.name}</a> : 
            props.tag == 'teampage' ?
            <a href="javascript:void(0)" onClick={()=> { openSharefrndformModal();}} className="email">Email</a> : 
            props.tag == "office-detail" ?
            <a href="javascript:void(0)" onClick={()=> { openSharefrndformModal();}} className={`${props.class} ${props.tag != "office-1" ? 'email' : ''}`}>Email Office</a> :
            props.tag == "office-2" ?
            <a href="javascript:void(0)" onClick={()=> { openSharefrndformModal();}} className={`${props.class} ${props.tag != "office-1" ? 'email' : ''}`}>Contact Us</a> :
            props.tag == "teamlist" ?
            <a href="javascript:void(0)" onClick={()=> { openSharefrndformModal();}} className={`${props.class} ${props.tag != "office-1" ? 'email' : ''}`}>{props.label}</a> :
            <a href="javascript:void(0)" onClick={()=> { openSharefrndformModal();}} className={`${props.class} ${props.tag != "office-1" ? 'email' : ''}`}>{props.email}</a>
            }
            
          <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="getin-touch book-a-viewing-modal">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Contact {props.name}</h2></ModalTiny.Title>
          </ModalTiny.Header>
          
          <ModalTiny.Body className="event-section">
            <Contact to_email_id={props.email} member_name={`${props.member_name != null ? props.name : ''}`} {...props}/>
          </ModalTiny.Body>

          </ModalTiny>

        </React.Fragment>
    );
};

export default TeamForm;
