import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
const RedbrikRecommendsRender = ({ item, tag }) => {

    const image_url = item?.Tile_Image?.url
    let processedImages = JSON.stringify({});

    return (
        <React.Fragment>           
            <div className="properties img-zoom">
                <div className="properties-img">                 
                <a href={`/lifestyle/redbrik-recommends/${item?.URL}`}>
                     {/*<img src={item?.Tile_Image?.url} alt="image" />*/}
                        <ImageTransform
                        imagesources={item?.Tile_Image?.url}
                        renderer="srcSet"
                        imagename="articles.Image_Block.detail"
                        imagetransformresult={processedImages}
                        id={item.id} />
                    </a>
                </div>
                <div className="gallery-text">
                <a href={`/lifestyle/redbrik-recommends/${item?.URL}`}><h3 className='guide_title'>{item?.Title}</h3></a>
                </div>
            </div>                                       
        </React.Fragment>
    );
};

export default RedbrikRecommendsRender;
