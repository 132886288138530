import React, { useState, useEffect, useRef } from "react";
import { get } from 'lodash';
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import PlayVideo from "../../Play/custom-video";
import parse from 'html-react-parser';
import videoUrl from "../../../images/selling/iPhone-fashion-chatbot2.mp4";
// import videoUrl2 from "../../../images/selling/2.mp4";
import videoUrl2 from "../../../images/selling/iPhone-fashion-chatbot3.mp4";
import Img001 from "../../../images/selling/img04.png"
// import Img002 from "../../../images/selling/img04.png"
// import Img003 from "../../../images/selling/img04.png"
import "../SocialPromotion/SocialPromotion.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component
const VideoPromotion = (props) => {
    const image  = get(props, 'Add_Image_here.url', Img001)
    return (
        <React.Fragment>
            <section className={`about-part ku image-promotion social-promotion  ${props?.Bg_Color} ${props?.Animation_Type} ${props?.Content_Position} ${props?.Module_Width}`}>
                <div class="container-parent">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                {(props?.Content_Position === 'Top') && (
                                    <div className="promotion-info about-info">
                                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                                            {parse(props.Content)}
                                        </ScrollAnimation>
                                    </div>
                                )}
                                <div className="promotion-img  about-img">
                                    <picture class="phone-frame">
                                        <img src={image} alt={props?.Add_Image_here?.alternativeText} />
                                    </picture>
                                    {props?.Video && (
                                        <div className="video-buttons">
                                            <PlayVideo url={props.Video} />
                                        </div>
                                    )}
                                </div>
                                {(props?.Content_Position !== 'Top') && (
                                    <div className="promotion-info about-info">
                                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                                            {parse(props.Content)}
                                        </ScrollAnimation>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    );
};
export default VideoPromotion;
