import React, { } from "react"
import { get } from 'lodash';
import { Link } from "@StarberryUtils"
import "./Banner.scss"
import { Container, Row, Col } from "react-bootstrap";
import ModalTiny from "react-bootstrap/Modal"
import Slider from "react-slick";
import parse from 'html-react-parser';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import GenerateLink from "../../common/site/generate-link"
import { sitename } from "@constants";

import bannerlogo from "../../../images/banner-logo.png"

// Header component
  let slider = {};

  const Banner = (props) => {
    const ctas = get(props, 'Ctas', []);
    const img = get(props, 'Banner_Image.url')

    const [showModal,setShowModal] = React.useState(false);
    const [modalData,setModalData] = React.useState({});
    const openModal = () => {
      setShowModal(true);
    }
    const hideModal = () => {
      setShowModal(false);
    }

  const handleonClick = (e, cta) => {
     e.preventDefault();
     setShowModal(true);
     setModalData(cta)
  }

  let gatsbyImg = getImage(props?.Banner_Image?.url_sharp)

  let pagename = props?.Pagename;
  if (pagename === 'Home')
    pagename = 'property service';

  return (
    <section className="home-banner home-banner-links">
        <div className="banner-img">
          {/* When using ggfx clitn component, banner image getting time to load, facing some jump issue. Using gatsby image to over come this */}
          <GatsbyImage image={gatsbyImg} alt={img.alternativeText || `${pagename} Banner - ${sitename} Estate Agents`} className="d-block"/>
        </div>

      <div className="banner-content-alt d-lg-flex justify-content-center">
        <div className="">
          <div>
              {props.Sub_Title &&
              <span className="sm-info">
                  {props.Sub_Title}
              </span>
              }
              {props.Title && (
                <h1>{props.Title}</h1>
              )}
            {(ctas.length) && (
                <div className="btn-list">
                    {ctas.map((cta, i) => {
                        if (cta?.Target === 'Popup') {
                            return(
                                <a href={cta.Cta_Link} onClick={(e) => handleonClick(e, cta)} className="btn btn-trans">{cta.Cta_Label}</a>
                            )
                        } else {

                            if (cta?.Cta_Link.match(/http|https/)) {
                                return(
                                    <a href={cta.Cta_Link} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className="btn btn-trans">{cta.Cta_Label}</a>
                                )
                            } else {
                                return(
                                    <Link to={cta.Cta_Link} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className="btn btn-trans">{cta.Cta_Label}</Link>
                                )
                            }

                        }
                    })}
                </div>
            )}
          </div>
        </div>
      </div>
      <ModalTiny show={showModal} onHide={hideModal} className="book-a-viewing-modal iframe-modal">
          <ModalTiny.Header closeButton className="contact-close-btn">
              <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">{modalData?.Cta_Label}</h2></ModalTiny.Title>
          </ModalTiny.Header>
          <ModalTiny.Body className="event-section">
              <iframe src={modalData?.Cta_Link} width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
          </ModalTiny.Body>
      </ModalTiny>
    </section>

  )
}
export default Banner
