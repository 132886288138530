import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import "../GetInTouch/GetInTouch.scss";
import RegisterForm from "../forms/register-form"
import parse from 'html-react-parser';
import Img from 'gatsby-image';

import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const Register = (props) => {
    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                <section className="getin-touch getin-touch-register">
                    { props.Form_Background_Image &&
                   <>
                    {/*<div className="banner-img">
                        <Img fluid={props.Form_Background_Image.url_sharp.childImageSharp.fluid} alt={props.Form_Background_Image.alternativeText} />
                    </div>*/}
                    </>
                    }
                    <Container>
                        <Row>
                            <Col sm={12}>
                            <div className="query">                                   
                                    {props.Form_Info_Title &&
                                    <span className="sm-heading">
                                        {props.Form_Info_Title}
                                    </span>
                                    }
                                    {props.Form_Header_Content &&
                                    <>
                                    {parse(props.Form_Header_Content)}
                                    </>
                                    }
                                    <div className="form-wrapper">
                                        <RegisterForm />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
        </React.Fragment>
    );
};

export default Register;
