import React, { } from "react"
import { Link } from "@StarberryUtils"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import { isMobile, isMobileOnly, isIPhone13 } from 'react-device-detect';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

import GenerateLink from "../../common/site/generate-link"
import "./Contact.scss"
// Header component

const Contact = (props) => {
    return (
        <section className="get-in-touch">
            <div className="pic">
                <ImageTransform imagesources={props.Get_in_Touch_Background_Image.url} renderer="srcSet" imagename={props?.imagename || `articles.Get_in_Touch_Background_Image.default`}
                    attr={{ alt: 'Branch' }}
                    imagetransformresult={props?.processedImages || {}} id={props?.pageId} testparam={true} />
            </div>
           {/* <div className="contact">
                <Container>
                    <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}>
                        <div className="contact-info">
                            {props.Get_in_Touch_Content ? parse(props.Get_in_Touch_Content) : ''}

                            <div className="btn-wrapper desktop">

                                {props.Get_in_Touch_CTA_1_Label &&
                                    <GenerateLink class="btn" link={props.Get_in_Touch_CTA_1_Link}>
                                        {props.Get_in_Touch_CTA_1_Label}
                                    </GenerateLink>
                                }

                                {props.Get_in_Touch_CTA_2_Label &&
                                    <GenerateLink class="btn" link={props.Get_in_Touch_CTA_2_Link}>
                                        {props.Get_in_Touch_CTA_2_Label}
                                    </GenerateLink>
                                }

                            </div>
                          
                        </div>
                    </ScrollAnimation>
                </Container>
                            </div>*/}
        </section>
    )
}
export default Contact
