import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Choice.scss";
import PlayVideo from "../../Play/custom-video";
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
// Header component

const Video = (props) => {
    const [isPlay, setPlay] = useState(false);
    return (
        <React.Fragment>
            <section className="choice">
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className={"choice-img "+props.Custom_Class}>
                                    <ImageTransform 
                                        imagesources={props.Video_Background_Image.url} 
                                        renderer="srcSet" 
                                        imagename="articles.Video_Background_Image.detail"
                                        attr={{ alt: props?.Video_Background_Image?.alternativeText, className: 'img-zoom' }}
                                        imagetransformresult={props?.processedImages || {}} 
                                        id={props?.pageId} 
                                        testparam={true} 
                                    />

                                    {/*<picture className="img-zoom">
                                        <source media="(min-width:1800px)" srcSet={props.Video_Background_Image.url} />
                                        <source media="(min-width:992px)" srcSet={props.Video_Background_Image.url} />
                                        <source media="(min-width:768px)" srcSet={props.Video_Background_Image.url} />
                                        <img src={props.Video_Background_Image.url} alt={props.Video_Background_Image.alternativeText} />
                                    </picture>*/}
                                    <div className="video-buttons">
                                        {props.Video_Embed_URL &&
                                        <PlayVideo url={props.Video_Embed_URL} />
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment>
    );
};

export default Video;
