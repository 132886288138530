import { pickBy, identity, isArray, get, isEmpty, isString, find } from "lodash"
import { toTitleCase } from "../common/site/utils";
import { defaultArea } from "@constants";
const defaultarea = defaultArea.slug;
const SEARCH_BASE_URL = '';
const METER_VAL =  1609.34;

const bedOptions = ['Any', '1 +' , '2 +', '3 +', '4 +', '5 +', '6 +', '7 +', '8 +', '9 +']

const buildingOptions = [
  { value: "", label: "All" },
  { value: "apartment", label: "Apartments" },
  { value: "flat", label: "Flats" },
  { value: "bungalow", label: "Bungalows" },
  { value: "detached-house", label: "Detached Houses" },
  { value: "semi-detached-house", label: "Semi Detached Houses" },
  { value: "terraced-house", label: "Terraced Houses" },
  // { value: "full-floor", label: "Full floor" },
  // { value: "hotel-apartment", label: "Hotel/Hotel Apartment" },
  // { value: "shop", label: "Shop" }
  // { value: "property", label: "Others" },
]

const newBuildingOptions = [
    { value: "", label: "All" },
    { value: "Apartment", label: "Apartments" },
    { value: "Bungalow", label: "Bungalows" },
    { value: "House", label: "Houses" },
    { value: "Townhouse", label: "Townhouses" },
    { value: "Land", label: "Land" },
    // { value: "terraced-house", label: "Terraced House" },
    // { value: "full-floor", label: "Full floor" },
    // { value: "hotel-apartment", label: "Hotel/Hotel Apartment" },
    // { value: "shop", label: "Shop" }
    // { value: "property", label: "Others" },
]

const radiusOptions = [
    { value: "", label: "Radius" },
    { value: "3", label: "+ 3 miles" },
    { value: "4", label: "+ 4 miles" },
    { value: "6", label: "+ 6 miles" },
    { value: "8", label: "+ 8 miles" },
    { value: "10", label: "+ 10 miles" },
    // { value: "30", label: "+ 30 miles" },
    // { value: "50", label: "+ 50 miles" },
    // { value: "100", label: "+ 100 miles" }
]
const actionType = ["Sales", "Lettings"]

const salespriceminRange = ["£80,000", 
"£100,000", 
"£200,000", 
"£225,000", 
"£250,000", 
"£275,000",
"£300,000",
"£325,000",
"£350,000",
"£375,000",
"£400,000",
"£425,000",
"£450,000",
"£475,000",
"£500,000",
"£525,000",
"£550,000",
"£575,000",
"£600,000",
"£625,000",
"£650,000",
"£675,000",
"£700,000",
"£725,000",
"£750,000",
"£775,000",
"£800,000",
"£825,000",
"£850,000",
"£875,000",
"£900,000",
"£1,000,000",
"£1,100,000",
"£1,200,000",
"£1,300,000",
"£1,400,000",
"£1,500,000",
"£1,600,000",
"£1,700,000",
"£1,800,000",
"£1,900,000",
"£2,000,000"]

const salespricemaxRange = ["£80,000",
"£100,000", 
"£200,000", 
"£225,000", 
"£250,000", 
"£275,000",
"£300,000",
"£325,000",
"£350,000",
"£375,000",
"£400,000",
"£425,000",
"£450,000",
"£475,000",
"£500,000",
"£525,000",
"£550,000",
"£575,000",
"£600,000",
"£625,000",
"£650,000",
"£675,000",
"£700,000",
"£725,000",
"£750,000",
"£775,000",
"£800,000",
"£825,000",
"£850,000",
"£875,000",
"£900,000",
"£1,000,000",
"£1,100,000",
"£1,200,000",
"£1,300,000",
"£1,400,000",
"£1,500,000",
"£1,600,000",
"£1,700,000",
"£1,800,000",
"£1,900,000",
"£2,000,000"]

const priceminRangeRentals = ["£500 pcm",
"£600 pcm",
"£750 pcm",
"£1,000 pcm",
"£1,250 pcm",
"£1,500 pcm",
"£2,000 pcm"
]

const pricemaxRangeRentals = [
"£500 pcm",
"£600 pcm",
"£750 pcm",
"£1,000 pcm",
"£1,250 pcm",
"£1,500 pcm",
"£2,000 pcm"
]

const newPriceminRange = [
  {
    value: "0",
    label: "No Min"
  },
  {
    value: "100000",
    label: "£100,000"
  },
  {
    value: "200000",
    label: "£200,000"
  },
  {
    value: "225000",
    label: "£225,000"
  },
  {
    value: "250000",
    label: "£250,000"
  },
  {
    value: "275000",
    label: "£275,000"
  },
  {
    value: "300000",
    label: "£300,000"
  },
  {
    value: "325000",
    label: "£325,000"
  },
  {
    value: "350000",
    label: "£350,000"
  },
  {
    value: "375000",
    label: "£375,000"
  },
  {
    value: "400000",
    label: "£400,000"
  },
  {
    value: "425000",
    label: "£425,000"
  },
  {
    value: "450000",
    label: "£450,000"
  },
  {
    value: "475000",
    label: "£475,000"
  },
  {
    value: "500000",
    label: "£500,000"
  },
  {
    value: "525000",
    label: "£525,000"
  },
  {
    value: "550000",
    label: "£550,000"
  },
  {
    value: "575000",
    label: "£575,000"
  },
  {
    value: "600000",
    label: "£600,000"
  },
  {
    value: "625000",
    label: "£625,000"
  },
  {
    value: "650000",
    label: "£650,000"
  },
  {
    value: "675000",
    label: "£675,000"
  },
  {
    value: "700000",
    label: "£700,000"
  },
  {
    value: "725000",
    label: "£725,000"
  },
  {
    value: "750000",
    label: "£750,000"
  },
  {
    value: "775000",
    label: "£775,000"
  },
  {
    value: "800000",
    label: "£800,000"
  },
  {
    value: "825000",
    label: "£825,000"
  },
  {
    value: "850000",
    label: "£850,000"
  },
  {
    value: "875000",
    label: "£875,000"
  },
  {
    value: "900000",
    label: "£900,000"
  },
  {
    value: "1000000",
    label: "£1,000,000"
  },
  {
    value: "1100000",
    label: "£1,100,000"
  },
  {
    value: "1200000",
    label: "£1,200,000"
  },
  {
    value: "1300000",
    label: "£1,300,000"
  },
  {
    value: "1400000",
    label: "£1,400,000"
  },
  {
    value: "1500000",
    label: "£1,500,000"
  },
  {
    value: "1600000",
    label: "£1,600,000"
  },
  {
    value: "1700000",
    label: "£1,700,000"
  },
  {
    value: "1800000",
    label: "£1,800,000"
  },
  {
    value: "1900000",
    label: "£1,900,000"
  },
  {
    value: "2000000",
    label: "£2,000,000"
  }
]

const newPricemaxRange = [
  {
    value: "0",
    label: "No Max"
  },
  {
    value: "100000",
    label: "£100,000"
  },
  {
    value: "200000",
    label: "£200,000"
  },
  {
    value: "225000",
    label: "£225,000"
  },
  {
    value: "250000",
    label: "£250,000"
  },
  {
    value: "275000",
    label: "£275,000"
  },
  {
    value: "300000",
    label: "£300,000"
  },
  {
    value: "325000",
    label: "£325,000"
  },
  {
    value: "350000",
    label: "£350,000"
  },
  {
    value: "375000",
    label: "£375,000"
  },
  {
    value: "400000",
    label: "£400,000"
  },
  {
    value: "425000",
    label: "£425,000"
  },
  {
    value: "450000",
    label: "£450,000"
  },
  {
    value: "475000",
    label: "£475,000"
  },
  {
    value: "500000",
    label: "£500,000"
  },
  {
    value: "525000",
    label: "£525,000"
  },
  {
    value: "550000",
    label: "£550,000"
  },
  {
    value: "575000",
    label: "£575,000"
  },
  {
    value: "600000",
    label: "£600,000"
  },
  {
    value: "625000",
    label: "£625,000"
  },
  {
    value: "650000",
    label: "£650,000"
  },
  {
    value: "675000",
    label: "£675,000"
  },
  {
    value: "700000",
    label: "£700,000"
  },
  {
    value: "725000",
    label: "£725,000"
  },
  {
    value: "750000",
    label: "£750,000"
  },
  {
    value: "775000",
    label: "£775,000"
  },
  {
    value: "800000",
    label: "£800,000"
  },
  {
    value: "825000",
    label: "£825,000"
  },
  {
    value: "850000",
    label: "£850,000"
  },
  {
    value: "875000",
    label: "£875,000"
  },
  {
    value: "900000",
    label: "£900,000"
  },
  {
    value: "1000000",
    label: "£1,000,000"
  },
  {
    value: "1100000",
    label: "£1,100,000"
  },
  {
    value: "1200000",
    label: "£1,200,000"
  },
  {
    value: "1300000",
    label: "£1,300,000"
  },
  {
    value: "1400000",
    label: "£1,400,000"
  },
  {
    value: "1500000",
    label: "£1,500,000"
  },
  {
    value: "1600000",
    label: "£1,600,000"
  },
  {
    value: "1700000",
    label: "£1,700,000"
  },
  {
    value: "1800000",
    label: "£1,800,000"
  },
  {
    value: "1900000",
    label: "£1,900,000"
  },
  {
    value: "2000000",
    label: "£2,000,000"
  }
]

const newPriceminRangeRentals = [
  {
    value: "0",
    label: "No Min"
  },
  {
    value: "500",
    label: "£500 pcm"
  },
  {
    value: "600",
    label: "£600 pcm"
  },
  {
    value: "750",
    label: "£750 pcm"
  },
  {
    value: "1000",
    label: "£1,000 pcm"
  },
  {
    value: "1250",
    label: "£1,250 pcm"
  },
  {
    value: "1500",
    label: "£1,500 pcm"
  },
  {
    value: "2000",
    label: "£2,000 pcm"
  }
]

const newPricemaxRangeRentals = [
  {
      value: "0",
      label: "No Max"
    },
    {
      value: "500",
      label: "£500 pcm"
    },
    {
      value: "600",
      label: "£600 pcm"
    },
    {
      value: "750",
      label: "£750 pcm"
    },
    {
      value: "1000",
      label: "£1,000 pcm"
    },
    {
      value: "1250",
      label: "£1,250 pcm"
    },
    {
      value: "1500",
      label: "£1,500 pcm"
    },
    {
      value: "2000",
      label: "£2,000 pcm"
    }
]


const createSearchUrlFromParams = params => {

  let pricePath = "",
    bedroomPath = "",
    pagePath = "",
    propertyPath = "",
    sortPath = "",
    areaPath = "",
    projectPath = "",
    developerPath = "",
    layoutPath = "",
    search_typePath = "",
    featuresPath = "",
    radiusPath = "";

  // params.areas = params?.areas || defaultarea;
  // if (params?.areas) {

  if (isArray(params?.project)) {
    if (params.project.length > 0)
      projectPath = `project-${params.project.join("-and-")}/`
  } else {
    if (params?.project)
      projectPath = `project-${params.project.replaceAll(" ", "-")}/`
  }
  projectPath = projectPath.toLowerCase().replaceAll(" ", "-")

  // developer
  if (!isEmpty(params?.developer)) {
    developerPath = `developer-${params?.developer
      .toLowerCase()
      .replaceAll(" ", "-")}/`
  }

  if (isArray(params?.areas)) {
    if (params.areas.length > 0) areaPath = `in-${params.areas.join("-and-")}/`
  } else {
    if (params?.areas) areaPath = `in-${params.areas.replaceAll(" ", "-")}/`
  }

  // if (!areaPath) areaPath = `in-${defaultarea}/`

  areaPath = areaPath.toLowerCase();

  // }
  if (params?.building) {
    propertyPath = `type-${params.building.toLowerCase()}/`
  }
  if (params?.bedroom) {
    if (params?.bedroom === 0) bedroomPath = `/studio/`
    else bedroomPath = `${params?.bedroom}-and-more-bedrooms/`
  }

  if (parseInt(params.minPrice) && parseInt(params.maxPrice)) {
    pricePath = `between-${params.minPrice}-and-${params.maxPrice}/`
  } else if (parseInt(params.maxPrice)) {
    pricePath = `under-${params.maxPrice}/`
  } else if (parseInt(params.minPrice)) {
    pricePath = `over-${params.minPrice}/`
  }

  // radius
  if (params?.radius && params?.areas && params.areaList.length) {
      let selectedArea = isAreaMatch(params.areaList, params?.areas);
      if (!isEmpty(selectedArea) && getLatLan(selectedArea)) {
        radiusPath = `radius-${params.radius}-miles/`;
      }
  }

  // features
  if (params.accomadationSummary) {
    let accomadationSummary = params.accomadationSummary
    if (!isEmpty(accomadationSummary)) {
      if (isArray(accomadationSummary)) {
        featuresPath = `with-${accomadationSummary
          .join("-and-")
          .toLowerCase()}/`
      } else {
        featuresPath = `with-${accomadationSummary.toLowerCase()}/`
      }
      featuresPath = featuresPath.replaceAll(" ", "-")
    }
  }

  layoutPath = `${params?.searchBaseUrl}`

  if (params?.searchBaseUrl) {
    layoutPath = `${params?.searchBaseUrl}`
  } else {
    layoutPath = SEARCH_BASE_URL
    if (params.search_type) {
      let searchTypeStr = "for-sale"
      if (params.search_type === "lettings") searchTypeStr = "for-rent"
      search_typePath = `-${searchTypeStr}`
    }
    if (params.layout) layoutPath += `-${params.layout}`
    layoutPath = `/${layoutPath}${search_typePath}/`
  }

  console.log("params ==>",params)

  if(params?.sortVal){
    sortPath = `sortby-${params.sortVal}/`
  }

  let myUrl = `${areaPath}${propertyPath}${projectPath}${developerPath}${bedroomPath}${pricePath}${featuresPath}${radiusPath}${sortPath}${pagePath}`

  return `${layoutPath}${myUrl}`
}

const meterToMiles = (val) => {
    return Math.round(parseInt(val) / METER_VAL)
}

const milesToMeter = (val) => {
    return Math.round(parseInt(val) * METER_VAL)
}

const getLatLan = (item) => {
    if (item?.latitude && item?.longitude) {
        return `${item.latitude},${item.longitude}`
    }
    return '';
}

const isAreaMatch = (areaList, area) => {
    return find(areaList, ['name', toTitleCase(area)])
}

const getMatterportUrl = (url) => {
    if (url.match(/\?/))
      return `${url}&play=1`
    return `${url}?play=1`
}

const getPidFromUrl = (url = '') => {
    if (!url) {
        url = typeof window !== 'undefined' ? window.location.href : ''
    }

    let urlObj = url.split('-');
    return urlObj[urlObj.length-1].replace('/', '').replace("/#","").split("?")[0];
}

export {
    bedOptions,
    buildingOptions,
    salespriceminRange,
    salespricemaxRange,
    priceminRangeRentals,
    pricemaxRangeRentals,
    radiusOptions,
    createSearchUrlFromParams,
    meterToMiles,
    milesToMeter,
    getLatLan,
    isAreaMatch,
    newBuildingOptions,
    newPriceminRange,
    newPricemaxRange,
    newPriceminRangeRentals,
    newPricemaxRangeRentals,
    getMatterportUrl,
    getPidFromUrl,
    actionType
}
