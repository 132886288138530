import React, { } from "react"
import { get } from 'lodash';
import { Link } from "@StarberryUtils"
import { sitename } from "@constants";
import "./Banner.scss"
import Slider from "react-slick";
import parse from 'html-react-parser';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import GenerateLink from "../../common/site/generate-link"
import bannerlogo from "../../../images/banner-logo.png"
import {
  isIOS,
  isMobile
} from "react-device-detect";
// Header component
  let slider = {};

  const Banner = (props) => {
    const ctas = get(props, 'Ctas', []);
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3500,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            arrows: true,

          },
        }
      ]
    };

    const next = () => {
      slider.slickNext();
    };
    const previous = () => {
      slider.slickPrev();

    }

    const handleClick = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: (document.getElementById('section-choice').offsetTop - document.getElementById('pageheader').offsetHeight),
            left: 0,
            behavior: 'smooth'
        })
    }


  const data = useStaticQuery(graphql`
  query {
    glstrapi {

      siteConfig {
        Facebook_Link
        Instagram_Link
        Twitter_Link
        Youtube_Link
      }

    }
  }
`);

const social_links = data.glstrapi.siteConfig;

  let pagename = props?.Pagename;
  if (pagename === 'Home')
    pagename = 'property service';
  return (
    <section className="home-banner home-banner-links">
      
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Organization",
            "@id": "https://www.redbrik.co.uk/#Organization",
            "name": "Redbrik Estate Agents",
            "url": "https://www.redbrik.co.uk/",
            "logo": "https://www.redbrik.co.uk/images/logo.png",
            "description": "Redbrik is an award-winning estate agent in Sheffield. We offer estate agency and lettings services across Sheffield & Chesterfield. Find out how our property experts are achieving best results for the buyers, sellers, landlords & tenants",
            "foundingDate": "2013",
            "sameAs": [
            "https://www.facebook.com/redbrikestateagents",
            "https://www.instagram.com/redbrik/",
            "https://www.linkedin.com/company/redbrik-estate-agents-llp",
            "https://twitter.com/REDBRIKproperty"
            ]
          }`}
        </script>
      </Helmet>

      {/*<div className="home-banner-links">
        <Container>
          <ul className="social-links d-none d-xl-block" >
            {social_links.Facebook_Link &&
            <li>
              <a href={social_links.Facebook_Link} target="_blank">
                Facebook
              </a>
            </li>
            }
            {social_links.Twitter_Link &&
            <li>
              <a href={social_links.Twitter_Link} target="_blank">
                Twitter
              </a>
            </li>
            }
            {social_links.Instagram_Link &&
            <li>
              <a href={social_links.Instagram_Link} target="_blank">
                Instagram
              </a>
            </li>
            }
            {social_links.Youtube_Link &&
            <li>
              <a href={social_links.Youtube_Link} target="_blank">
                YouTube
              </a>
            </li>
            }
          </ul>
        </Container>
      </div>
      <div className="banner-content banner-content-alt">

        <div className="banner-text d-md-none">
          {props.Slider_Info_Title &&
            <span className="sm-text">
            {props.Slider_Info_Title}
            </span>
          }

          {parse(props.Slider_Content)}

          <div className="sm-details">
            {props.Slider_CTA_1_Label &&
              <GenerateLink class="sm-text" link={props.Slider_CTA_1_Link}>
                {props.Slider_CTA_1_Label}
              </GenerateLink>
            }
            <span className="sm-info">&nbsp;</span>
            {props.Slider_CTA_2_Label &&
                <GenerateLink class="sm-info cta-2" link={props.Slider_CTA_2_Link}>
                  {props.Slider_CTA_2_Label}
                </GenerateLink>
            }
          </div>
        </div>
        <div className="sm-logo d-none d-lg-block">
          <img src={bannerlogo} alt="banner" />
        </div>

      </div>*/}
      <div className="image_width desk_bimg">
      <Slider ref={(c) => (slider = c)} {...settings} slideIndex>
        {props.Slider_Image && props.Slider_Image.map((image, i) => {
          let gatsbyImg = getImage(image.url_sharp)
          return (
            <div className="banner-img" key={i}>
                {/* When using ggfx clitn component, banner image getting time to load, facing some jump issue. Using gatsby image to over come this */}
                <GatsbyImage image={gatsbyImg} alt={image.alternativeText || `${pagename} Banner - ${sitename} Estate Agents`} />
            </div>
          )
        })}
      </Slider>
      </div>
      
      <div className="image_width mob_bimg">
      <Slider ref={(c) => (slider = c)} {...settings} slideIndex>
        {props.Mobile_Banner_Image && props.Mobile_Banner_Image.map((image, i) => {
          let gatsbyImg = getImage(image.url_sharp)
          return (
            <div className="banner-img" key={i}>
                {/* When using ggfx clitn component, banner image getting time to load, facing some jump issue. Using gatsby image to over come this */}
                <GatsbyImage image={gatsbyImg} alt={image.alternativeText || `${pagename} Banner - ${sitename} Estate Agents`} />
            </div>
          )
        })}
      </Slider>
      </div>
      <div className="banner-content-alt">
        <div className="d-lg-flex justify-content-center">
          <div>
            {props.Slider_Info_Title &&
              <h1>{props.Slider_Info_Title}</h1>
            }
            {(ctas.length) && (
                <div className="btn-list">
                    {ctas.map((cta, i) => {
                        return(
                            <Link to={cta.Submenu_Link.URL} className="btn btn-trans">{cta.Submenu_Label}</Link>
                        )
                    })}
                </div>
            )}
          </div>
        </div>
      </div>
      {/*
      <div className="container slick-arrow-wrap">
        <button className="button" className="slick-prev" onClick={previous}>

        </button>
        <button className="button" className="slick-next"  onClick={next}>

        </button>
      </div>*/}
      <a className="view-more" onClick={(e) => handleClick(e)}>
        <i className="icon-arrow"></i>
      </a>
    </section>
  )
}
export default Banner
