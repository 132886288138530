import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import Slider from "react-slick";
import { useQuery } from '@apollo/client';
import Select from "react-select"
import gql from "graphql-tag"
import "./News.scss";
import logoBlack from "../../../images/redbrik-new-logo.svg";
import _, { create } from "lodash"
import parse from 'html-react-parser';
import { isTablet, isMobile, isMobileOnly, isDesktop } from 'react-device-detect';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import usePagination from "../../../hooks/usePagination"
import InnerPagination from "../../InnerPagination/InnerPagination"
import { useLocation } from "@reach/router"


import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const GET_ALL_NEWS = gql`
query GETNEWS{
    newsBlogs(sort: "Date:desc",where:{Publish:true}) {
        id
        Title
        Date
        URL
        Tile_Image {
          url
          alternativeText
        }
        Short_Description
        Categories {
          Name
          URL
        }
        imagetransforms
    }

  newsCategories(sort: "Sort:asc",where:{Publish:true}) {
    Name
    Sort
    URL
  }
}
`;

function Details(props) {

    const { loading, error, data } = useQuery(GET_ALL_NEWS);

    const [main_news, setMainNews] = useState([]);
    const [all_news, setAllNews] = useState([]);
    const [tags, setTags] = useState('All News');
    const [screenSize, setScreenSize] = useState(0);
    const {state} = useLocation()    
    const [cate, setCat] = useState('');    
    const customStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isFocused ? "#B20838" : null,
            color: isFocused ? "#ffffff" : "#575757",
          }
        }, 
        dropdownIndicator: styles => ({
          ...styles,
          //  color: "#ffffff",
        }),
        indicatorsContainer: styles => ({
          ...styles,
          // color: '#ffffff',
          padding: 0,
          cursor: "pointer",
        }),
        indicatorSeparator: () => null
      }

    

     

    const getCatlist = (list) =>{
        let catList = [{label: "All News", value:"all"}]
    
        if(list.length > 0){
            list.map((node, key) => {
                catList.push({label: node.Name, value:node.URL})
            })
        }
        return catList
    }


    // for pagination
    const itemsPerPage = 16
    const { currentItems, currentPage, setCurrentPage } = usePagination({
        items: all_news,
        itemsPerPage,
    })
    // for pagination
     

    useEffect(() => {
        if (data && _.isEmpty(all_news)) {
            let filteredTeamList = data.newsBlogs;
            if(state?.slug){
                filteredTeamList = filteredTeamList = data.newsBlogs.filter(news => {
                    let main_category = news.Categories.filter(cat => _.includes(cat, state?.slug));
                    return main_category.length > 0
                });
            }
          
            setAllNews(filteredTeamList)
            setCat(state?.slug ? state.slug: "all")
        }
        deviceWidth()
        window.addEventListener("resize", () => {
            deviceWidth()
        });
    }, [data]);

    const deviceWidth = () => {
        if (window) {
            setScreenSize(window.innerWidth)
        }
      } 
     

    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" class="new-loader">
                    <img className="logo-white loader-logo" src={logoBlack} alt="logo" />
                </div>
                <div class="loader-section section-left"></div>
                <div class="loader-section section-right"></div>
            </div>
        </section>
    )

    function NewsFunctions(url) {
        var filteredTeamList = []
        filteredTeamList = data.newsBlogs

        if (url != "all") {
            setCat(url)
            filteredTeamList = data.newsBlogs.filter(news => {
                let main_category = news.Categories.filter(cat => _.includes(cat, url));
                return main_category.length > 0
            });
        }
        else {
            setCat("all")
        }

        // setAllNews(filteredTeamList)
        setMainNews(filteredTeamList.slice(0, 1))
        // const chunk = _.chunk(data.newsBlogs.slice(1), 6)
        setAllNews(filteredTeamList)

    }


    const chunks = _.chunk(data.newsBlogs.slice(1), 6);
    // console.log("xxx",chunks)
    // console.log("xxx1",data.newsBlogs)

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                <section className="news-section">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                {screenSize > 768 ?
                                    <ul className="nav-tabs">
                                        <li className={`nav-link ${cate == "all" ? 'active' : ''}`} onClick={() => { NewsFunctions("all"); }}>
                                            All News
                                        </li>
                                        {data.newsCategories.map((cat, key) => {
                                            return (
                                                <li className={`nav-link ${cate == cat.URL ? 'active' : ''}`} onClick={() => { NewsFunctions(cat.URL); setTags(cat.Name) }}>
                                                    {cat.Name}
                                                </li>
                                            )
                                        })}
                                        <li>
                                        </li>
                                    </ul>
                                    :
                                    <div className="news-section-select">
                                        <Select
                                            name="people"
                                            options={getCatlist(data.newsCategories)}
                                            isSearchable={false}
                                            classNamePrefix={"select-opt  news-category"}
                                            defaultValue={cate === "all" ? "All News" : cate}
                                            value={getCatlist(data.newsCategories).find(
                                                obj => obj.value === cate
                                            )}
                                            placeholder="All People"
                                            onChange={e => {
                                                NewsFunctions(e.value)
                                            }}
                                            styles={customStyles}

                                        />
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <Col sm={12}>

                                {currentItems.length != 0 &&
                                    <div className="gallery-wrapper row">

                                        {currentItems.map((news, key) => {

                                            var months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                                            var css_class = ["one", "two", "three", "four", "five", "six"];

                                            var selectedMonthName = months[parseInt(news.Date?.slice(5, 7))];

                                            var selectclass = css_class[key];


                                            const image_url = news.Tile_Image?.url

                                            let processedImages = JSON.stringify({});
                                            if (news?.imagetransforms?.Tile_Image_Transforms) {
                                                processedImages = news.imagetransforms.Tile_Image_Transforms;
                                            }

                                            var cat_list = ''

                                            var cats = news.Categories

                                            for (var i = 0; i < cats?.length; i++) {
                                                var full_name = cats[i].Name
                                                cat_list += (cats[i].Name) + `${cats.length == 1 || i + 1 == cats.length ? '' : ', '}`
                                            }
                                            if(cat_list.length == 0){
                                                cat_list = 'Redbrik'
                                            }

                                            return (
                                                <div className={`gallery-info col-lg-6 col-xl-3 ${selectclass}`}>
                                                    <Link className="gallery-link" to={news.URL}>
                                                        <div className="gallery-img img-zoom">

                                                            <ImageTransform imagesources={image_url} key={image_url} renderer="srcSet" imagename="news-blogs.Tile_Image.tile_1" attr={{ alt: news.Title + ' - Redbrik', class: '' }} imagetransformresult={processedImages} id={news.id} />

                                                            <div className="gallery-text">
                                                                <span className="date">
                                                                    {news.Date?.slice(8, 10)}
                                                                </span>
                                                                <span className="month">
                                                                    {selectedMonthName}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <div className="gallery-content">
                                                        <h3>
                                                            <Link to={news.URL}>
                                                                {/* {news.Title.length} */}
                                                                {news.Title}
                                                            </Link>
                                                        </h3>
                                                        {/* {cat_list && (<span className="sm-info">#{cat_list}</span>)} */}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                }


                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>

            <Container>
                <Row>
                    <Col sm={12}>
                        <InnerPagination
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            totalItems={all_news.length}
                            setCurrentPage={setCurrentPage}
                        />
                    </Col>
                </Row>
            </Container>

        </React.Fragment>
    );
};

export default Details;