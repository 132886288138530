import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import parse from 'html-react-parser';
import gql from "graphql-tag"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import Slider from "react-slick";
import "./SimilarProperties.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import Coming from "../../../images/coming-soon.png"


const SIMILAR_DEV = gql`
query SIMDEV {
    newDevelopments(limit:6){
        id
        title
        display_address
        price
        slug
        images {
          url
          alternativeText
        }
        imagetransforms
    }
}
`;


function NewHomesProperties(props) {

  //console.log("palani")
  //console.log(props)

  const { loading, error, data } = useQuery(SIMILAR_DEV);

    console.log("palani")
    console.log(data , "new homes")

      const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    if (loading) return (
        <p>loading</p>
    )

      return (
            <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                <section className="similar-properties other-developments">
                    <Container>
                        <Row>
                            <Col sm={12}>
                              <div className="heading">
                              {props.Title ? parse(`<h2>${props.Bold_Title}<span>&nbsp;${props.Title}</span></h2>`) : parse("<h2>Properties for <span>rent</span></h2>")}                 
                              </div>

                                <div className="slick-wrap">
                                <Slider {...settings}>
                                { data.newDevelopments.map((item, index) => {

                                    let uriStr = "new-homes/current-developments-details/";

                                    let processedImages = JSON.stringify({});
                                    if (item?.imagetransforms?.Images_Transforms) {
                                        processedImages = item.imagetransforms.Images_Transforms;
                                    }
                                    let imageUrl = item?.images[0]?.url || Coming;

                                    console.log("item => ", item)

                                    return (
                                    <div className="properties img-zoom">
                                        <Link to={'/'+uriStr+item.slug+'-'+item.id}>
                                        {item?.images[0]?.url ? (
                                            <ImageTransform imagesources={imageUrl} renderer="srcSet" imagename="new-developments.images.similar_properties" attr={{ alt: item.display_address+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={item.id}/>
                                        ) : (
                                            <img src={Coming} alt="" className="soon" />
                                        )}

                                        </Link>

                                        <div className="card-details">
                                            <h3>
                                                <Link to={'/'+uriStr+'-'+item.id}>
                                                {item.display_address}
                                                </Link>

                                            </h3>
                                            <h4>                                               
                                                {item?.price && (
                                                    <><span className="price-qualifier">Price from </span> <span className="sm-info"> &pound;{item.price.toLocaleString()}</span></>
                                                )}
                                            </h4>
                                            <div className="card-info">
                                            {
                                                item.title &&
                                                <span className="details">
                                                <Link href="javascript:void(0)" className="other-link">
                                                    <i className="icon-bedroom"></i>
                                                </Link>
                                                {item.title}
                                                </span>
                                            }

                                            </div>
                                        </div>
                                    </div>
                                    )
                                })  }
                                </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
        </React.Fragment>
      )
}

export default NewHomesProperties;
