import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser';

import "./Offer.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import GenerateLink from "../../common/site/generate-link"

// Header component

const OfferBlack = (props) => {
    return (
        <React.Fragment>
            <section className="our-offer black">
                    {props.Plans_Modules_Info_Title &&
                    <Container className="head">
                        <Row>
                            <Col sm={12} lg={12}>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                                <div className="content">
                                    <div className="info-title">
                                        {props.Plans_Modules_Info_Title}
                                    </div>
                                    <div className="intro">
                                        {parse(props.Plans_Module_Heading_Content)}
                                    </div>
                                </div>
                            </ScrollAnimation>
                            </Col>
                        </Row>
                    </Container>
                    }
                    <Container>
                        <Row>
                        {props.Add_Plan && props.Add_Plan.map((plan, i) => {
                            let anim_time = 100 * i
                            return (
                                <Col sm={12} lg={4}>
                                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={anim_time}>
                                    <div className={`box ${plan.Recommended === true ? 'premium' : ''} ${props.Add_Plan.length === i+1 ? 'last' : ''}`}>
                                        {plan.Recommended === true ?
                                        <div className="heading">
                                            <h3>{plan.Plan_Title}</h3>
                                            <span className="sm-heading">
                                            Recommended
                                            </span>
                                        </div>
                                     : <h3>{plan.Plan_Title}</h3>}

                                        <p>{plan.Plan_Intro}</p>
                                        {parse(plan.Plan_Features)}

                                        {plan.Plan_CTA_Link &&
                                        <GenerateLink className="btn" link={plan.Plan_CTA_Link}>
                                            {plan.Plan_CTA_Label}
                                        </GenerateLink>
                                        }

                                        {/* <Link to="#" className="features">
                                            View all features
                                        </Link> */}
                                    </div>
                                    </ScrollAnimation>
                                </Col>
                            )
                        })}
                        </Row>
                    </Container>
            </section>
        </React.Fragment>
    );
};

export default OfferBlack;
