import React from "react"
import { Link } from "@StarberryUtils"
import { sitename } from "@constants";
import "./Services.scss"
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { MEET_THE_TEAM_PAGE_URL } from "../../common/site/constants";
import { isMobileOnly, isMobile, isTablet } from "react-device-detect"

import MailForm from "../../Team/team-landing-form"

const TeamSlider = (props) => {
   
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 2,
        infinite: false,
        arrows: false,
        // mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    dots: true
                },
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                },
            },
            
        ],
    };
    return (
        <React.Fragment>
            <section className={`services team-slider no-padding-top`}>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="service">
                                    {props.Bold_Title || props.Title ?
                                        <h2><i>{props.Title}</i>{props.Bold_Title}</h2>
                                        :
                                        <h2>{props.Bold_Title}</h2>
                                    }
                                  
                                    <Link to={MEET_THE_TEAM_PAGE_URL.alias} class="learn-more">View more</Link>

                                </div>

                                <div className="team-slider-wrap">
                                    <Slider {...settings} className="service-wrapper service-tile-slide">
                                    {props.team && props.team.map((tile, i) => { 
                                        const image_url = tile.Image.url

                                        let processedImages = JSON.stringify({});
                                        if (tile?.imagetransforms?.Image_Transforms) {
                                            processedImages = tile.imagetransforms.Image_Transforms;
                                        }
                                        let url = `${MEET_THE_TEAM_PAGE_URL.alias}/${tile.URL}`

                                        return (
                                            <div className="service-img img-zoom">
                                                <Link to={url} className="img-list">
                                                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="peoples.Image.tile_1" attr={{ alt: `${tile.Name} - ${sitename}`, class:'' }} imagetransformresult={processedImages} id={tile.id}/>
                                                </Link>
                                                <div className="person-info">
                                                    <h3><Link to={url}>{tile.Name}</Link></h3>
                                                    <p className="person-positions">{tile.Designation}</p>
                                                    <div className="member-link">
                                                        {/*<MailForm tag="teamlist" name={tile.Name} email={tile.Email} label={"Email"} class="mail" />*/}
                                                        <a href={`mailto:${tile.Email}`} className="mail email">Email</a>
                                                        <span>/</span>
                                                        <Link to={url} className="bio-link">Bio</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment>
    )
}

export default TeamSlider
