import React, { useState, useEffect, useRef } from "react";
import { get } from 'lodash';
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import parse from 'html-react-parser';
import { InView } from 'react-intersection-observer';
import videoUrl from "../../../images/selling/iPhone-fashion-chatbot2.mp4";
// import videoUrl2 from "../../../images/selling/2.mp4";
import videoUrl2 from "../../../images/selling/iPhone-fashion-chatbot3.mp4";
import Img001 from "../../../images/selling/img04.png"
// import Img002 from "../../../images/selling/img04.png"
// import Img003 from "../../../images/selling/img04.png"
// import "./VideoPromotion.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component
const VideoPromotion = (props) => {
    const image  = get(props, 'Add_Image_here.url', Img001)
    const [inView, setInView] = useState(false)
    const videoRefOne = useRef(null);
    const videoRefTwo = useRef(null);

    useEffect(() => {
      if (inView) {
        videoRefOne.current.play();
        videoRefTwo.current.play();
      }
    }, [inView]);

    return (
        <React.Fragment>
            <InView onChange={setInView}>
                {({ inView, ref, entry }) => {

                    return (
                        <section ref={ref} className={`about-part ku social-promotion ${inView ? 'visible' : 'invisible'} ${props?.Bg_Color} ${props?.Animation_Type} ${props?.Content_Position} ${props?.Module_Width}`}>
                            <div class="container-parent">
                                <Container>
                                    <Row>
                                        <Col sm={12}>
                                            {(props?.Content_Position === 'Top') && (
                                                <div className="promotion-info about-info">
                                                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                                                        {parse(props.Content)}
                                                    </ScrollAnimation>
                                                </div>
                                            )}
                                           
                                            <div className="promotion-img  about-img">
                                                <div className="videoblock_box">
                                                    <picture class="phone-frame">
                                                        <source media="(min-width:992px)" srcSet={image} />
                                                        <source media="(min-width:768px)" srcSet={image} />
                                                        <img src={image} alt={props?.Add_Image_here?.alternativeText} />
                                                    </picture>
                                                    <video className='videoblock_wrap' loop muted ref={videoRefOne} playsInline>
                                                        <source src={videoUrl} type='video/mp4' />
                                                    </video>
                                                </div>
                                                <div className="videoblock_box videoblock_box_second">
                                                    <picture class="phone-frame">
                                                        <source media="(min-width:992px)" srcSet={image} />
                                                        <source media="(min-width:768px)" srcSet={image} />
                                                        <img src={image} alt={props?.Add_Image_here?.alternativeText} />
                                                    </picture>
                                                    <video className='videoblock_wrap' loop muted ref={videoRefTwo} playsInline>
                                                        <source src={videoUrl2} type='video/mp4' />
                                                    </video>
                                                </div>
                                            </div>
                                            {(props?.Content_Position !== 'Top') && (
                                                <div className="promotion-info about-info">
                                                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                                                        {parse(props.Content)}
                                                    </ScrollAnimation>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                    )
                }}
            </InView>
        </React.Fragment>
    );
};
export default VideoPromotion;
