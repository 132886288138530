import React, { } from "react"
import { get } from 'lodash';
import { Link } from "@StarberryUtils"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import GenerateLink from "../../common/site/generate-link"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

import branchimg01 from "../../../images/branch01.jpg"
import branchimg02 from "../../../images/branch02.jpg"
import branchimg04 from "../../../images/branch04.jpg"
import "./Branches.scss"
import { Container, Row } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import { useQuery } from '@apollo/client';
// Header component
const Branches = (props) => {

    const data = useStaticQuery(graphql`
    query {
      glstrapi {
          newsBlogs(limit: 1, sort: "Date:desc",where:{Publish:true}) {
            id
            Short_Description
            Title
            URL
         }
      }
    }
  `);

  function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const newsData = get(data, 'glstrapi.newsBlogs[0]', {})

    return (
        <React.Fragment>
            <section className="branches">
                <Row>
                    <div className="branch-img img-zoom col-lg-7">
                        <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}>
                        <ImageTransform
                            imagesources={props.Single_Block_Image.url}
                            renderer="srcSet"
                            imagename="articles.Single_Block_Image.detail"
                            attr={{ alt: props.Single_Block_Image.alternativeText, className: 'img-zoom' }}
                            imagetransformresult={props?.processedImages || {}} id={props?.pageId} testparam={true}
                        />
                            {/*<picture>
                                <source media="(min-width:1400px)" srcSet={props.Single_Block_Image.url} />
                                <source media="(min-width:992px)" srcSet={props.Single_Block_Image.url} />
                                <source media="(min-width:768px)" srcSet={props.Single_Block_Image.url} />
                                <img src={props.Single_Block_Image.url} alt={props.Single_Block_Image.alternativeText} />
                            </picture>*/}
                        </ScrollAnimation>
                    </div>
                    <div className="branch-info col-lg-5 ">
                        <ScrollAnimation animateIn='fadeInRight' animateOnce={true}>
                            {props.Single_Block_Info_Title &&
                                <span className="sm-text">
                                    {props.Single_Block_Info_Title}
                                </span>
                            }
                            {newsData.Title && (<h2>{newsData.Title}</h2>)}

                            {newsData.Short_Description && (<p>{(parse(newsData.Short_Description))}</p>)}
                            {!newsData.Short_Description && parse(props.Single_Block_Content)}

                            {props.Single_Block_CTA_Link &&
                            <GenerateLink class="learn-more" link={props.Single_Block_CTA_Link}>
                                {props.Single_Block_CTA_Label}
                            </GenerateLink>
                            }

                        </ScrollAnimation>
                    </div>
                </Row>
            </section>
        </React.Fragment>
    )
}

export default Branches
