import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get } from 'lodash';
import "./OurPackages.scss";
import parse from 'html-react-parser';
import $ from "jquery";

const OurPackages = (props) => {
    const contents = get(props, 'content_block', []);

    const toggleActivex = (e) => {
        console.log("here")
       // e.preventDefault();
       // console.log(activeId)
        //setActiveId(id)
       // $('html, body').animate({scrollTop: $(".our-packages").offset().top - 140}, 1000);
       // setActiveId(null)
    }

    return (
        <React.Fragment>
            <section className={`our-packages ${(props?.BG_Color)} ${(props?.Image_Align)} ${props?.Custom_Class}`}>
                <Container>
                <Row>
                    <Col sm={12}> 
                        { contents.map((cnt, i) => {    
                            return (         
                            <div className={"content-list"}>
                            <div class="content"> 
                                <h3>{cnt?.Title?parse(`<i>${cnt?.Title}</i>${cnt?.Heading}`):cnt?.Heading}</h3>
                                <>
                                    {parse(cnt.content)}
                                    {cnt.Button_Name &&
                                      <a onClick={(e) => toggleActivex(e)} className="link" href={cnt.Button_Link?cnt.Button_Link:"javascript:void(0)"}>{cnt.Button_Name}</a>
                                    }
                                </>               
                            </div>
                            </div>
                            )
                        })}
                    </Col>
                </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default OurPackages;
