import React from "react";
import './InnerPagination.scss';
import { isTablet, isTabletOnly, isMobile, isMobileOnly, isDesktop } from 'react-device-detect';
import Select from "react-select"

const InnerPagination = ({
    itemsPerPage,
    totalItems,
    currentPage,
    setCurrentPage,
    listingName
  }) => {
  
    // const {isMobile,isTabletOnly}=useDeviceMedia()
    const pageNumbers = []
    const pageNumberOptions = []
  
    // Generate page numbers
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i)
      pageNumberOptions.push({ value: i, label: i })
    }
  
    const totalPageNumbers = pageNumbers?.length
  
    const handlePageChange = value => {
      // handle change for dropdown select
      if (typeof value === "object") setCurrentPage(value.value)
      // handle change for number based page change
      else if (typeof value === "number") setCurrentPage(value)
      else
        switch (value) {
          case "back":
            setCurrentPage(page => --page)
            break
          case "next":
            setCurrentPage(page => ++page)
            break
          default:
            break
        }

        if(listingName==="Review"){
          typeof window!=="undefined"&&window.scrollTo({
            top: isMobile?1040:isTabletOnly?1180:1355,
            behavior: 'smooth',
          })
        }
        else if(listingName==="news"){
          const htmlBody = document.documentElement || document.body;
          htmlBody.scrollTop = 0;
        }
        else{
          const htmlBody1 = document.documentElement || document.body;
          htmlBody1.scrollTop = 220;
        }
    }
  
    if(totalPageNumbers < 2) return null
  
    return (
        <div className="news-pagination-wrapper">
            <div className="menu-list">
                <div className="algolia-pagination">
                    <section className="more-results pb-4 pt-0">
                        <div className="more-btn">
                            <button
                                className={`more ${(currentPage <= 1) ? "disable" : "active"} `}
                                id="mynextbut"
                                onClick={() => handlePageChange("back")}
                                disabled={currentPage <= 1}
                            >
                                <i className="icon-chevron-left"></i>  Prev
                            </button>
                        </div>
                    </section>
                    <div className="al-pagination">
                        Page 
                        <Select
                            options={pageNumberOptions}
                            placeholder={(currentPage + 1)}
                            classNamePrefix={"select-opt"}
                            isSearchable={false}
                            onChange={handlePageChange}
                            styles={customStyles}  
                            value={{ value: currentPage, label: currentPage }}
                        />
                        of {totalPageNumbers}
                    </div>
                    <section   className={`more-results pb-4 pt-0`} >
                        <button 
                            className={`more ${(currentPage >= totalPageNumbers) ? "disable" : "active"} `}
                            id="mynextbut"
                            onClick={() => handlePageChange("next")}
                            disabled={currentPage >= totalPageNumbers}
                        >
                            Next <i className="icon-chevron-right"></i>
                        </button>
                    </section>
                </div>
            </div>
        </div>
    )
}

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#B20838" : null,
      color: isFocused ? "#ffffff" : "#575757",
    }
  },
  control: styles => ({
    ...styles,
    backgroundColor: null, 
    width:"80px",
    borderRadius: "4px",
    paddingLeft: "15px",
  //   height: "40px",
  //   boxShadow: "none",
    color: "#4c505b",
    fontSize: "14px",
    fontWeight: "300",
  }),
  valueContainer: styles => ({
    ...styles,
    fontSize: ".875rem",
    paddingLeft: 0,
    paddingRight: 0,
    lineHeight: "30px",
    fontWeight: "300",
    cursor: "pointer",
  }),
  dropdownIndicator: styles => ({
    ...styles,
    //  color: "#ffffff",
  }),
  indicatorsContainer: styles => ({
    ...styles,
    // color: '#ffffff',
    padding: 0,
    cursor: "pointer",
  }),
  indicatorSeparator: () => null,
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: "#4c505b",
      marginLeft: 0,
    }
  },
}

export default InnerPagination