import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import parse from "html-react-parser"
import PlayVideo from "../../Play/custom-video";
import "./SellingProperty.scss";
// Header component

const SellingProperty = (props) => {
  const [isPlay, setPlay] = useState(false);
  const [isPlaying, setPlaying] = useState(false);

  return (
    <React.Fragment>

        <section className={`selling-property count-${props?.count}`}>
        <div className="property-wrapper">

          {props.Add_Images_here.map((image, i) => {
            return (
              <>
              <div className="property-img img-zoom">
                <picture>
                  <source media="(min-width:1400px)" srcSet={image.Image_1.url} />
                  <source media="(min-width:768px)" srcSet={image.Image_1.url} />
                  <img src={image.Image_1.url} alt={image?.Image_1?.alternativeText} />
                </picture>
              </div>
              <div className="property-img img-zoom">
                {/* <Link to="/sell/"> */}
                    <picture>
                    <source media="(min-width:1400px)" srcSet={image.Image_2.url} />
                    <source media="(min-width:768px)" srcSet={image.Image_2.url} />
                    <img src={image.Image_2.url} alt={image?.Image_2?.alternativeText} />
                    </picture>
                {/* </Link> */}
                {image.Image_2_Video_URL &&
                  <div className="video-buttons">
                    <PlayVideo url={image.Image_2_Video_URL} />
                  </div>
                }
              </div>
              </>
            )
          })}

          </div>
          {props.Add_Rightside_Content &&
          <div className="property-info">
            {parse(props.Add_Rightside_Content)}
          </div>
          }
        </section>
    </React.Fragment>
  );
};

export default SellingProperty;
