import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get } from 'lodash';
import { Link } from "@StarberryUtils"
import parse from 'html-react-parser';
import "./DoubleImageBlock.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import ModalTiny from "react-bootstrap/Modal"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
// Header component

const DoubleImageBlock = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState('');
    const openModal = () => {
      setShowModal(true);
    }
    const hideModal = () => {
      setShowModal(false);
    }
  
    const handleonClick = (e, cta) => {
      e.preventDefault();
      setShowModal(true);
      setModalData(cta)
    }
  
    const ctas = get(props, 'Buttons', []);
    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.Video_Background_Image_Transforms) {
        processedImages = props.imagetransforms.Video_Background_Image_Transforms;
    }    
    return (
        <React.Fragment>
            <section className={`doubleImageBlock`}> 
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                      <Container>
                        <div className="gallery-list">
                            {props.Image_block1.url &&
                                <div className="gallery-img">
                                    <div className={"img-column"}>
                                        <ImageTransform
                                            imagesources={props.Image_block1.url}
                                            renderer="srcSet"
                                            imagename="articles.Double_Image_Block.detail"
                                            attr={{ alt: props?.Image?.alternativeText, className: 'img-zoom' }}
                                            imagetransformresult={processedImages}
                                            id={props?.pageId}
                                            testparam={true}
                                        />
                                    </div>
                                    <div className="content-block">
                                        {parse(props.content1)}                                        
                                        <div className="btn-list">
                                            {props.cta1name==="Book a site appraisal"?
                                            <a href="javascript:void(0)" onClick={(e) => handleonClick(e, props?.customUrl1)} className="btn transparent transparent_white">{props.cta1name}</a>:
                                            <a href={props?.customUrl1?props?.customUrl1:props.cta2link.URL} className={`btn transparent transparent_white`}>{props.cta1name}</a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }                           
                            {props.Image_block2.url &&
                                <div className="gallery-img">
                                    <div className={`img-column`}>
                                            <ImageTransform
                                                imagesources={props.Image_block2.url}
                                                renderer="srcSet"
                                                imagename="articles.Double_Image_Block.detail"
                                                attr={{ alt: props?.Image?.alternativeText, className: 'img-zoom' }}
                                                imagetransformresult={processedImages}
                                                id={props?.pageId}
                                                testparam={true}
                                            />
                                    </div>
                                    <div className="content-block">
                                        {parse(props.content2)}                                   
                                        <div className="btn-list">
                                        {props.cta2name==="Book a site appraisal"?
                                            <a href="javascript:void(0)"  onClick={(e) => handleonClick(e, props?.customUrl2)} className="btn transparent transparent_white">{props.cta2name}</a>:
                                            <a href={props?.customUrl2?props?.customUrl2:props.cta2link.URL} className={`btn transparent transparent_white`}>{props.cta2name}</a>
                                        }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        </Container>                  
                </ScrollAnimation>
                    <ModalTiny show={showModal} onHide={hideModal} className="book-a-viewing-modal iframe-modal">
                    <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">{`Book a site appraisal`}</h2></ModalTiny.Title>
                    </ModalTiny.Header>
                    <ModalTiny.Body className="event-section">
                    <iframe src={modalData?modalData:''} width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    </ModalTiny.Body>
                    </ModalTiny>
            </section>
        </React.Fragment>
    );
};

export default DoubleImageBlock;
