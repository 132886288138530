import React, { useRef, useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"
import Select from "react-select"

import InputField from './elements/input'
import SelectField from './elements/select'
import MultiSelectField from './elements/multi-select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from 'jquery/dist/jquery.min.js'
import { bedOptions, salespriceminRange, salespricemaxRange, priceminRangeRentals, pricemaxRangeRentals, actionType} from "./constant"

// import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string"

function RegisterForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [selectedInterst, setSelectIntrest] = useState("");
  const [isValid, setIsValid] = useState(true);
  // const [serverResponse, setServerResponse] = React.useState(``)

  const [formvalues, setFormvalues] = useState("");

  const [typeVal, setTypeVal] = useState("")
  const [minpriceVal, setMinPriceVal] = useState("")
  const [maxpriceVal, setMaxPriceVal] = useState("")

  const [minPriceList, setMinPriceList] = useState([])
  const [maxPriceList, setMaxPriceList] = useState([])

  const [token, setToken] = useState("");
  const [file, setFile] = useState(""); // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();
  const fields = ([
    {
      element: "config",
      formname: "Register",
      error_text: "Highlighted fields are required | invalid",
      success_text: "Thank you for your interest. A member of our team will contact you shortly.",
      email_temp_user: "register_user",
      email_temp_admin: "register_admin",
      email_subject_user:"Register",
      email_subject_admin:"Register",
      email_server_func: "register",
      event_tracking: "register",
      page_url: "/register"
    },
    {
      grpmd: "12",
      label: "Type",
      placeholder: "Select type *",
      name: "type",
      id: "type",
      element: "select",
      type: "select", 
      required: true,
      class: "select-box select-opt",
      values: actionType
    },
    {
      grpmd: "12",
      label: "Full Name",
      placeholder: "Enter your full name here *",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "Enter your email address here *",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      class: ""
    },
    {
      grpmd: "12",
      label: "Phone Number",
      placeholder: "Your phone number *",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      fieldClass: "",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Min Price",
      placeholder: "Select min price",
      name: "minprice",
      id: "minprice",
      element: "select",
      type: "select", 
      class: "select-box",
      values: minPriceList,
    },
    {
      grpmd: "12",
      label: "Max Price",
      placeholder: "Select max price",
      name: "maxprice",
      id: "maxprice",
      element: "select",
      type: "select", 
      values: maxPriceList,
    },
    {
      grpmd: "12",
      label: "Number of Bedrooms",
      placeholder: "Select bedrooms",
      name: "bedrooms",
      id: "bedrooms",
      element: "select",
      type: "select", 
      values: bedOptions,
    },
    {
      grpmd: "12",
      label: "Where you'd like to live",
      placeholder: "Enter where you'd like to live...",
      name: "message",
      element: "textarea",
      class: "mb-4 msg ",
      rows: "3",
      required: false,
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      name: "Submit",
      type: "submit",
      element: "button",
      value: "submit",
      step: "col-md-12 text-center",
      class: "btn btn-primary",
      formclass: ""
    },
    {
      step: "col-md-12",
      text: 'By clicking Submit, you agree to our <a href="/privacy-policy/"> Privacy Policy </a>.',
      element: "html",
      class: "apply"
    },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
    console.log(event.target.name, event.target.value)
    if(event.target.name === "type" && event.target.value!=="")  {
        setTypeVal(event.target.value)
        if(event.target.value === "Sales") {
          setMinPriceList(salespriceminRange)
          setMaxPriceList(salespricemaxRange)
        }else if(event.target.value === "Lettings") {
          setMinPriceList(priceminRangeRentals)
          setMaxPriceList(pricemaxRangeRentals)
        }
     }else if(event.target.name === "type" && event.target.value==="")  {
        setMinPriceList([])
        setMaxPriceList([])
     }

    if(event.target.name === "minprice" && event.target.value!== undefined) {      
            setMinPriceVal(event.target.value)
    }
    if(event.target.name === "maxprice" && event.target.value!== undefined) {
          setMaxPriceVal(event.target.value)
    }
  }

  const handletypes = event => {

    console.log("type", event.target.value.trimStart())
    // remove initial empty spaces
     //event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {    
    if(minpriceVal) {
      let priceItemList = salespricemaxRange;
      if (typeVal === "Lettings")
        priceItemList = pricemaxRangeRentals
      setMaxPriceList(priceItemList.filter(
        x => (parseInt(x) > parseInt(minpriceVal) || !parseInt(x))
      ))
    }else{
      //let priceItemList = salespricemaxRange;
      //if (typeVal === "lettings")
        //priceItemList = pricemaxRangeRentals
       //setMaxPriceList(priceItemList)
    }

  }, [minpriceVal])


  useEffect(() => {
    if(maxpriceVal) {
      let priceItemList = salespriceminRange;
      if (typeVal === "Lettings")
          priceItemList = priceminRangeRentals
        setMinPriceList(priceItemList.filter(
          x => (parseInt(x.value) < parseInt(maxpriceVal) || !parseInt(x.value))
        ))
    } else {
        //let priceItemList = salespriceminRange;
        //if (typeVal === "lettings")
         // priceItemList = priceminRangeRentals
        //setMinPriceList(priceItemList)
    }
  }, [maxpriceVal])


  useEffect(() => {

    if (token !== '') {

      const processFromData = async () => {


        formvalues['g-recaptcha-response'] = token;

        let formData = new FormData();
        formvalues['extra'] = JSON.stringify({
          message: formvalues.message,
          name: formvalues.name
        });
        formvalues['name'] = formvalues.name;
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;
        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          // lets send mail

          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
          // .then(res => res.json())
          // setServerResponse(response)


          // const axiosOptions_email = {
          //   url: '/api/server/' + fields[0].email_server_func,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          // axios(axiosOptions_email)
          //   .then(response => {
          //     console.log('mail sent!')
          //   })
          //   .catch(err =>
          //     console.log(err)
          //   );
          });

        const url = typeof window !== 'undefined' ? window.location.href : ''
        if(props.title != ""){
          fields[0].formname = props.title
        }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        setTimeout(() => {
          $(".alert-success").show().delay(4000).fadeOut();
        }, 500)
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {
    const form = event.currentTarget;

    /*if (selectedInterst == "") {
      setIsValid(false)
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    } else {
      setIsValid(true)
    }*/

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

    }
    else {
      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;


      json['files'] = file;
      setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();
      //setToken("aaa")
      setValidated(false);

      // reset form
      const form = event.target
      form.reset();

    }
  };

  return (
    <div>
      <div ref={myRef} />

      {showerror && <div className="alert alert-danger alert-error">
        <p>{fields[0].error_text}</p>
      </div>}

      {showthankyou && <div className="alert-success">
        <p>{fields[0].success_text}</p>
      </div>}


      <Form className="form" id="contact-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id ? props.to_email_id : ''} />
        <input type="hidden" name="bot-field" />
        <div className="row">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("file" === field.element) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  label={field.label}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  // handlechange={handleAttachment}
                  required={field.required}
                />
              );
            }
            if ("select" === field.element) {
                if(field.label === "Type") {
                  return (
                    <SelectField
                      name={field.name}
                      id={field.id}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      required={field.required}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      values={field.values}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                      componentprops={props}
                    />
                  );
                }else if(field.label === "Min Price") {
                  return (
                    <SelectField
                      name={field.name}
                      id={field.id}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      required={field.required}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      values={field.values}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                      componentprops={props}
                    />
                  );
                }else if(field.label === "Max Price") {
                  return (
                    <SelectField
                      name={field.name}
                      id={field.id}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      required={field.required}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      values={field.values}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                      componentprops={props}
                    />
                  );
                }else{
                    return (
                      <SelectField
                        name={field.name}
                        id={field.id}
                        grpmd={field.grpmd}
                        label={field.label}
                        ref={field.ref}
                        required={field.required}
                        fieldClass={field.class}
                        placeholder={field.placeholder}
                        values={field.values}
                        key={`${field.element}~${index}`}
                        handlechange={handlechange}
                        componentprops={props}
                      />
                    );
                }
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  step={field.step}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  step={`nostep`}
                  formclass={field.formclass}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
          }
        </div>
      </Form>
    </div>
  );
}


const RegisterFormPage = (props) => (
  <RegisterForm classone={props.classone} to_email_id={props.to_email_id} title={props.title} />
)

export default RegisterFormPage
