import React, { useEffect, useState, useRef } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
//import axios from 'axios';
import AreaGuideRender from "./AreaGuideRender";

import "./CharityPartners.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";

const AREAGUIDELIST = gql`
  query GetAreaGuides ($Area_Name: String!){
    areaGuides(
        where: {Area_Name: $Area_Name} limit: 10 ) {
          id
          Video_URL
          URL
          Title
          Tile_Image {
          url
          alternativeText
          }
          Latitude
          Longtitude
          Publish
      }
  }
`;

const AllAREAGUIDELIST = gql`
  query GETGUIDE {
    areaGuides(where: {Publish: true}) {
          id
          Video_URL
          URL
          Title
          Tile_Image {
          url
          alternativeText
          }
          Latitude
          Longtitude
      }
  }
`;


const AllAreaGuides = (props) => { 

  const { loading, error, data } = useQuery(AllAREAGUIDELIST);
  const { loading: ChesterfieldLoad, error: ChesterfieldErr, data: Chesterfield } = useQuery(AREAGUIDELIST, { variables: { Area_Name: "Chesterfield" } });
  const { loading: SheffieldLoad, error: SheffieldErr, data: Sheffield } = useQuery(AREAGUIDELIST, { variables: { Area_Name: "Sheffield" } });
  const { loading: CrystalLoad, error: CrystalErr, data: Crystal } = useQuery(AREAGUIDELIST, { variables: { Area_Name: "Crystal_Peaks" } });
  const { loading: StocksbridgeLoad, error: StocksbridgeErr, data: Stocksbridge } = useQuery(AREAGUIDELIST, { variables: { Area_Name: "Stocksbridge" } });
  const [activetype, setActivceType] = useState("all")

  useEffect(() => {
    /*var propertySelector = '.all-featured-properties .type-list li';

    $(propertySelector).on('click', function(){
        $(propertySelector).removeClass('active');
        $(this).addClass('active');
    });*/
  },[]);

  let sliderRef = useRef(null);
  const play = () => {
    sliderRef.slickPlay();
  };
  const pause = () => {
    sliderRef.slickPause();
  };

  const settingsAll = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 991,       
        settings: { 
          mobileFirst: true,
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 767,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const settingsSheffield = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 991,       
        settings: { 
          mobileFirst: true,
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 767,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };



  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 991,       
        settings: { 
          mobileFirst: true,
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 767,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (loading) return null;

  return (
    <React.Fragment>
      <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

        <section className={`similar-properties all-featured-properties guide-block charityPartners area-guide-list ${props?.BackgroundColor}`}>
          <Container>
            <Row>
              <Col sm={12}>
                <div className="heading">
                  {(props.Title || props.Bold_Title) ? parse(`<h2><i>${props.Bold_Title? props.Bold_Title : ''}</i> ${props.Title? props.Title : ''}</h2>`) : parse("<h2>All Featured<br /><span>properties</span></h2>")}
                  <div className="type-list">
                    <ul>
                      <li className={activetype == "all" ? "active" : ""} onClick={() => { setActivceType('all') }}>All</li>
                      <li className={activetype == "Chesterfield" ? "active" : ""}onClick={() => { setActivceType('Chesterfield') }}>Chesterfield</li>
                      <li className={activetype == "Sheffield" ? "active" : ""}onClick={() => { setActivceType('Sheffield') }}>Sheffield</li>
                      <li className={activetype == "Crystal_Peaks" ? "active" : ""} onClick={() => { setActivceType('Crystal_Peaks') }}>Crystal Peaks</li>
                      <li className={activetype == "Stocksbridge" ? "active" : ""} onClick={() => { setActivceType('Stocksbridge') }}>Stocksbridge</li>
                    </ul>
                  </div>
                </div>

              <div className={`slick-wrap ${activetype == "all" ? 'd-block' : 'd-none'}`}>
                  {data?.areaGuides.length ?
                    <Slider ref={slider => (sliderRef = slider)} {...settingsAll}>

                      {data && data.areaGuides.map((item, index) => {
                        return (
                          <AreaGuideRender item={item} tag={``} />
                        )
                      })
                      }
                      {data?.areaGuides?.length===4 && data.areaGuides.map((item, index) => {
                        return (
                          <AreaGuideRender item={item} tag={``} />
                        )
                      })
                    }
                    </Slider> : 'No Area Guides found'
                    }
                </div>

              <div className={`slick-wrap ${activetype == "Chesterfield" ? 'd-block' : 'd-none'}`}>
                  {Chesterfield?.areaGuides.length ?
                    <Slider ref={slider => (sliderRef = slider)} {...settings}>

                      {Chesterfield && Chesterfield.areaGuides.map((item, index) => {
                        return (
                          <AreaGuideRender item={item} tag={``} />
                        )
                      })                     
                      }
                      {/*Chesterfield?.areaGuides?.length==4 && Chesterfield.areaGuides.map((item, index) => {
                        return (
                          <AreaGuideRender item={item} tag={``} />
                        )
                      })*/
                      }
                    </Slider> : 'No Area Guides found'
                  }
                </div>

                <div className={`slick-wrap ${activetype == "Sheffield" ? 'd-block' : 'd-none'}`}>
                  {Sheffield?.areaGuides.length ?
                    <Slider ref={slider => (sliderRef = slider)} {...settingsSheffield}>

                      {Sheffield && Sheffield.areaGuides.map((item, index) => {
                        return (
                          <AreaGuideRender item={item}  />
                        )
                      })
                      }
                      {/*Sheffield?.areaGuides?.length===4 && Sheffield.areaGuides.map((item, index) => {
                        return (
                          <AreaGuideRender item={item}  />
                        )
                      })
                    */}
                    </Slider> : 'No Area Guides found'
                  }
                </div>

                <div className={`slick-wrap ${activetype == "Crystal_Peaks" ? 'd-block' : 'd-none'}`}>
                  {Crystal?.areaGuides.length ?
                    <Slider ref={slider => (sliderRef = slider)} {...settings}> 
                      {Crystal && Crystal.areaGuides.map((item, index) => {
                        return (
                          <AreaGuideRender item={item} />
                        )
                      })
                      }
                      {/*Crystal?.areaGuides?.length===4 && Crystal.areaGuides.map((item, index) => {
                        return (
                          <AreaGuideRender item={item} />
                        )
                      })
                    */}
                    </Slider> : 'No Area Guides found'
                  }
                </div>

                <div className={`slick-wrap ${activetype == "Stocksbridge" ? 'd-block' : 'd-none'}`}>
                  {Stocksbridge?.areaGuides.length ?
                    <Slider ref={slider => (sliderRef = slider)} {...settings}> 
                      {Stocksbridge && Stocksbridge.areaGuides.map((item, index) => {
                        return (
                          <AreaGuideRender item={item} />
                        )
                      })
                      }
                      {/*Stocksbridge?.areaGuides?.length===4 && Stocksbridge.areaGuides.map((item, index) => {
                        return (
                          <AreaGuideRender item={item} />
                        )
                      })
                    */}
                    </Slider> : 'No Area Guides found'
                  }
                </div>
                
              </Col>
            </Row>
          </Container>
                </section>

      </ScrollAnimation> 
    </React.Fragment>
  ); 
};

export default AllAreaGuides;
