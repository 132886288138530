import React from "react";
import { Link } from "@StarberryUtils"  

import "./Breadcrumb.scss";




const Breadcrumbs = ({ menus, breadcrumbsClass, staticList }) => {

  const PageBreadcrumbs = (data = null, staticList = []) => {
    var breadcrumbs = []

    data?.map((bread, i, arr) => {
      if ((bread?.Main_Parent)) {
        breadcrumbs.push({ label: bread?.Main_Parent?.Label, url: `/${bread?.Main_Parent?.URL}` })
      } 
      if ((bread?.Label)) {
        breadcrumbs.push({ label: bread?.Label, url: `/${bread?.URL}` })
      }
    })
    
    if ((staticList)) {
      staticList.map((item) => (breadcrumbs.push({ label: item.Title, url: `/${item.Slug}` })))
    }
    return breadcrumbs;
  }

  const breadcrumbs = PageBreadcrumbs(menus, staticList);
  return (
    <div className={`container ${breadcrumbsClass ? ' ' + breadcrumbsClass : ''}`}>
      <ul className="section-breadcrumbs">
      <li className="breadcrumb-item"><Link to="/" className="breadcrumb-item">Home</Link><i class="icon-next-arrow"></i></li>
      {breadcrumbs?.map((breadcrumb, i, arr) => {
        if (arr.length - 1 === i) { // is the last item in the array
          return (
            <li className="breadcrumb-item" key={i}>{breadcrumb.label}</li>
          )
        } else {
          return (
            <li className={`breadcrumb-item ${!breadcrumb.url.replace(/\//g, '')?'no-link':''}`} key={i}>
              <Link
                key={i}
                to={breadcrumb.url}

                className="breadcrumb-item"


              >
                {breadcrumb.label}
              </Link>
              <i class="icon-next-arrow"></i>
            </li>
          )
        }
      })}
      </ul>
    </div>
  )
}

export default Breadcrumbs
