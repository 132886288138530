import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import { navigate } from "@reach/router"
import parse from 'html-react-parser';
import gql from "graphql-tag"
import GuideFormPage from '../../forms/guide-form';
import ModalTiny from "react-bootstrap/Modal"
import HTMLReactParser from 'html-react-parser';


import Sliderdesk from "../../../images/propertyDetails/desktop.jpg"



import Slider from "react-slick";
import "./GuidesBlock.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const GUIDE_LIST = gql`
query GUIDE_LIST {
    guides(publicationState: LIVE,
        sort: "createdAt:DESC",
        limit:8){
        id
        Title
        URL
        Description
        Image {
          alternativeText
          url
        }
        File {
          alternativeText
          url
        } 
    }
}
`;



function GuidesBlock(props) {

    const { loading, error, data } = useQuery(GUIDE_LIST);

    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                  dots: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
        ],
    };

    const [modalSharefrndformOpen, setSharefrndformOpen] = useState(false);
    const [pdfFile, setPdfFile] = useState("");
    const [title, setTitle] = useState("");

    //var guidesList = data?.guides.filter(item => item.Title.toLowerCase().includes("renting"));
    //var guidesexclude = guidesList[0]?.id

    // Pdf download function
    const downloadFUnction = () => {
        if (pdfFile) {
            // using Java Script method to get PDF file
            fetch(pdfFile).then(response => {
                response.blob().then(file => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(file);
                    const PDF = pdfFile.replace(pdfFile, title)

                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = PDF;
                    alink.click();
                })
            })
        }
    }

    const openSharefrndformModal = (e) => {
        // setPdfFile(e?.File?.url)
        // setTitle(e?.Title)
        // setSharefrndformOpen(e?.File?.url ? true : false);
        navigate(`/guide/${e.URL}`)
    }
    const openSharefrndformModal1 = (e) => {
        // setPdfFile(e?.File?.url)
        // setTitle(e?.Title)
        // setSharefrndformOpen(e?.File?.url ? true : false);
        navigate('/about-us/property-news/', { state: { slug: `tips-and-guides`} });
    }

    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    if (loading) return (
        <p>loading</p>
    )

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                <section className="similar-properties guide-block latest-news">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="heading">
                                {(props.Title || props.Bold_Title) ? parse(`<h2><i>${props.Bold_Title? props.Bold_Title : ''}</i>${props.Title? props.Title : ''}</h2>`) : parse("<h2>All Featured<br /><span>properties</span></h2>")}
                                    {/*<Link to={`/about-us/property-news/`} className="link-tag">More guides old</Link>*/}
                                    <a href="javascript:void(0)" onClick={() => openSharefrndformModal1()}> More guides</a>
                                </div>
                                <div className="slick-wrap">
                                    <Slider {...settings}>
                                        {data.guides && data.guides.map((guide, i) => {
                                            if("6513d7434ee705d3fa765b14"!==guide.id) {
                                            return (
                                                <div className="properties img-zoom">
                                                    <div className="properties-img">
                                                        <img onClick={() => openSharefrndformModal(guide?.File?.url ? guide : "")} src={guide.Image.url} alt="image" />
                                                    </div>

                                                    <div className="gallery-text">
                                                        <h3 onClick={() => openSharefrndformModal(guide?.File?.url ? guide : "")} className='guide_title'>{guide.Title}</h3>
                                                       
                                                    </div>
                                                </div>
                                            )
                                            }
                                        })}
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
            <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="book-a-viewing-modal guide_modal">
                <ModalTiny.Header closeButton className="contact-close-btn px-0 px-md-3">
                    <ModalTiny.Title className="w-100"><h2 className="mb-0 text-uppercase">Download <span className='color-text'>The Guide</span></h2></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section px-0">
                    {/* <p className="px-0 px-md-3"></p> */}
                    <GuideFormPage formLabel="Guide Form" pdfFile={pdfFile} pdfTitle={title} downloadFUnction={() => downloadFUnction()} to_email_id={process.env.MAIL_VIEWING_TO} />
                </ModalTiny.Body>
            </ModalTiny>
        </React.Fragment>
    );
};

export default GuidesBlock;
