import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import axios from 'axios';
import PriceComp from "@components/Property/Price"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import PropertyRender from "./PropertyRender";
import $ from "jquery";



import Slider from "react-slick";
import "./SimilarProperties.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const FEATURED_PROPERTY = gql`
  query GetProperty ($ptype: String!){
      properties(
        where: {status_in: ["For Sale", "To Let"], publish: true, department: "residential", search_type: $ptype}
        sort: "available_from:DESC"
        limit: 5
      ) {
        id
        status
        imagetransforms
        images
        price
        price_qualifier
        reception
        slug
        search_type
        title
        crm_id
        department
        display_address
        building
        brochure
        bedroom
        bathroom
        area
      }
  }
`;


const NEW_DEVELOPMENT = gql`
  query newDevelopment {
    newDevelopments(where:{publish: true}, limit: 5) {
        id
        display_address
        imagetransforms 
        price
        search_type
        street_name
        title
        status
        bedroom
        town 
        images {
          url
          alternativeText
        } 
        slug
    }
  }
`;
const INTERNATIONAL_PROPERTIES = gql`
  query GetInternationalProperty {
    internationalProperties(where:{publish: true}, limit: 5) {
        id
        display_address
        imagetransforms 
        price
        search_type
        street_name
        title
        status
        bedroom
        town 
        images {
          url
          alternativeText
        } 
        slug
    }
  }
`;

const AllFeaturedProperties = (props) => {
  const { loading, error, data } = useQuery(FEATURED_PROPERTY, { variables: { ptype: "sales" } });
  const { loading: letLoad, error: letErr, data: lettingsList } = useQuery(FEATURED_PROPERTY, { variables: { ptype: "lettings" } });
  const { loading: newLoad, error: newErr, data: newDevelopmentList } = useQuery(NEW_DEVELOPMENT);
  const { loading: interLoad, error: interErr, data: internationalList } = useQuery(INTERNATIONAL_PROPERTIES);
  const [activetype, setActivceType] = useState("sales") 
 
  useEffect(() => {
    var propertySelector = '.all-featured-properties .type-list li';

    $(propertySelector).on('click', function(){
        $(propertySelector).removeClass('active');
        $(this).addClass('active');
    });
  },[]);

  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 2,
    infinite: false,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1, 
          arrows: true,
          dots: false,
        },
      },

      {
        breakpoint: 991,       
        settings: { 
          mobileFirst: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1, 
        },
      },

      {
        breakpoint: 767,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          mobileFirst: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (loading) return null;

  return (
    <React.Fragment>
      <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

        <section className={`similar-properties all-featured-properties ${props.BackgroundColor}`}>
          <Container>
            <Row>
              <Col sm={12}>
                <div className="heading">
                  {(props.Title || props.Bold_Title) ? parse(`<h2><i>${props.Bold_Title? props.Bold_Title : ''}</i>${props.Title? props.Title : ''}</h2>`) : parse("<h2>All Featured<br /><span>properties</span></h2>")}
                  <div className="type-list">
                    <ul>
                      <li className={activetype == "sales" ? "active" : ""} onClick={() => { setActivceType('sales') }}>Sales</li>
                      <li className={activetype == "lettings" ? "active" : ""}onClick={() => { setActivceType('lettings') }}>Lettings</li>
                      <li className={activetype == "newDevelopment" ? "active" : ""}onClick={() => { setActivceType('newDevelopment') }}>New Homes</li>
                      <li className={activetype == "international" ? "active" : ""} onClick={() => { setActivceType('international') }}>International</li>
                    </ul>
                  </div>
                </div>

                <div className={`slick-wrap ${activetype == "sales" ? 'd-block' : 'd-none'}`}>
                  {data?.properties.length ?
                    <Slider {...settings}>

                      {data && data.properties.map((item, index) => {
                        return (
                          <PropertyRender item={item} tag={``} />
                        )
                      })
                      }
                    </Slider> : 'No properties found'
                  }
                </div>
                <div className={`slick-wrap ${activetype == "lettings" ? 'd-block' : 'd-none'}`}>
                  {lettingsList?.properties.length ?
                    <Slider {...settings}>

                      {lettingsList && lettingsList.properties.map((item, index) => {
                        return (
                          <PropertyRender item={item} tag={``} />
                        )
                      })
                      }
                    </Slider> : 'No properties found'
                  }
                </div>
                <div className={`slick-wrap ${activetype == "newDevelopment" ? 'd-block' : 'd-none'}`}>
                  {newDevelopmentList?.newDevelopments.length ?
                    <Slider {...settings}>

                      {newDevelopmentList && newDevelopmentList.newDevelopments.map((item, index) => {
                        return (
                          <PropertyRender item={item} tag={`new-developments`} />
                        )
                      })
                      }
                    </Slider> : 'No properties found'
                  }
                </div>
                <div className={`slick-wrap ${activetype == "international" ? 'd-block' : 'd-none'}`}>
                  {internationalList?.internationalProperties.length ?
                    <Slider {...settings}> 
                      {internationalList && internationalList.internationalProperties.map((item, index) => {
                        return (
                          <PropertyRender item={item} tag={`international`} />
                        )
                      })
                      }
                    </Slider> : 'No properties found'
                  }
                </div>
                
              </Col>
            </Row>
          </Container>
        </section>
      </ScrollAnimation>
    </React.Fragment>
  );
};

export default AllFeaturedProperties;
