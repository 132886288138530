import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import parse from 'html-react-parser';

import Slider from "react-slick";
import "./NewsBlock.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const NEWS_LIST = gql`
query NEWS_LIST {
    newsBlogs(where:{Publish: true},
        sort: "Date:DESC",
        limit:8){
        id
        Title
        Date
        URL
        Tile_Image {
          url
          alternativeText
        }
        Categories {
          Name
          URL
        }
        imagetransforms
    }
}
`;



function NewsBlock(props) {

    const { loading, error, data } = useQuery(NEWS_LIST);

    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true, 
                },
            },

            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1, 
                },
            },
            {
                breakpoint: 480,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1, 
                },
            },
        ],
    };

    if (loading) return (
        <p>loading</p>
    )

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                <section className="similar-properties latest-news">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="heading">
                                {(props.Title && props.Bold_Title) ? parse(`<h2><i>${props.Title}</i>${props.Bold_Title}</h2>`):parse(`<h2>${props.Bold_Title}</h2>`)}
                                    <Link to={`/about-us/property-news/`} className="link-tag">More News</Link>
                                </div>
                                <div className="slick-wrap">
                                    <Slider {...settings}>
                                        {data.newsBlogs && data.newsBlogs.map((news, i) => {

                                            var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

                                            var selectedMonthName = months[parseInt(news.Date.slice(5, 7))];

                                            var cat_list = ''
                                            var cats = news.Categories

                                            for (var i = 0; i < cats.length; i++) {
                                                var full_name = cats[i].Name
                                                cat_list += (cats[i].Name) + `${cats.length == 1 || i + 1 == cats.length ? '' : ', '}`
                                            }
                                            if(cat_list.length == 0){
                                                cat_list = 'Redbrik'
                                            }

                                            return (
                                                <Link to={`/about-us/property-news/${news.URL}`}>
                                                    <div className="properties img-zoom">
                                                        <picture>
                                                            <source media="(min-width:1500px)" srcSet={news.Tile_Image.url} />
                                                            <source media="(min-width:992px)" srcSet={news.Tile_Image.url} />
                                                            <source media="(min-width:768px)" srcSet={news.Tile_Image.url} />
                                                            <img src={news.Tile_Image.url} alt={news.Title} />
                                                        </picture>

                                                        <div className="gallery-text">
                                                            <span className="date">
                                                                {news.Date.slice(8, 10)}
                                                            </span>
                                                            <span className="month">
                                                                {selectedMonthName}
                                                            </span>
                                                        </div>

                                                        <div className="card-details">
                                                            <h3>
                                                                {news.Title}
                                                            </h3>
                                                            <div className="cats"># {cat_list}</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
        </React.Fragment>
    );
};

export default NewsBlock;
