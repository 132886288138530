import React from "react";
import parse from 'html-react-parser';
import { Link } from "@StarberryUtils"
import {Row, Col, Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import PlayVideo from "../Play/custom-video";
import LocRatingMap from '../map/loc-rating-map-area-guides';

import "../ProperyDetails/Location/Location.scss";

const PlainHeader = (props) => {
    
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        areaGuides(where: {Publish: true}) {
            id
            Video_URL
            URL
            Title
            Tile_Image {
            url
            alternativeText
            }
            Latitude
            Longtitude
            imagetransforms
        }
    }
  }
`);

const areaguides = data.glstrapi.areaGuides;

    // Get Map Details
    let mapItems = [];
    {areaguides.map((node, key) => {
    let mapItem = {};
    mapItem['lat']  = node.Latitude      
    mapItem['lng']  = node.Longtitude
    mapItem['id']  = node.id
    const mapMarkerImageShowFlag = 0;
    mapItems.push( mapItem );
    })};

    return (
        <section class="about-location area-guides-list">
        <Container className="list">
            <Row>
            {areaguides.map((node, key) => {

                const image_url = node.Tile_Image.url 

                let processedImages = JSON.stringify({});
                if (node?.imagetransforms?.Tile_Image_Transforms) {
                    processedImages = node.imagetransforms.Tile_Image_Transforms;
                }

                return (
                <Col md={4} lg={3} className="location area-guide-box" id={node.id}>
                    <Link to={node.URL} className="area-guide-img">
                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="area-guides.Tile_Image.tile" attr={{ alt: node.Title+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={node.id}/>
                    </Link>
                    <Link to={node.URL}>
                    <h3>{node.Title}</h3>
                    </Link>
                    {node.Video_URL &&
                    <div className="video-buttons">
                        <PlayVideo url={node.Video_URL} />
                    </div>
                    }

                </Col>
                )
            })}
            </Row>
        </Container>

        {/* <div className="area-guides-landing-map" id="area-guides-landing-map">
            <LocRatingMap data={mapItems} />
        </div> */}

        </section>
    )
}

export default PlainHeader;
