import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import { navigate } from "@reach/router"
import parse from 'html-react-parser';
import gql from "graphql-tag"

import Slider from "react-slick";
import "./Accreditations.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const GUIDE_LIST = gql`
query GUIDE_LIST {
    accreditations {
        accreditations_title
        accreditations_image {
            url
        }
        accreditations_url
    }
}
`;



function Accreditations(props) {

    const { loading, error, data } = useQuery(GUIDE_LIST);

    console.log("data", data)

    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true,
                    dots: false,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                  dots: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
        ],
    };

    const [modalSharefrndformOpen, setSharefrndformOpen] = useState(false);
    const [pdfFile, setPdfFile] = useState("");
    const [title, setTitle] = useState("");

    // Pdf download function
    const downloadFUnction = () => {
        if (pdfFile) {
            // using Java Script method to get PDF file
            fetch(pdfFile).then(response => {
                response.blob().then(file => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(file);
                    const PDF = pdfFile.replace(pdfFile, title)

                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = PDF;
                    alink.click();
                })
            })
        }
    }

    const openSharefrndformModal = (e) => {
        // setPdfFile(e?.File?.url)
        // setTitle(e?.Title)
        // setSharefrndformOpen(e?.File?.url ? true : false);
        navigate(`/guide/${e.URL}`)
    }

    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    if (loading) return (
        <p>loading</p>
    )

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

               <section className="similar-properties guide-block latest-news accreditations">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="heading">
                                {(props.Title || props.Bold_Title) ? parse(`<h2><i>${props.Bold_Title? props.Bold_Title : ''}</i>${props.Title? props.Title : ''}</h2>`) : ""}                                   
                                </div>
                                <div className="slick-wrap">
                                    <Slider {...settings}>
                                        {data.accreditations && data.accreditations.map((guide, i) => {
                                            return (
                                                <div className="properties img-zoom">
                                                    <div className="properties-img">
                                                        <a target="_blank" href={guide.accreditations_url}><img src={guide.accreditations_image.url} alt="image" /></a>
                                                    </div>

                                                    <div className="gallery-text">
                                                    <a target="_blank" href={guide.accreditations_url}><h3 className='guide_title'>{guide.accreditations_title}</h3></a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                                    </section>
            </ScrollAnimation>            
        </React.Fragment>
    );
};

export default Accreditations;
