import React,{useEffect, useState} from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import axios from 'axios';
import PriceComp from "@components/Property/Price"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
 
import Slider from "react-slick";
import "./SimilarProperties.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const FEATURED_PROPERTY = gql`
  query GetProperty ($ptype: String!){
      properties(
        where: {status_in: ["For Sale", "To Let"], publish: true, department: "residential", search_type: $ptype}
        sort: "available_from:DESC"
        limit: 10
      ) {
        id
        status
        imagetransforms
        images
        price
        price_qualifier
        reception
        slug
        search_type
        title
        crm_id
        department
        display_address
        building
        brochure
        bedroom
        bathroom
        area
      }
  }
`;

const FEATURED_PROPERTY_OFFICE = gql`
  query GetProperty ($ptype: String!, $officeId: String!){
      properties(
        where: {status_in: ["For Sale", "To Let"], publish: true, department: "residential", search_type: $ptype, office_crm_id: $officeId}
        sort: "available_from:DESC"
        limit: 10
      ) {
        id
        status
        imagetransforms
        images
        price
        price_qualifier
        reception
        slug
        search_type
        title
        crm_id
        department
        display_address
        building
        brochure
        bedroom
        bathroom
        area
      }
  }
`;

const FEATURED_PROPERTY_AREA = gql`
  query GetProperty ($ptype: String!, $area: String!){
      properties(
        where: {status_in: ["For Sale", "To Let"], publish: true, department: "residential", search_type: $ptype, display_address_contains: $area}
        sort: "available_from:DESC"
        limit: 10
      ) {
        id
        status
        imagetransforms
        images
        price
        price_qualifier
        reception
        slug
        search_type
        title
        crm_id
        department
        display_address
        building
        brochure
        bedroom
        bathroom
        area
      }
  }
`;

const FeaturedProperties = (props) => {

    let officeId = props.officeId ?? "";
    let area = props.area ?? "";
    let landtag = props.landtag ?? "";
    let ptype = props.Featured_Property_Type == 'Lettings' ? 'lettings' : 'sales';
    let queryName = FEATURED_PROPERTY;
    let queryParams =  { ptype: ptype }
    if (area && landtag != "landing") {
      queryName = FEATURED_PROPERTY_AREA;
      queryParams =  { ptype: ptype, area:area }
    }
    if (officeId) {
      queryName = FEATURED_PROPERTY_OFFICE;
      queryParams =  { ptype: ptype, officeId:officeId }
    }

    const { loading, error, data } = useQuery(queryName, {
        variables: queryParams
    });

  // const [data, setdata] = useState(false)
  // const getitems = async url => {
  //   try {
  //     const { data } = await axios.get(url, {})
  //     setdata(data)
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  useEffect(() => {

    // let ptype = props.Featured_Property_Type == 'Lettings' ? 'lettings' : 'sales';
    // let url = process.env.GATSBY_STRAPI_SRC + "/properties?department=residential&search_type="+ptype+"&_limit=10&_sort=available_from:DESC&publish=true"; // TODO: base URL supposed to be from .env
    // if (area && landtag != "landing") {
    //   url = url + "&display_address_contains=" + area;
    // }
    // if (officeId) {
    //   url = url + "&office_crm_id=" + officeId;
    // }

    // getitems(url)
  },[])


    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: { 
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: { 
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {  
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    if (loading) return null;

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                <section className="similar-properties featured-properties">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                {props.Featured_Properties_Title_Override ? parse(props.Featured_Properties_Title_Override) : parse("<h2>Featured<br /><span>properties</span></h2>")}
                                <div className="slick-wrap">
        {data?.properties.length ?
                                <Slider {...settings}>

                                {data && data.properties.map((item, index) => {
                // property details url structure
                let uriStr = "";

                if( item.search_type === "sales" ) {
                  uriStr = `property-for-sale-details/`
                } else if ( item.search_type === "lettings" ) {
                  uriStr = `property-to-rent-details/`
                }

                if (props.tag == "new-homes") {
                  if( item.search_type === "sales" ) {
                    uriStr = `new-homes-for-sale/`
                  } else if ( item.search_type === "lettings" ) {
                    uriStr = `new-homes-to-rent/`
                  }
                }
                // property details url structure

                let processedImages = JSON.stringify({});
            if (item?.imagetransforms?.images_Transforms) {
                processedImages = item.imagetransforms.images_Transforms;
            }



            return (


                                    <div className="properties img-zoom">
                                        <Link to={'/'+uriStr+item.slug+'-'+item.id}>
                                        <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename="property.images.similar_properties" attr={{ alt: item.display_address+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={item._id}/>
                                        </Link>
                                        <div className="card-details">
                                            <h3>
                                                <Link to={'/'+uriStr+item.slug+'-'+item.id}>
                                                {item.display_address}
                                                </Link>

                                            </h3>
                                            {item.price &&
                                            <h4>
                                                <PriceComp {...item} />
                                            </h4>
                                            }
                                            <div className="card-info">
                                                {item.bedroom &&
                                                <span className="details">
                                                    <Link to={'/'+uriStr+item.slug+'-'+item.id}>
                                                        <i className="icon-bedroom"></i>
                                                    </Link>
                                                    {item.bedroom}
                                                </span>
                                                }
                                                {item.bathroom &&
                                                <span className="details">
                                                    <Link to={'/'+uriStr+item.slug+'-'+item.id}>
                                                        <i className="icon-bathroom"></i>
                                                    </Link>
                                                    {item.bathroom}
                                                </span>
                                                }
                                                {item.reception &&
                                                <span className="details">
                                                    <Link to={'/'+uriStr+item.slug+'-'+item.id}>
                                                        <i className="icon-chair"></i>
                                                    </Link>
                                                    {item.reception}
                                                </span>
                                                }
                                            </div>
                                        </div>
                                    </div>


            )})
        }


                                </Slider> : 'No properties found'
            }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
        </React.Fragment>
    );
};

export default FeaturedProperties;
