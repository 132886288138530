import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import axios from 'axios';
import PriceComp from "@components/Property/Price"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import PropertyRender from "./PropertyRender"



import Slider from "react-slick";
import "./SimilarProperties.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const SOLD_PROPERTY = gql`
  query GetProperty ($ptype: String!){
      properties(
        where: {status_in: ["Sold STC"], publish: true, department: "residential", search_type: $ptype}
        sort: "available_from:DESC"
        limit: 5
      ) {
        id
        status
        imagetransforms
        images
        price
        price_qualifier
        reception
        slug
        search_type
        title
        crm_id
        department
        display_address
        building
        brochure
        bedroom
        bathroom
        area
      }
  }
`;
  

const SoldProperties = (props) => {
  const { loading, error, data } = useQuery(SOLD_PROPERTY, { variables: { ptype: "sales" } });  

  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 2,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          dots: false
        },
      },

      {
        breakpoint: 991,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false
        },
      },

      {
        breakpoint: 767,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (loading) return null;

  return (
    <React.Fragment>
      <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

        <section className={`similar-properties no-padding-top sold-properties ${props.BackgroundColor}`}>
          <Container>
            <Row>
              <Col sm={12}>
                <div className="heading">
                {(props.Title || props.Bold_Title) ? parse(`<h2><i>${props.Bold_Title? props.Bold_Title : ''}</i>${props.Title? props.Title : ''}</h2>`) : ""}
                </div>

                <div className={`slick-wrap`}>
                  {data?.properties.length ?
                    <Slider {...settings}>

                      {data && data.properties.map((item, index) => {
                        return (
                          <PropertyRender item={item} tag={``} />
                        )
                      })
                      }
                    </Slider> : 'No properties found'
                  }
                </div> 
                
              </Col>
            </Row>
          </Container>
        </section>
      </ScrollAnimation>
    </React.Fragment>
  );
};

export default SoldProperties;
