import React, { } from "react"
import { get } from 'lodash';
import { Link } from "@StarberryUtils"

import ModalTiny from "react-bootstrap/Modal"
import parse from 'html-react-parser';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import $ from "jquery";

import GenerateLink from "../../common/site/generate-link"
import { sitename } from "@constants";
import "./Banner.scss"
import ReviewsCount from '../Reviews/review-count'
import bannerlogo from "../../../images/banner-logo.png"
import GoogleReview from "../../../images/google-reviews-white.svg"

// Header component
let slider = {};

const Banner = (props) => {
  const ctas = get(props, 'Buttons', []);
  const img = get(props, 'Image.url')
  const [showModal, setShowModal] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const openModal = () => {
    setShowModal(true);
  }
  const hideModal = () => {
    setShowModal(false);
  }

  const handleonClick = (e, cta) => {
    e.preventDefault();
    setShowModal(true);
    setModalData(cta)
  }

  const handleLinkTarget = (e, target) => {
    e.preventDefault();
    $('html, body').animate({
        scrollTop: $(target).offset().top - 140//document.getElementById('pageheader').offsetHeight
    }, 1000);
}


  let gatsbyImg = getImage(props?.Image?.url_sharp)

  let pagename = props?.Pagename;

  //console.log("pagename", pagename)
  let iconsClass ='logo-home-banner'
  let isHome = false;
  let isHomeBanner = false
  if (pagename === 'Home'){
    pagename = 'property service';
    isHome = true;
    iconsClass ='logo-home-banner'
    isHomeBanner = true
  }
  if (pagename === 'International'){
    isHome = true;
    iconsClass ='logo-International-banner'
  }
  if (pagename === 'New Homes'){
    isHome = true;
    iconsClass ='logo-newhomes-banner'
  }
  if (pagename === 'Redbrik Foundation'){
    isHome = true;
    iconsClass ='logo-foundation-banner'
  }
  
  
  
  return (
    <section className="home-banner home-banner-links">
      <div className="banner-item">
          <div className="banner-img">
            {/* When using ggfx clitn component, banner image getting time to load, facing some jump issue. Using gatsby image to over come this */}
            <GatsbyImage image={gatsbyImg} alt={img.alternativeText || `${pagename} Banner - ${sitename} Estate Agents`} className="d-block" />
          </div>

          <div className="banner-content-alt d-lg-flex justify-content-center">
            <div className="">
              <div className="banner-contents">
               {isHome?
               <>                
                <i className={`${iconsClass? iconsClass : "logo-home-banner"}`}></i>
                {props.Heading && (
                  <div className="banner-header">{props.Pre_Title &&
                    <span className={`${isHome ? "italic" : "bold"}`}>
                      {props.Pre_Title}</span>} <span className={`sub_title ${isHome ? "bold" : "normal"}`}>{props.Heading}</span></div>
                )}
                 {/*props.Sub_Heading && (
                  <h1 className="h2">{props.Sub_Heading}</h1>
                 )*/}
                </>
                 :
                props.Heading && (
                  <h1>{props.Pre_Title &&
                    <span className={`${isHome ? "italic" : "bold"}`}>
                      {props.Pre_Title}
                    </span>
                  } <span className={`${isHome ? "bold" : "normal"}`}>{parse(props.Heading)}</span></h1>
                )
              }

              {isHomeBanner?
                <>
                    {props.Content &&
                      <h1 className="paragraph">{parse(props.Content.replace(/<[^>]+>/g, ''))}</h1>
                    }
                </>
                :
                <>
                  {props.Content &&
                    <>{parse(props.Content)}</>
                  }
                </>
              }

                {(ctas.length>0) && (             
                  <div className="btn-list">
                    {ctas.map((cta, i) => {
                      const  parenturl = cta?.Link?.Main_Parent?.URL
                      var pageurl = parenturl?'/'+parenturl+"/"+cta?.Link?.URL:"/"+cta?.Link?.URL
                      pageurl = cta?.custom_url?cta?.custom_url:pageurl

                      if (cta?.Link?.URL.match(/http|https/)) {
                        return (
                          <a href={`/${cta?.Link?.URL}`} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className={`btn ${cta.Color === "primary" ? 'btn-trans' : cta.Color}`}>{cta.Label}</a>
                        )
                      } else {
                        if("Book a site appraisal"===cta?.Label) {
                          return (
                          <a href="javascript:void(0)" onClick={(e) => handleonClick(e, cta)} className="btn transparent">{cta.Label}</a>
                          )
                        }else if("View vacancies"===cta?.Label) {
                          return (
                              <a href="javascript:void(0)" onClick={(e) => handleLinkTarget(e, '.CurrentVacancies')} className="btn transparent">{cta.Label}</a>
                          )
                        } else {
                                return (
                                  <Link to={`${pageurl}`} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className={`btn ${cta.Color === "primary" ? 'btn-trans' : cta.Color}`}>
                                    {cta.Icon && 
                                      <i className={`icon-video-white`}></i>
                                    }
                                    {cta.Label}
                                  </Link>
                                )
                          }
                      }
                    } ) }
                  </div>
                )}
               
              </div>
            </div>
          </div> 
      </div>
      <ModalTiny show={showModal} onHide={hideModal} className="book-a-viewing-modal iframe-modal">
          <ModalTiny.Header closeButton className="contact-close-btn">
              <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">{modalData?.Label}</h2></ModalTiny.Title>
          </ModalTiny.Header>
          <ModalTiny.Body className="event-section">
              <iframe src={modalData?.custom_url} width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
          </ModalTiny.Body>
      </ModalTiny>
    </section>
  )
}
export default Banner
