import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get } from 'lodash';
import { Link } from "@StarberryUtils"
import parse from 'html-react-parser';
import "./ImageAndVideoBlock.scss";
import PlayVideo from "../../Play/custom-video";
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
// Header component

const ImageAndVideoBlock = (props) => {
    const ctas = get(props, 'Buttons', []);
    const [isPlay, setPlay] = useState(false);
    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.Video_Background_Image_Transforms) {
        processedImages = props.imagetransforms.Video_Background_Image_Transforms;
    }
    return (
        <React.Fragment>
            <section className={`choice image-video-block ${props.custom_class}`}>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                <Container>
                <Row>
                <Col sm={6}>
                    <div className="video-column">
                        <div className={"video-image-banner"}>
                        {props?.Video_Embed_Code?<div className="ifram-container">
                            <iframe src={props?.Video_Embed_Code} frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title={`${props.Pagename} | Your place to thrive`}></iframe>
                        </div>
                        :<>
                            {props.officeImage ?

                                <ImageTransform
                                    imagesources={props.officeImage.url}
                                    renderer="srcSet"
                                    imagename="offices.Video_Background_Image.detail"
                                    attr={{ alt: "office", className: 'img-zoom' }}
                                    imagetransformresult={processedImages}
                                    id={props?.pageId}
                                />
                                :
                                <ImageTransform
                                    imagesources={props.Image.url}
                                    renderer="srcSet"
                                    imagename="articles.Video_Background_Image.detail"
                                    attr={{ alt: props?.Image?.alternativeText, className: 'img-zoom' }}
                                    imagetransformresult={processedImages}
                                    id={props?.pageId}
                                    testparam={true}
                                />
                            }
                            <div className="video-btn-text">
                                <div className="video-buttons">
                                    {props.Video_Url ?
                                        <PlayVideo url={props.Video_Url} />
                                    :
                                        <PlayVideo url={props.Video_Upload.length>0?props.Video_Upload[0].url:''} />
                                     }
                                </div>
                                {props.Image_Info &&
                                    <div className="image-info">
                                        {parse(props.Image_Info)}
                                    </div>
                                }
                            </div>
                         </>
                        }
                        </div>
                    </div>
                    </Col>
                    <Col sm={6}>
                                {props.Content &&
                                    <div className="status-content">
                                        {parse(props.Content)}
                                           
                                           {(ctas.length) && (
                                            <div className="btn-list">
                                            {ctas.map((cta, i) => {
                                                let parenturl = cta?.Link?.Main_Parent?.URL
                                                let pageUrl = parenturl?parenturl+"/"+cta?.Link?.URL:cta?.Link?.URL
                                                pageUrl = cta?.custom_url?cta?.custom_url:pageUrl
                                                if (cta?.Link?.URL.match(/http|https/)) {
                                                    return (
                                                        <a href={cta?.Link?.URL} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className={`btn ${cta.Color === "primary" ? 'btn-trans' : cta.Color}`}>{cta.Label}</a>
                                                    )
                                                } else {
                                                    return (
                                                        <Link to={pageUrl} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className={`btn ${cta.Color === "primary" ? 'btn-trans' : cta.Color}`}>
                                                            {cta.Icon &&
                                                                <i className={`icon-video-white`}></i>
                                                            }
                                                            {cta.Label}
                                                        </Link>
                                                    )
                                                }
                                            })}
                                            </div>
                                            )}                                          
                                    </div>
                                }
                                                        
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment>
    );
};

export default ImageAndVideoBlock;
