import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get } from 'lodash';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { Link } from "@StarberryUtils"
import parse from 'html-react-parser';
import "./ImageBlock.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import Slider from "react-slick";
// Header component

const GUIDE_LIST = gql`
query GUIDE_LIST {
    serviceBlockContents {
        id
        contents
        image {
            url
        }
        btn_label
        URL
        title
        Bold_Title
        Sub_Heading
      }
}
`;

const ImageBlockSlide = (props) => {

    const { loading, error, data } = useQuery(GUIDE_LIST);


    const ctas = get(props, 'Buttons', []);
    let processedImages = JSON.stringify({});
    if (props?.imagetransforms?.Video_Background_Image_Transforms) {
        processedImages = props.imagetransforms.Video_Background_Image_Transforms;
    }


    function htmlDecode(input){
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].innerHTML;
    }


    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                  dots: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
        ],
    };


   
    
    return (
        <React.Fragment>
            <section className={`imageblock Left no-padding-top`}> 
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    <Container>

                    <Slider {...settings}>
       
                           {data?.serviceBlockContents.map((guide, i) => {

                            var contents = guide.contents.substring(0, 350)
                            var property_desc = htmlDecode(guide.contents)
                            return (
                                <>
                                <Row>
                                        <Col sm={6}>
                                            <div className={"img-column"}>

                                            <img className="img-zoom" src={guide.image.url} alt="logo"/>
                                            </div>
                                        </Col>
                                
                                    <Col sm={6}>
                                        {guide.contents &&
                                            <div className="status-content">
                                                <h2>
                                                    <>
                                                {guide?.title &&
                                                    <em> {guide.title}</em> 
                                                }
                                       
                                                {guide?.Bold_Title &&
                                                     <> {guide.Bold_Title}</>
                                                }
                                                </>
                                                </h2>
                                                {guide?.Sub_Heading &&
                                                     <h5>{guide.Sub_Heading}</h5>
                                                }
                                               
                                                <p>{parse(property_desc)}</p>
                                                <a href={`/lifestyle/home-and-interiors/${guide?.URL}`} className="btn btn_cray">{guide.btn_label}</a>
                                                    {/*props?.reviews &&
                                                    <div className="google-reviews">
                                                        <picture><img src={GoogleReview} alt="reviews-img01" className="google_review_logo"/></picture>
                                                        <ReviewsCount />                  
                                                    </div>
                                                    */}
                                            </div>
                                        }
                                                                
                                    </Col>                           
                                </Row>    
                                </>
                            )                    
                     })}
                    
                     </Slider>
                    </Container>
                </ScrollAnimation>
            </section>            
        </React.Fragment>
    );
};

export default ImageBlockSlide;
