import React, { } from "react"
import { get } from 'lodash';
import { Link } from "@StarberryUtils" 
import { Container, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser'; 
import "./ContentBlock.scss"  
 

const ContentBlock = (props) => {

  const ctas = get(props, 'CTA_Block', []);
 

  return (
    <section className="content-block">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="content">
              <h3><strong>{props.Bold_Title}</strong>{props.Title}</h3>
              {props.Content &&
                <div>
                  {parse(props.Content)}
                </div>
              }
              {(ctas.length > 0) && (
                <div className="btn-list">
                  {ctas.map((cta, i) => { 
                      if (cta?.Cta_Link.match(/http|https/)) {
                        return (
                          <a href={cta.Cta_Link} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className="btn btn-trans">{cta.Cta_Label}</a>
                        )
                      } else {
                        return (
                          <Link to={cta.Cta_Link} target={cta?.Target === 'New_Tab' ? '_blank' : ''} className="btn btn-trans">{cta.Cta_Label}</Link>
                        )
                      } 
                  })}
                </div>
              )}
            </div> 

          </Col>
        </Row>
      </Container>
    </section>

  )
}
export default ContentBlock
