import React, { useEffect, useState, useRef } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import { navigate } from "@reach/router"
import parse from 'html-react-parser';
import gql from "graphql-tag"

import Slider from "react-slick";
import "./CharityPartners.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const GUIDE_LIST = gql`
query GUIDE_LIST {
    charityPartners {
        Charity_Contents
        Charity_Image {
          url
        }
        Charity_Name
      }
}
`;



function CharityPartners(props) {

    let sliderRef = useRef(null);
    const play = () => {
      sliderRef.slickPlay();
    };
    const pause = () => {
      sliderRef.slickPause();
    };

    const { loading, error, data } = useQuery(GUIDE_LIST);
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        arrows: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                  dots: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
        ],
    };

    const [modalSharefrndformOpen, setSharefrndformOpen] = useState(false);
    const [pdfFile, setPdfFile] = useState("");
    const [title, setTitle] = useState("");

    // Pdf download function
    const downloadFUnction = () => {
        if (pdfFile) {
            // using Java Script method to get PDF file
            fetch(pdfFile).then(response => {
                response.blob().then(file => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(file);
                    const PDF = pdfFile.replace(pdfFile, title)

                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = PDF;
                    alink.click();
                })
            })
        }
    }

    const openSharefrndformModal = (e) => {
        // setPdfFile(e?.File?.url)
        // setTitle(e?.Title)
        // setSharefrndformOpen(e?.File?.url ? true : false);
        navigate(`/guide/${e.URL}`)
    }

    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    if (loading) return (
        <p>loading</p>
    )

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

               <section className="similar-properties guide-block latest-news charityPartners charityPartnersList">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="heading">
                                {(props.Title || props?.bold_title) ? parse(`<h2><i>${props.Title? props.Title : ''}</i>${props?.bold_title? props?.bold_title : ''}</h2>`) : props?.bold_title}                                   
                                </div>
                                <div className="slick-wrap">
                                <Slider ref={slider => (sliderRef = slider)} {...settings}>
                                        {data?.charityPartners && data?.charityPartners.map((guide, i) => {
                                            return (
                                                <div className="properties img-zoom not-zoom">
                                                    <div className="properties-img">
                                                        <img src={guide?.Charity_Image?.url} alt="image" />
                                                    </div>
                                                    {!guide?.Charity_Name || !guide?.Charity_Contents?
                                                    <div className="gallery-text">
                                                    <h3 className='guide_title'>{guide?.Charity_Name}</h3>
                                                        {parse(guide?.Charity_Contents)}
                                                    </div>
                                                    :``
                                                    }
                                                </div>
                                            )
                                        })}
                                        {data?.charityPartners && data?.charityPartners.map((guide, i) => {
                                            return (
                                                <div className="properties img-zoom not-zoom">
                                                    <div className="properties-img">
                                                        <img src={guide?.Charity_Image?.url} alt="image" />
                                                    </div>
                                                    {!guide?.Charity_Name || !guide?.Charity_Contents?
                                                    <div className="gallery-text">
                                                    <h3 className='guide_title'>{guide?.Charity_Name}</h3>
                                                        {parse(guide?.Charity_Contents)}
                                                    </div>
                                                    :``
                                                    }
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                                    </section>
            </ScrollAnimation>            
        </React.Fragment>
    );
};

export default CharityPartners;
