import React, { useState, useEffect, useRef } from "react";
// import { Controller, Scene } from 'react-scrollmagic';
import { Link } from "@StarberryUtils";
import parse from 'html-react-parser';

import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import Img01 from "../../../images/phone_img.png";
import Img02 from "../../../images/phone_img.png";
import Img03 from "../../../images/phone_img.png";
import Img04 from "../../../images/phone_img.png";
import videoUrl from "../../../images/selling/water.mp4";
import "./SocialPromotion.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component
const Sticky2Styled = styled.div`
  overflow: hidden;
  .sticky, .sticky2 {
    height: 100vh;
    width: 100%;
    & .animation, .animation2 {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index:1;
      & svg {
        opacity: 1;
        position: absolute;
        width: 300px;
        height: 300px;
      }
    }
    .heading {
      position: absolute;
      height: 100%;
      width: 100%;
      h2 {
        font-size: 40px;
        position: absolute;
        bottom: 10%;
        left: 10%;
        margin: 0;
      }
    }
  }
`;
const SocialPromotion = (props) => {
    const [showComp, setShowComp] = useState(false);
    try {
        window.addEventListener("scroll", function () {
            if (document.querySelector('.promotion-img')) {
                var colorString = document.querySelector('.promotion-img').style.clipPath;
                colorString = parseInt(colorString.substring(colorString.indexOf("(") + 1, colorString.lastIndexOf(")")).split(/,\s*/)[0]);
                let target = document.querySelector('.animation');
                if (target) {
                    if (colorString < 190) {
                        document.querySelector('.animation').classList.add("left-animation");
                        document.querySelector('.animation .line-svg-box').style.opacity = 0;
                    } else {
                        document.querySelector('.animation').classList.remove("left-animation");
                        document.querySelector('.animation .line-svg-box').style.opacity = '';
                    }
                }
            }
        });
    }
    catch { }
    const mobileblock = useRef(null);
    const promotionimg = useRef(null);
    let scaleval = 0;
    useEffect(()=>{
        if (mobileblock.current)
            scaleval = mobileblock.current.offsetWidth / 710;
    });

    useEffect(() => {
        setTimeout(function() {
            setShowComp(true)
        }, 3000)
    }, [showComp])

    if (!showComp) {
        return <>Loading...</>
    }

    return (
        <React.Fragment>
            <div ref={mobileblock} className="mobile-scrolling-block">
                <Sticky2Styled className="sticky_top">
                    <Controller>
                        <div>
                            <Scene triggerHook="onLeave" duration={3000} pin>
                                {(progress) => (
                                    <div className="sticky line_fixed">
                                        <Timeline totalProgress={progress} paused>
                                            <Tween from={{ opacity: 2, x: '-50%' }} to={{ opacity: 0.3, x: '65%' }}>
                                                <div className="animation">
                                                    <div className="line-svg">
                                                        <div className="line-svg-box"></div>
                                                    </div>
                                                </div>
                                            </Tween>
                                            <Timeline target={
                                                <div  className="promotion-img video-clipping">
                                                    <picture className="phone-frame">
                                                        <img src={Img01} alt="aboutimg" />
                                                    </picture>
                                                    <video ref={promotionimg} id="videoblock_wrap" className='videoblock_wrap' autoPlay loop muted playsInline>
                                                        <source src={videoUrl} type='video/mp4' />
                                                    </video>
                                                </div>
                                            }>
                                                <Tween from={{ clipPath: 'inset(235px 0px)' }} to={{ clipPath: 'inset(0px 0px)' }} />
                                                <Tween from={{ scale: scaleval }} to={{ scale: 0.8 }} />
                                            </Timeline>
                                        </Timeline>
                                    </div>
                                )}
                            </Scene>
                        </div>
                    </Controller>
                </Sticky2Styled>

                <section className="social-promotion topspace_block  pb-0">
                    <div className="container">
                        <div className="promotion-info">
                            <ScrollAnimation animateIn="fadeIn">
                                {parse(props.Content)}
                            </ScrollAnimation>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="height_block">
                <section ref={socialPromotion} className="social-promotion pb-0">
                    <div className="line-svg-wrap">
                        <span className="line-svg">
                            <span className="line-svg-box"></span>
                        </span>
                        <div ref={containerParent} className="container-parent">
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div className="video_fixed_wrap">
                                            <div className="video_fixed_line"></div>
                                            <div ref={promotionImg} className="promotion-img">
                                                <picture className="phone-frame">
                                                    <source media="(min-width:1800px)" srcSet={Img04} />
                                                    <source media="(min-width:992px)" srcSet={Img03} />
                                                    <source media="(min-width:768px)" srcSet={Img02} />
                                                    <img src={Img01} alt="aboutimg" />
                                                </picture>
                                                <video className='videoblock_wrap' autoPlay loop muted>
                                                    <source src={videoUrl} type='video/mp4' />
                                                </video>
                                            </div>
                                        </div>
                                        <div className="promotion-info">
                                            <ScrollAnimation animateIn="fadeInUp">
                                                <h2>short statement <span className="sm-text">
                                                    social promotion</span></h2>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vel
                                                    efficitur tellus, laoreet finibus orci. Nam eget consectetur mi orbi
                                                    sodales sapien. Lorem ipsum dolor sit amet consectetur.
                                                </p>
                                            </ScrollAnimation>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>
                    </div>

                </section>
            </div> */}
        </React.Fragment >
    );
};

export default SocialPromotion;
