import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import { navigate } from "@reach/router"
import parse from 'html-react-parser';
import gql from "graphql-tag"

import Slider from "react-slick";
import "./CurrentVacancies.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../Play/property-details-video";
// Header component

const GUIDE_LIST = gql`
query GUIDE_LIST {
    currentVacancies {
        salary
        Title
        URL
        Video_url
        image {
          url
        }
        contents
      }
}
`;

function CurrentVacancies(props) {

    const { loading, error, data } = useQuery(GUIDE_LIST);

    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                  dots: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
        ],
    };

    if (loading) return (
        <p>loading</p>
    )

    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

               <section className="similar-properties guide-block latest-news CurrentVacancies no-padding-top">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="heading">
                                {(props.Title || props?.bold_title) ? parse(`<h2><i>${props.Title? props.Title : ''}</i>${props?.bold_title? props?.bold_title : ''}</h2>`) : props?.bold_title}                                   
                                </div>
                                <div className="slick-wrap">
                                    <Slider {...settings}>
                                        {data?.currentVacancies && data?.currentVacancies.map((guide, i) => {
                                            return (
                                                <div className="properties img-zoom">
                                                    <div className="properties-img">
                                                    <a href={`/about-us/careers/${guide?.URL}`}> <img src={guide?.image?.url} alt="image"/></a>
                                                        {/*guide.Video_url?<><PlayVideo url={guide.Video_url}><img src={guide?.image?.url} alt="image"/><i className="logo-play-button-transparent"></i></PlayVideo></>
                                                        :<a href={`/about-us/careers/${guide?.URL}`}> <img src={guide?.image?.url} alt="image"/></a>
                                                        */}
                                                    </div>

                                                    <div className="gallery-text">
                                                    <a href={`/about-us/careers/${guide?.URL}`}><h3 className='guide_title'>{guide?.Title}</h3></a>
                                                     {guide?.salary && 
                                                        <p>{guide?.salary}</p>                                                       
                                                     }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>            
        </React.Fragment>
    );
};

export default CurrentVacancies;
