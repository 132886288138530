import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import parse from 'html-react-parser';
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import GenerateLink from "../common/site/generate-link"
import PlayVideo from "../Play/custom-video";
import loadable from '@loadable/component'
import getVideoId from 'get-video-id';
import videoUrl from "../../images/selling/mov_bbb.mp4";
import Slider from "react-slick";
import "./SellingBanner.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import Img from 'gatsby-image';
// Header component

const YoutubeBackground = loadable(() => import('react-youtube-background'))

const SellingBanner = (props) => {
    var youtube_id = ''
    if(props.Banner_Video_Link) {
      youtube_id = getVideoId(props.Banner_Video_Link);
    }

    return (
        <React.Fragment>

                <section className="selling-banner static-banner selling-banner-img">
                {/*<YoutubeBackground
                    videoId={youtube_id.id}
                    className="area-guide-banner-video"

                    >*/}
                    {props.Banner_Video_Link &&
                        <div className="video-wrapper d-none d-md-block">
                            <div className="video-buttons">
                                <PlayVideo url={props.Banner_Video_Link} />
                            </div>
                            <span className="btn-info d-none d-xl-block">
                                {props.Banner_Video_Intro_Content}
                            </span>
                        </div>
                    }
                    <div className="banner-img">

                        {props.Banner_Image &&
                            <Img fluid={props.Banner_Image.url_sharp.childImageSharp.fluid} alt={props.Banner_Image.alternativeText} />
                        }

                          {/*<video className='videoTag' autoPlay loop muted>
                               <source src={props.Banner_Video_Link} type='video/mp4' />
                           </video>*/}

                        {props.Banner_Video_Link && <div className="video-wrapper d-md-none">
                            <div className="video-buttons">
                                <PlayVideo url={props.Banner_Video_Link} />
                            </div>
                            <span className="btn-info d-none d-xl-block">
                                {props.Banner_Video_Intro_Content}
                            </span>
                        </div>}
                    </div>
                    <div className="banner-content banner-content-alt">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <>
                                    {props.Banner_Title_Heading &&
                                    <span className="sm-info">
                                        {props.Banner_Title_Heading}
                                    </span>
                                    }
                                    {parse(props.Banner_Main_Content)}

                                    {props.Banner_CTA_Link &&
                                        <GenerateLink class="btn" link={props.Banner_CTA_Link}>
                                            {props.Banner_CTA_Label}
                                        </GenerateLink>
                                    }
                                    </>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/*</YoutubeBackground>*/}
                </section>

        </React.Fragment>
    );
};

export default SellingBanner;
