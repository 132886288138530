import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Container, Accordion } from "react-bootstrap";
import { isSafari, isIOS, isMacOs } from "react-device-detect";
import { Link } from "@reach/router";
import $ from "jquery";
import "./RentalPacakges.scss";
import OurPackages from "../../components/OurPackages/OurPackages"
import Xchecked from "../../components/RentalPackages/x-check.svg";
import Newclose from "../../components/RentalPackages/new-close-icons.svg";
import parse from 'html-react-parser';

const RentalPackages = (props) => {
    
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                rentalPackages(where: {Publish: true}, sort: "Sort") {
                    id
                    Features
                    Introduction_Service {
                        Value
                        IfBoolean
                    }
                    Rent_Collect {
                        Value
                        IfBoolean
                    }
                    Fully_Managed {
                        Value
                        IfBoolean
                    }
                    Fully_Managed_Enhanced {
                        Value
                        IfBoolean
                    }
                }
            }
        }
    `);
    const [ viewAll, setViewAll ] = React.useState(false);
    const [activeId, setActiveId] = useState(null);
    const [brows, setBrows] = useState(null);  

    const toggleActive = (e, id) => {
        e.preventDefault();
        if (activeId === id) {
            setActiveId(null)
            $('html, body').animate({scrollTop: $(".our-packages").offset().top - 140}, 1000);
        } else {
            setActiveId(id)
        }
    }
    useEffect(() => {
        setTimeout(() => {
            var browser =  isSafari?`safari`:``
            setBrows(browser)
          }, 1000);
       
        //const [brows, setBrows] = useState(browser);
         //console.log(brows)
        /*var propertySelector = '.all-featured-properties .type-list li';
    
        $(propertySelector).on('click', function(){
            $(propertySelector).removeClass('active');
            $(this).addClass('active');
        });*/
      },[]);
    return (
        <React.Fragment>
            {props.Page_module && props.Page_module.map((module, i) => {
                if(module.__typename == "GLSTRAPI_ComponentRevampOurPakage") {
                    return (
                        <OurPackages className={"our_package"}  {...module} />
                    )
                }
            })
            }
            {console.log("brows", brows)}
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                <section className={`rental-packages my-5 pb-5 ${brows}`}>
                    <Container>
                        <Accordion defaultActiveKey={activeId} className={`accordion_container ${brows}`}>
                            <Accordion.Toggle onClick={(e) => toggleActive(e, "let-package")} className={`accordion-header`} eventKey={"let-package"}>
                            {props?.Title?parse(`<em>${props.Title}</em>`):''}
                            {props?.bold_title?parse(props.bold_title):''}
                            <i className={activeId === "let-package" ? "icon-minus-menu" : "icon-plus-menu"}></i>
                            </Accordion.Toggle>
                            <Accordion className={`collapse ${activeId === "let-package" ? "show" : ""}`} eventKey={"let-package"}>
                                {props.Page_module && props.Page_module.map((module, i) => {
                                    return (
                                        <>
                                        {module.Add_Static_Content &&
                                        <div className="static-content-block single-content">
                                            <div className="plain-content">
                                                {parse(module.Add_Static_Content)}
                                            </div>
                                        </div>
                                        }
                                        </>
                                    )
                                })}
                                <div className="table-responsive d-none-">
                                    <div className="package-container">
                                        <div className="package-list containerDiv">
                                            <div className="package-head rowDivHeader">
                                                <div className="features cellDivHeader">Features</div>
                                                <div className="collect-protect cellDivHeader">Collect & Protect</div>
                                                <div className="collect-protect cellDivHeader">Fully Managed</div>
                                            </div>

                                            {(viewAll ? data?.glstrapi?.rentalPackages : data?.glstrapi?.rentalPackages).map((each, k) =>
                                            <div className={`package-item-list package-item rowDiv ${(((k+1)%2)===0)?"odd":"even"}`}>
                                       
                                                    <div className="features cellDiv">{each.Features}</div>
                                                    <div className="collect-protect cellDiv">
                                                        {each.Rent_Collect && (
                                                            each.Rent_Collect.Value ? each.Rent_Collect.Value : <img src={each.Rent_Collect.IfBoolean ? Xchecked : Newclose} alt="image" />
                                                        )
                                                        }
                                                    </div>
                                                    <div className="collect-protect cellDiv">{each.Fully_Managed && 
                                                    (each.Fully_Managed.Value ? each.Fully_Managed.Value : <img src={each.Fully_Managed.IfBoolean? Xchecked : Newclose} alt="image" />
                                                    )
                                                    }
                                                    </div>
                                         
                                            </div>
                                            )}

                                            {/*<div className="package-item-list package-item rowDiv even">
                                                    <div className="features cellDiv"></div>
                                                    <div className="collect-protect cellDiv ">
                                                        <Link to="/property-valuation/"><button className="btn btn_grau">Get Started</button></Link>
                                                        <a href="javascript:void(0)" onClick={(e) => toggleActive(e, "let-package")} class="link">Hide features</a>
                                                    </div>
                                                    <div className="collect-protect cellDiv">
                                                        <Link to="/property-valuation/"><button className="btn">Get Started</button></Link>
                                                        <a href="javascript:void(0)" onClick={(e) => toggleActive(e, "let-package")}  class="link">Hide features</a>
                                                    </div>
                                            </div>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </Accordion>
                        </Accordion>
                    </Container>
                </section>
            </ScrollAnimation>
        </React.Fragment>
    )
}

export default RentalPackages;
