import React from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";
import ShowMoreText from 'react-show-more-text';
import parse from 'html-react-parser';
import ReactTimeAgo from 'react-time-ago'
import Count from './review-count'
// import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

import reviewsimg01 from "../../../images/google-review.svg";
import client01 from "../../../images/client01.png";
import Slider from "react-slick";
import "./Reviews.scss";

// Header component
const Reviews = (props) => {
  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 1.9,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1402,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,

          arrows: true,
        },
      },

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 377,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const data = useStaticQuery(graphql`
  query {
    glstrapi {

      reviews(publicationState: LIVE, sort: "Date:DESC", limit: 10,where : {Review_Content_ne:null}) {
        id
        Name
        Date
        Publish
        Rating
        Review_Content
        Image {
          url
        }
      }

      globalModule {
        Google_Reviews_Content
      }

    }
  }
`);

const reviews = data.glstrapi.reviews;
const module = data.glstrapi.globalModule;


  return (
    <React.Fragment>
      <section className="reviews">
        <div className="reviews_block">
          <Container>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <Row>
                <Col sm={12} lg={4} className="col-custom">
                  <div className="google-reviews">
                    <picture>
                      <img
                        src={reviewsimg01}
                        alt="reviews-img01"
                        className="google_review_logo"
                      />
                    </picture>
                    <Count />
                    <p>
                      {parse(module.Google_Reviews_Content)}
                    </p>
                  </div>
                </Col>
              </Row>
              <h2>Reviews</h2>
            </ScrollAnimation>
          </Container>
          <div className="slider-wrap">
            <Slider className="slick-arrow" {...settings}>

            {reviews &&
              reviews.map((item, i) => (

              <div class="slider-wrapper">
                <div className="home-img">
                  <picture>
                    <img src={item.Image.url} alt={item.Name} />
                    {/*<ImageTransform imagesources={item.Image.url} renderer="srcSet" imagename="articles.ReviewImage.default"
                                               attr={{ alt: item.Name}}
                                               imagetransformresult={props?.processedImages || {}} id={props?.pageId} testparam={true} />*/}
                  </picture>
                  <div className="home-content">
                    <h3 class="h4">{item.Name}</h3>
                    <span className="time"><ReactTimeAgo date={item.Date} locale="en-US"/></span>
                  </div>
                </div>

                {item.Review_Content &&
                <p>
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more='More'
                    less='Less'
                    className='google-review-content-css desc'
                    anchorClass='btn-more review-more'
                    expanded={false}
                    keepNewLines={false}
                    >
                      {parse(item.Review_Content)}
                    </ShowMoreText>

                  {/* {parse(item.Review_Content.slice(0,200))} */}
                </p>
                }

                  {item.Rating === "One" &&
                  <ul className="rating-icons">
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  </ul>
                  }
                  {item.Rating === "Two" &&
                  <ul className="rating-icons">
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  </ul>
                  }
                  {item.Rating === "Three" &&
                  <ul className="rating-icons">
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  </ul>
                  }
                  {item.Rating === "Four" &&
                  <ul className="rating-icons">
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  </ul>
                  }
                  {item.Rating === "Five" &&
                  <ul className="rating-icons">
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  <li>
                      <i className="icon-star"></i>
                  </li>
                  </ul>
                  }
              </div>
              )
              )}
            </Slider>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Reviews;
