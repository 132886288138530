import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import "./Awards.scss";
import { useStaticQuery, graphql } from "gatsby";
import parse from 'html-react-parser';
import { useLocation } from "@reach/router";
import GoogleReview from "../../../images/google-reviews-white.svg"
import ReviewsCount from '../Reviews/review-count'
// Header component

const Awards = (props) => {

  const hasInfo = props.Intro_Info_Title ? ' has-info' : 'has-noinfo';

  const path = ["/buy/", "/sell/"]
  var { pathname } = useLocation();
  const isPath = path.includes(pathname)
  const [readMore, setReadMore] = useState(props.Intro_Content_One?.length >= 700)
console.log("awd", props)

  return (
    <React.Fragment>

      <section className="award">
        <Container>
          <Row className="about-content">         
            <Col lg={7}>
              <div className="choice-sm-info">
                {/*<i className="icon-rebriks-logo"></i>*/}
                {props.content &&
                  <>{parse(props.content)}</>
                }
              </div>
            </Col>
            <Col lg={5}>
                <div className="img-column ">
                    <img className="award-logo" src={props.award_icons.url} alt="logo"/>    
                </div>              
            </Col>            
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Awards;
