import React from "react"
import { Link } from "@StarberryUtils"
import { sitename } from "@constants";
import "./Services.scss"
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap"
import GenerateLink from "../../common/site/generate-link"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

const ServicesOne = (props) => {

    let title = props?.Services_Title;
    let lastStr = '';
    if (title) {
        let tmp = title.split(' ');
        if (tmp.length > 1) {
            lastStr = ` ${tmp.pop()}`;
            title = tmp.join(' ');
        }
    }

    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <React.Fragment>
            <section className={`services ${props.class} ${props.Services_Title ? 'service-bottom' : ''}`}>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                {props.Services_Title &&
                                <div className="service">
                                    <h2>
                                        {title}{` `}
                                        {lastStr && (<span>{lastStr}</span>)}
                                    </h2>
                                    {props.Services_CTA_Label &&
                                        <GenerateLink class="learn-more" link={props.Services_CTA_Link}>
                                        {props.Services_CTA_Label}
                                        </GenerateLink>
                                    }

                                </div>
                                }
                                <div className={`service-wrapper service-landing-tiles-list ${props.index > 3 ? 'slide-show-tiles' : ''}`}>
                                {props.Service_Tile && props.Service_Tile.map((tile, i) => {
                                    return (
                                    <div className="service-img img-zoom">
                                        <GenerateLink link={tile.Service_Tile_Link}>
                                            <ImageTransform imagesources={tile.Service_Tile_Image.url} renderer="srcSet" imagename="offices.Service_Tile_Image.default"
                                                               attr={{ alt: tile.Service_Tile_Image.alternativeText || `${tile.Service_Tile_Label} ${sitename} Estate Agents`}}
                                                               imagetransformresult={props?.processedImages || {}} id={props?.pageId} testparam={true} />
                                            {/*<picture>
                                                <source media="(min-width:992px)" srcSet={tile.Service_Tile_Image.url} />
                                                <source media="(min-width:768px)" srcSet={tile.Service_Tile_Image.url} />
                                                <img src={tile.Service_Tile_Image.url} alt={tile.Service_Tile_Image.alternativeText} />
                                            </picture>*/}
                                            <div className="service-info">
                                                <GenerateLink link={tile.Service_Tile_Link}>
                                                    {tile.Service_Tile_Label}
                                                </GenerateLink>
                                            </div>
                                        </GenerateLink>
                                    </div>
                                    )
                                })}
                                </div>

                                {props.index > 3 &&
                                <Slider {...settings} className="service-wrapper service-tile-slide">
                                {props.Service_Tile && props.Service_Tile.map((tile, i) => {
                                    return (
                                    <div className="service-img img-zoom">
                                        <GenerateLink link={tile.Service_Tile_Link}>
                                            <picture>
                                                <source media="(min-width:992px)" srcSet={tile.Service_Tile_Image.url} />
                                                <source media="(min-width:768px)" srcSet={tile.Service_Tile_Image.url} />
                                                <img src={tile.Service_Tile_Image.url} alt={tile.Service_Tile_Image.alternativeText || `${tile.Service_Tile_Label} ${sitename} Estate Agents`} />
                                            </picture>
                                            <div className="service-info">
                                                <GenerateLink link={tile.Service_Tile_Link}>
                                                    {tile.Service_Tile_Label}
                                                </GenerateLink>
                                            </div>
                                        </GenerateLink>
                                    </div>
                                    )
                                })}
                                </Slider>
                                }
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment>
    )
}

export default ServicesOne
