import React from "react"
import { Container, Row, Col } from "react-bootstrap";

import { Helmet } from "react-helmet";

const Instagram = (props) => {
    return (
        <>
            <Helmet>
                <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            </Helmet>
            <Container className="services  insta-feed">
                <Row>
                    <Col lg={12}>
                        <div class="service"><h2>@REDBRIK</h2><a class="learn-more" href="https://www.instagram.com/redbrik/" target="_blank">Go to Instagram</a></div>
                        <div class="elfsight-app-d70256d4-cedf-46a8-8d28-64cd41e2d34d"></div>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Instagram;