import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { get } from 'lodash';
import { Link } from "@StarberryUtils"
import parse from 'html-react-parser';
import "./Stats.scss";
import PlayVideo from "../../Play/custom-video";
import ScrollAnimation from 'react-animate-on-scroll'; 
// Header component

const Stats = (props) => { 

    return (
        <React.Fragment>
            <section className={`stats-block ${props.custom_class}`}>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <h3>{props.Intro}</h3>

                                <div className={"list"}>
                                    {props.Stats && props.Stats.map((stat, i) => {
                                             const parenturl = stat?.CTA_Link?.Main_Parent?.URL
                                        return (
                                            <div className="stats">
                                                <div className="title"><span className="prefix">{stat.Prefix}</span>{stat.Title}<span className="suffix">{stat.Suffix}</span></div>
                                                <div className="info">{stat.Info}</div>
                                                {stat.CTA_Label && stat.CTA_Link &&
                                                    <Link to={parenturl?"/"+parenturl+"/"+stat?.CTA_Link?.URL:"/"+stat?.CTA_Link?.URL}>{stat.CTA_Label}</Link>
                                                }
                                            </div>
                                        )
                                    })}

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ScrollAnimation>
            </section>
        </React.Fragment>
    );
};

export default Stats;
