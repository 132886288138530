import React from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import parse from 'html-react-parser';
import GenerateLink from "../../common/site/generate-link"
import Join01 from "../../../images/propertyDetails/join01.png"
import Join02 from "../../../images/propertyDetails/join02.png"
import Join03 from "../../../images/propertyDetails/join03.png"
import Join04 from "../../../images/propertyDetails/join04.png"

import "./ImageRightContent.scss";
import ScrollAnimation from 'react-animate-on-scroll';
// Header component

const JoinUs = (props) => {
    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                <section className="join-us-alt">
                    <div className="join-img  d-none d-lg-block">
                        <picture>
                            <source media="(min-width:1500px)" srcSet={props.Add_Image_here.url} />
                            <source media="(min-width:992px)" srcSet={props.Add_Image_here.url} />
                            <source media="(min-width:768px)" srcSet={props.Add_Image_here.url} />
                            <img src={props.Add_Image_here.url} alt={props.Add_Image_here.alternativeText} />
                        </picture>
                    </div>
                    <div className="join-now">
                        {parse(props.Content)}
                        {props.Ctas && props.Ctas.map((cta, i) => {
                            if(cta.Target && cta.Target === "New_Tab") {
                                return (
                                    <a href={cta.Cta_Link} class="btn" target="_blank">{cta.Cta_Label}</a>
                                )
                            }
                            return (
                                <Link to={cta.Cta_Link} class="btn">{cta.Cta_Label}</Link>
                            )
                        })}
                    </div>
                </section>
            </ScrollAnimation>
        </React.Fragment>
    );
};

export default JoinUs;
