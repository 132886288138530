import React, { useState } from "react";
import HTMLReactParser from 'html-react-parser';
import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll'; 
import "./FAQ.scss";
import SiteLogo from "../../images/site-logo.png"
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";


const FAQ = (props) => {
  const [activeId, setActiveId] = useState(null);
  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null)
    } else {
      setActiveId(id)
    }
  }

  return (
    <section className={`faq-section ${props?.bg_color?props?.bg_color:'gray'}`}>
      <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
        <Container>
          <Row>
            <Col sm={12}>

              <h3><i>{props.Title}</i>{props.bold_title}</h3>
              {/*<p>{props.Content && HTMLReactParser(props.Content)}</p>*/}
              <Accordion defaultActiveKey={activeId}>
                {
                  props.FAQ_List.length > 0 && props.FAQ_List.map((item, index) => {
                    return (
                      <Card>
                        <Accordion.Toggle onClick={() => toggleActive(index.toString())} className={`accordion-header`} eventKey={index.toString()}>
                          {item.Question}
                          <i className={activeId === index.toString() ? "icon-minus-menu" : "icon-plus-menu"}></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index.toString()}>
                          <div className="accordion-card">
                            <p>{item.Answer && HTMLReactParser(item.Answer)}</p>
                          </div>
                        </Accordion.Collapse>
                      </Card>
                    )
                  })
                }
              </Accordion>
            </Col>
          </Row>
        </Container>
      </ScrollAnimation>
    </section>
  );
};

export default FAQ;
