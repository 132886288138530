import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";

import Slider01 from "../../images/propertyDetails/location01.png";
import Slider001 from "../../images/propertyDetails/tablocation01.png";
import Slider002 from "../../images/propertyDetails/tablocation02.png";
import Slider0001 from "../../images/propertyDetails/desklocation01.png";
import Slider0002 from "../../images/propertyDetails/desklocation02.png";
import Slider0003 from "../../images/propertyDetails/desklocation03.png";
import Slider0004 from "../../images/propertyDetails/desklocation04.png";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import LocRatingMap from '../map/loc-rating-map-office';

import MailForm from "../Team/team-landing-form"

import PlayVideo from "../Play/custom-video";

import Slider from "react-slick";
import "./Location.scss";
import ScrollAnimation from "react-animate-on-scroll";
import { propTypes } from "react-bootstrap/esm/Image";
// Header component

const Properties = () => {
  const [isPlay, setPlay] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [isPlayed, setPlayed] = useState(false);

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      offices(sort: "Sort:asc", where: {Publish: true}) {
        Sort
        Title
        Video_URL
        URL
        Address
        Latitude
        Primary_Email
        Lettings_Email
        Lettings_Phone
        Longitude
        New_Homes_Email
        New_Homes_Phone
        Chesterfield_New_Homes_Phone
        Chesterfield_New_Homes_Email
        Sheffield_New_Homes_Phone
        Sheffield_New_Homes_Email
        Sales_Email
        Sales_Phone
        Office_Title
        Image {
          url
          alternativeText
        }
        imagetransforms
        id
      }

    }
  }
`);

const offices = data.glstrapi.offices;

    // Get Map Details
    let mapItems = [];
    {offices.map((node, key) => {
        if (node.Latitude && node.Longitude) {
            let mapItem = {};
            mapItem['lat']  = node.Latitude
            mapItem['lng']  = node.Longitude
            mapItem['id']  = node.id
            const mapMarkerImageShowFlag = 0;

            mapItems.push( mapItem );
        }
    })};

  return (
    <React.Fragment>
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
        <section className="about-location">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="location-wrapper row">

                {offices.map((office, i) => {

                const image_url = office.Image.url

                let processedImages = JSON.stringify({});
                if (office?.imagetransforms?.Image_Transforms) {
                    processedImages = office.imagetransforms.Image_Transforms;
                }
                  return (
                  <div className="location area-guide-box col-lg-4" id={office.id}>
                    <div className="card-img img-zoom">
                    <Link to={office.URL}>
                      <img src={image_url} alt={`${office.Title} - Redbrik`} />
                      {/*<ImageTransform imagesources={image_url} renderer="srcSet" imagename="offices.Image.tile" attr={{ alt: office.Title+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={office.id}/>*/}
                    </Link>
                    {office?.Video_URL && (
                      <div
                        className="video-buttons"
                        onClick={(e) => {
                          setPlay(true);
                        }}
                      >
                        <PlayVideo url={office.Video_URL} />
                      </div>
                    )}
                    </div>
                    <div className="card-details">
                      <div className="card-details-text">
                        <Link to={office.URL}>
                          <h2 className="h3">{office.Title.replace(office.Office_Title, '')} <span>{office.Office_Title}</span></h2>
                        </Link>
                        <div className="details d-none">
                          {/*<MailForm name={office.Title.split(" ")[0].replace(',','')} class="sm-info" tag="contact" email={office.Primary_Email} />*/}
                          {/* <Link href="javascript:void(0)" className="sm-info">
                            Email {office.Title.split(" ")[0].replace(',','')}
                          </Link> */}
                          <span className="sm-text">
                            {office.Address}
                          </span>
                        </div>
                      </div>

                      <div className="info">
                        {office.Sales_Phone &&
                        <>
                          <span className="title">Sales</span>
                          <a href={`tel:${office.Sales_Phone}`} className="contact-no">
                            {office.Sales_Phone}
                          </a>
                        </>
                        }
                        {office.Lettings_Phone &&
                        <>
                          <span className="title">Lettings</span>
                          <a href={`tel:${office.Lettings_Phone}`} className="contact-no">
                            {office.Lettings_Phone}
                          </a>
                        </>
                        }
                        {office.New_Homes_Phone &&
                        <>
                          <span className="title">New Homes</span>
                          <a href={`tel:${office.New_Homes_Phone}`} className="contact-no">
                            {office.New_Homes_Phone}
                          </a>
                        </>
                        }
                        {office.Chesterfield_New_Homes_Phone &&
                        <>
                          <span className="title">Chesterfield New Homes</span>
                          <a href={`tel:${office.Chesterfield_New_Homes_Phone}`} className="contact-no">
                            {office.Chesterfield_New_Homes_Phone}
                          </a>
                        </>
                        }
                        {office.Sheffield_New_Homes_Phone &&
                        <>
                          <span className="title">Sheffield New Homes</span>
                          <a href={`tel:${office.Sheffield_New_Homes_Phone}`} className="contact-no">
                            {office.Sheffield_New_Homes_Phone}
                          </a>
                        </>
                        }
                      </div>
                      <div className="view-details-link d-none"><Link to={office.URL}>View Office Details</Link></div>
                    </div>
                  </div>
                  )
                  })}

                </div>
              </Col>
            </Row>
          </Container>

        {/* <div className="office-landing-map" id="office-landing-map">
            <LocRatingMap data={mapItems} />
        </div> */}

        </section>
      </ScrollAnimation>
    </React.Fragment>
  );
};

export default Properties;
